import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { toast } from "react-toastify";
import api from "../../../../../services/api";

import { Content } from "./styles";

function OrcamentosProdutos({ setFiltroProduto, produtoSelecionado, setCategoriaproduto, categoriaProdutoSelecionada, setCidadeProduto, cidadeProdutoSelecionada, setRegiaoProduto, regiaoProdutoSelecionado }) {

  const [produto, setProduto] = useState([]);

  const loadingProdutos = async () => {
    try {
      const { data } = await api.get(`/orcamentos_graficoProduto?produto=${produtoSelecionado}&categoriaProduto=${categoriaProdutoSelecionada}&cidadeProduto=${cidadeProdutoSelecionada}&regiaoProduto=${regiaoProdutoSelecionado}`);
      let produtos = data?.produtos.map((item) => item);
      if (produtos.length == 0) {
        toast.error(`Produto não encontrado!`);
        return false;
      };
      setProduto(data.produtos);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingProdutosFiltro = async () => {
    try {
      const { data } = await api.get(`/orcamentos_grafico_filtroProduto`);
      let cidades = data.map((item) => item.cidades);

      const filtroProdutos = [];
      data?.map((item) => {
        if (!filtroProdutos.includes(item.nome)) {
          filtroProdutos.push(item.nome)
        }
      });
      setFiltroProduto(filtroProdutos?.map(item => ({
        value: item, label: item
      })));

      const categorias = [];
      data.map((item) => {
        item.categorias?.map(data => {
          if (!categorias.includes(data.nome)) {
            categorias.push(data.nome)
          }
        })
      });
      setCategoriaproduto(categorias?.map(item => ({
        value: item, label: item
      })));

    } catch (err) {
      console.log(err);
    }
  };

  const loadingCidade = async () => {
    try {
      const { data } = await api.get(`/cidade_all`);
      let cidades = [];
      data.map((item) => {
        if (!cidades.includes(item.nome)) {
          cidades.push(item.nome)
        }
      });
      setCidadeProduto(cidades?.map(item => ({
        value: item, label: item
      })));
    } catch (err) {
      console.log(err);
    }
  };

  const loadingRegiao = async () => {
    try {
        const { data } = await api.get(`/regiao_all`);
        let regiao = [];
        data.map((item) => {
          if(!regiao.includes(item.nome)){
            regiao.push(item.nome);
          };
        });
        setRegiaoProduto(regiao?.map(item => ({
          value: item, label: item
        })));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadingProdutos();
    loadingCidade();
    loadingRegiao();
  }, [produtoSelecionado, categoriaProdutoSelecionada, cidadeProdutoSelecionada, regiaoProdutoSelecionado]);

  useEffect(() => {
    loadingProdutosFiltro();
  }, [produto]);

  return (
    <Content>
      <BarChart
        width={produto.length * 130}
        height={300}
        data={produto}
        margin={{
          top: 20,
          // right: 30,
          // left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="nome_comercial" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="produtos" fill="#20655F" />
      </BarChart>
    </Content>
  );
}

export default OrcamentosProdutos;
