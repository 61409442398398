import React, { createContext } from "react";
import { toast } from "react-toastify";
import api from "../services/api";

const AuthContextRegiao = createContext({});

const AuthProviderRegiao = ({ children }) => {

  /* Função para carregar regiões */
  const loadingRegiao = async () => {
    try {
      const { data } = await api.get(`/regiao`);
      return data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* Função para delatar regiao */
  const deleteRegiao = async (id) => {
    try {
      const { data } = await api.delete(`/regiao/${id}`);
      toast.success(data.message);
      return data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

      return false;
    }
  };

  /* Função para atualiza regiao */
  const updateRegiao = async (data, id) => {
    try {
      const resp = await api.put(`/regiao/${id}`, data);
      resp.data?.id ?
        toast.error(resp?.data?.message) :
        toast.success("Atualizado com sucesso!");
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

      return false;
    }
  };

  /* Função para criar uma nova regiao */
  const createRegiao = async (data) => {
    try {
      const resp = await api.post(`/regiao`, data);
      !resp?.data?.id ?
        toast.error(resp?.data?.message) :
        toast.success("Cadastrado com sucesso!");
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
      return false;
    }
  };

  return (
    <AuthContextRegiao.Provider
      value={{
        deleteRegiao,
        loadingRegiao,
        updateRegiao,
        createRegiao,
      }}
    >
      {children}
    </AuthContextRegiao.Provider>
  );
};

export { AuthContextRegiao, AuthProviderRegiao };