import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { AuthContextBuyPlan } from '../../../../../contexts/BuyPlanContext';
import Stepper from '../../components/Steps';
import AddressForm from '../AddressForm';
import PaymentForm from '../PaymentForm';
import Plans from '../Plans';
import Review from '../Review';
import { ContainerCircularProgress } from './styles';

const steps = [
    'Planos',
    'Endereço',
    'Pagamento',
    'Resumo'
];

const theme = createTheme();

export default function Checkout() {
	const { 
    handleNext, 
    activeStep, 
    handleBack, 
    handleCreateSubscription, 
    checkSubscriptionActive, 
    error, 
    loadingCheckout, 
    statistics 
  } = useContext(AuthContextBuyPlan)


  function getStepContent(step) {
    switch (step) {
      case 1:
        return <Plans/>;
      case 2:
        return <AddressForm />;
      case 3:
        return <PaymentForm />;
      case 4:
        return <Review />;
      case 5:
        //error or finish
        break;
      default:
        throw new Error('Unknown step');
    }
  }

  function handleLastStep() {
    handleCreateSubscription()
  }

  if(statistics === undefined){
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          {loadingCheckout &&
            <ContainerCircularProgress>
              <CircularProgress/>
            </ContainerCircularProgress>
          }
          </Paper>
        </Container>
      </ThemeProvider>
    );
  }

  const disableStepper = () => {
    const disableByValidCanceled = () =>{
      if(statistics === undefined) return true
      const haveActive = statistics.allValidPlanStatus.filter(fitem=> fitem.plan > 1 && fitem.status_subscription === 'active').length > 0
      const haveValidCanceled = statistics.allValidPlanStatus.filter(fitem=> fitem.plan > 1 && fitem.status_subscription === 'canceled').length > 0
      const allValid = statistics.allValidPlanStatus.filter(fitem=> fitem.plan > 1)
      if(statistics.haveValidPaid){

        if(haveActive) return false
        if(allValid.length === 2) return false

        if(haveValidCanceled && haveActive === false) return true
        if(haveValidCanceled && haveActive === true) return false
        return false
      }
      return !(statistics.havePaid === true && statistics.haveValidPaid === true)
    }
    
    return !(
      (error !== undefined || activeStep !== 5) &&
      statistics && 
      !disableByValidCanceled()
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          {disableStepper() &&
            <>
              <Typography component="h1" variant="h4" align="center">
                Checkout
              </Typography>
              <Stepper currentStep={activeStep} steps={steps} />
            </>
          } 
          <Fragment>
            {activeStep === 5 && error === undefined && (
              <Fragment>
                <Typography variant="h5" gutterBottom marginTop={10}>
                  Obrigado pelo seu pedido.
                </Typography>
                <Typography variant="subtitle1">
                  Agora você pode voltar para o aplicativo.
                </Typography>
              </Fragment>
            )}
            {activeStep === 5 && error && (
              <>
                <Fragment>
                  <Typography variant="h5" gutterBottom marginTop={10}>
                    Ocorreu algum problema.
                  </Typography>
                  {Array.isArray(error) ? 
                    error.map(item=>(
                      <Typography variant="subtitle1">
                        {item}
                      </Typography>
                    )) :
                    <Typography variant="subtitle1">
                      {error}
                    </Typography>
                  }
                </Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Voltar
                      </Button>
                    )}
                </Box>
              </>
            )}
            {activeStep !== 5 && (
              <Fragment>
                {loadingCheckout &&
                  <ContainerCircularProgress>
                    <CircularProgress/>
                  </ContainerCircularProgress>
                }
                {getStepContent(activeStep)}
                {activeStep !== 1 &&
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Voltar
                      </Button>
                    )}

                  
                    <Button
                      variant="contained"
                      onClick={activeStep === steps.length ? handleLastStep : handleNext }
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length ? 'Finalizar' : 'Próximo'}
                    </Button>
                  </Box>
                }
              </Fragment>
            )}
          </Fragment>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}