import React, { useState, useRef } from "react";
import {
    RiFilterLine,
} from "react-icons/ri";
import SomaValores from "./SomaValores";
import NumeroUsuarios from "./NumeroUsuarios";
import OrcamentosProdutos from "./TotalOrcamentos/Produtos";
import OrcamentosInsumos from "./TotalOrcamentos/Insumos";
import Select from "react-select";

import { Container, Header, Content, Filtros } from "./styles";

function GraficosAdm() {

    const [mes, setMes] = useState([]);
    const [filtroMesSelecionado, setFiltroMesSelecionado] = useState('');
    const [ano, setAno] = useState([]);
    const [filtroAnoSelecionado, setFiltroAnoSelecionado] = useState('');
    const [somaValoresCidade, setSomaValoresCidade] = useState([]);
    const [somaValoresCidadeSelecionado, setSomaValoresCidadeSelecionado] = useState('');
    const [somaValoresRegiao, setSomaValoresRegiao] = useState([]);
    const [somaValoresRegiaoSelecionado, setSomaValoresRegiaoSelecionado] = useState('');

    const [filtroMesUsuarios, setFiltroMesUsuarios] = useState([]);
    const [mesSelecionado, setMesSelecionado] = useState('');
    const [filtroAnoUsuarios, setFiltroAnoUsuarios] = useState([]);
    const [anoSelecionado, setAnoSelecionado] = useState('');
    const [cidade, setCidade] = useState([]);
    const [filtroCidadeSelecionada, setFiltroCidadeSelecionada] = useState('');
    const [regiao, setRegiao] = useState([]);
    const [filtroRegiaoSelecionada, setFiltroRegiaoSelecionada] = useState('');

    const [filtroProduto, setFiltroProduto] = useState([]);
    const [produtoSelecionado, setProdutoSelecionado] = useState('');
    const [categoriaproduto, setCategoriaproduto] = useState([]);
    const [categoriaProdutoSelecionada, setCategoriaProdutoSelecionada] = useState('');
    const [cidadeProduto, setCidadeProduto] = useState([]);
    const [cidadeProdutoSelecionada, setCidadeProdutoSelecionada] = useState('');
    const [regiaoProduto, setRegiaoProduto] = useState([]);
    const [regiaoProdutoSelecionado, setRegiaoProdutoSelecionado] = useState('');

    const [insumos, setInsumos] = useState([]);
    const [insumoSelecionado, setInsumoSelecionado] = useState('');
    const [categoriaInsumo, setCategoriaInsumo] = useState([]);
    const [categoriaInsumoSelecionada, setCategoriaInsumoSelecionada] = useState('');
    const [cidadeInsumo, setCidadeInsumo] = useState([]);
    const [cidadeInsumoSelecionada, setCidadeInsumoSelecionada] = useState('');
    const [regiaoInsumo, setRegiaoInsumo] = useState([]);
    const [regiaoInsumoSelecionado, setRegiaoInsumoSelecionado] = useState('');

    const customStyles = {
        control: () => ({
            width: 150,
            borderRadius: '8px',
            height: '35px',
            // backgroundColor: 'yellow'
        }),
        menuList: (provided) => ({
            ...provided,
            width: 150,
            display: 'flex',
            flexDirection: 'column',
            fontSize: 15,
        }),
        valueContainer: (provider) => ({
            ...provider,
            width: '100%',
            height: '100%'
        })
    };
    return (
        <>
            <Container>
                <Header>
                    <div>
                        <h1>Gráficos</h1>
                    </div>
                </Header>
                <Content>
                    <div id='soma'>
                        <h1>Soma de todos os melhores/piores valores</h1>
                        <Filtros>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setFiltroMesSelecionado(value.label)}
                                    options={mes}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Mês</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setFiltroAnoSelecionado(value.label)}
                                    options={ano}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Ano</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setSomaValoresCidadeSelecionado(value.label)}
                                    options={somaValoresCidade}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Cidade</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setSomaValoresRegiaoSelecionado(value.label)}
                                    options={somaValoresRegiao}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Região</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="botao" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}
                                onClick={() => {
                                    setFiltroMesSelecionado('');
                                    setFiltroAnoSelecionado('');
                                    setSomaValoresCidadeSelecionado('');
                                    setSomaValoresRegiaoSelecionado('');
                                }}
                            >
                                <p>Limpar filtro</p>
                            </section>
                        </Filtros>
                    </div>
                    <div>
                        <SomaValores
                            setMes={setMes}
                            filtroMesSelecionado={filtroMesSelecionado}
                            setAno={setAno}
                            setSomaValoresCidade={setSomaValoresCidade}
                            setSomaValoresRegiao={setSomaValoresRegiao}
                            filtroAnoSelecionado={filtroAnoSelecionado}
                            somaValoresCidadeSelecionado={somaValoresCidadeSelecionado}
                            somaValoresRegiaoSelecionado={somaValoresRegiaoSelecionado}
                        />
                    </div>
                    <div id='soma'>
                        <h1>Número de usuários</h1>
                        <Filtros>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setMesSelecionado(value.label)}
                                    options={filtroMesUsuarios}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Mês</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setAnoSelecionado(value.label)}
                                    options={filtroAnoUsuarios}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Ano</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setFiltroCidadeSelecionada(value.label)}
                                    options={cidade}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Cidade</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setFiltroRegiaoSelecionada(value.label)}
                                    options={regiao}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Região</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="botao" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}
                                onClick={() => {
                                    setMesSelecionado('');
                                    setAnoSelecionado('');
                                    setFiltroCidadeSelecionada('');
                                    setFiltroRegiaoSelecionada('')
                                }}>
                                <p>Limpar filtro</p>
                            </section>
                        </Filtros>
                    </div>
                    <div>
                        <NumeroUsuarios
                            setFiltroMesUsuarios={setFiltroMesUsuarios}
                            mesSelecionado={mesSelecionado}
                            setFiltroAnoUsuarios={setFiltroAnoUsuarios}
                            anoSelecionado={anoSelecionado}
                            setCidade={setCidade}
                            filtroCidadeSelecionada={filtroCidadeSelecionada}
                            setRegiao={setRegiao}
                            filtroRegiaoSelecionada={filtroRegiaoSelecionada}
                        />
                    </div>
                    <div id="soma">
                        <h1>Total de Orçamentos Produtos</h1>
                        <Filtros>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setProdutoSelecionado(value.label)}
                                    options={filtroProduto}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Produto</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setCategoriaProdutoSelecionada(value.label)}
                                    options={categoriaproduto}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Categoria</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setCidadeProdutoSelecionada(value.label)}
                                    options={cidadeProduto}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Cidade</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setRegiaoProdutoSelecionado(value.label)}
                                    options={regiaoProduto}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Região</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="botao" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}
                                onClick={() => {
                                    setProdutoSelecionado('');
                                    setCategoriaProdutoSelecionada('');
                                    setCidadeProdutoSelecionada('');
                                    setRegiaoProdutoSelecionado('');
                                }}>
                                <p>Limpar filtro</p>
                            </section>
                        </Filtros>
                    </div>
                    <div>
                        <OrcamentosProdutos
                            setFiltroProduto={setFiltroProduto}
                            produtoSelecionado={produtoSelecionado}
                            setCategoriaproduto={setCategoriaproduto}
                            setCidadeProduto={setCidadeProduto}
                            setRegiaoProduto={setRegiaoProduto}
                            categoriaProdutoSelecionada={categoriaProdutoSelecionada}
                            cidadeProdutoSelecionada={cidadeProdutoSelecionada}
                            regiaoProdutoSelecionado={regiaoProdutoSelecionado}
                        />
                    </div>
                    <div id="soma">
                        <h1>Total de Orçamentos Insumos</h1>
                        <Filtros>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setInsumoSelecionado(value.label)}
                                    options={insumos}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por insumos</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setCategoriaInsumoSelecionada(value.label)}
                                    options={categoriaInsumo}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Categoria</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setCidadeInsumoSelecionada(value.label)}
                                    options={cidadeInsumo}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Cidade</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setRegiaoInsumoSelecionado(value.label)}
                                    options={regiaoInsumo}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Região</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="botao" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}
                                onClick={() => {
                                    setInsumoSelecionado('');
                                    setCategoriaInsumoSelecionada('');
                                    setCidadeInsumoSelecionada('');
                                    setRegiaoInsumoSelecionado('');
                                }}>
                                <p>Limpar filtro</p>
                            </section>
                        </Filtros>
                    </div>
                    <div>
                        <OrcamentosInsumos
                            setInsumos={setInsumos}
                            insumoSelecionado={insumoSelecionado}
                            setCategoriaInsumo={setCategoriaInsumo}
                            setCidadeInsumo={setCidadeInsumo}
                            setRegiaoInsumo={setRegiaoInsumo}
                            categoriaInsumoSelecionada={categoriaInsumoSelecionada}
                            cidadeInsumoSelecionada={cidadeInsumoSelecionada}
                            regiaoInsumoSelecionado={regiaoInsumoSelecionado}
                        />
                    </div>
                </Content>
            </Container>
        </>
    )
}

export default GraficosAdm;
