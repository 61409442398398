import React, { useState, useEffect } from 'react';

import { Container, Body, Buttons } from './styles';
import colors from '../../../styles/colors';

const InsumosRow = ({ insumos, setSelected, selected, selecionarTodosInsumos, setNotSelectedInsumos, notSelectedInsumos }) => {

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (notSelectedInsumos.includes(insumos.id)) {
            setChecked(false)
            return
        }
        setChecked(selecionarTodosInsumos)
    }, [selecionarTodosInsumos])

    const categorias = insumos.fk_categorias;
    const categoria = categorias?.map((data) => {
        return data.nome
    });

    function handleClick() {

        if (selecionarTodosInsumos) {
            const index = notSelectedInsumos.findIndex(item => item == insumos.id)
            if (index != -1) {
                notSelectedInsumos.splice(index, 1)
                setNotSelectedInsumos(notSelectedInsumos)
            } else {
                const auxiliar = [...notSelectedInsumos]
                auxiliar.push(insumos.id)
                setNotSelectedInsumos(auxiliar)
            }
            return
        }

        const index = selected.findIndex(item => item == insumos.id)
        if (index != -1) {
            selected.splice(index, 1)
            setSelected(selected)
        } else {
            const auxiliar = [...selected]
            auxiliar.push(insumos.id)
            setSelected(auxiliar)
        }
    };

    return (
        <>
            <Container style={{ background: checked && 'rgb(248, 170, 39, 0.2)' }}>
                {/* <Body>
                    <img src='https://picsum.photos/200' />
                </Body> */}
                <header>
                    <p>Nome comercial</p>
                    <span>{insumos.nome}</span>
                </header>
                <Body>
                    <p>Categoria</p>
                    <span>{categoria.join(', ')}</span>
                </Body>
                <Body>
                    <p>Princípio ativo</p>
                    <span>{insumos.principio_ativo}</span>
                </Body>
                <Buttons>
                    <div
                        type='checkbox'
                        value={checked}
                        onClick={() => { setChecked(!checked); handleClick() }}
                        style={{ backgroundColor: checked && colors.secondary }}
                    />
                </Buttons>
            </Container>
        </>
    )
}

export default InsumosRow;
