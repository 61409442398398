import React, { createContext } from "react";
import { toast } from "react-toastify";
import api from "../services/api";
import { getId } from "../services/auth";

const AuthContextInsumo = createContext({});

const AuthProviderInsumo = ({ children }) => {

  /* Função para carregar usuário */
  const loadingInsumo = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/insumo/${id}`);
      return data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* Função para delatar usuário */
  const deleteInsumo = async (id) => {
    try {
      const { data } = await api.delete(`/insumo/${id}`);
      toast.success(data.message);
      return data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

      return false;
    }
  };

  /* Função para atualiza usuário */
  const updateInsumo = async (data, id) => {
    try {
      const resp = await api.put(`/insumo/${id}`, data);
      toast.success(resp?.data?.message);
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

      return false;
    }
  };

  /* Função para criar um novo usuário */
  const createInsumo = async (data) => {
    try {
      const resp = await api.post(`/insumo`, data);
      !resp?.data?.id ?
        toast.error(resp?.data?.message) :
        toast.success("Cadastrado com sucesso!");
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
      return false;
    }
  };
  
  return (
    <AuthContextInsumo.Provider
      value={{
        deleteInsumo,
        loadingInsumo,
        updateInsumo, 
        createInsumo,
      }}
    >
      {children}
    </AuthContextInsumo.Provider>
  );
};

export { AuthContextInsumo, AuthProviderInsumo };