import Pagination from "@mui/material/Pagination";
import React, { useContext, useEffect, useState } from "react";
import { RiFilterLine, RiSearchLine } from "react-icons/ri";
import Select from "react-select";

import ProdutosQueTrabalhaRow from "../../../../components/ProdutosQueTrabalhaRow";
import InsumosRow from "../../../../components/ProdutosQueTrabalhaRow/InsumosRow";
import ProdutosRow from "../../../../components/ProdutosQueTrabalhaRow/ProdutosRow";
import { AuthContextFornecedorInsumo } from "../../../../contexts/FornecedorInsumosContext";
import { AuthContextFornecedorProduto } from "../../../../contexts/FornecedorProdutosContext";
import api from "../../../../services/api";
import { getId } from "../../../../services/auth";
import ModalDelete from "./ModalDelete";
import { Buscar, Buttons, Container, Content, Filtros, Tabela } from "./styles";

function ProdutosQueTrabalha() {
  const {
    loadingFornecedorProdutos,
    createFornecedorProduto,
    loadingFornecedorProdutosFiltro,
    createFornecedorProdutos,
  } = useContext(AuthContextFornecedorProduto);
  const {
    loadingFornecedorInsumos,
    createFornecedorInsumo,
    loadingFornecedorInsumosFiltro,
    createFornecedorInsumos,
  } = useContext(AuthContextFornecedorInsumo);

  const [botao, setBotao] = useState(1);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [produtoSelected, setProdutoSelected] = useState(null);
  const [selected, setSelected] = useState([]);
  const [notSelectedProdutos, setNotSelectedProdutos] = useState([]);
  const [notSelectedInsumos, setNotSelectedInsumos] = useState([]);

  const [selecionarTodosProdutos, setSelecionarTodosProdutos] = useState(false);
  const [selecionarTodosInsumos, setSelecionarTodosInsumos] = useState(false);

  const [categoria, setCategoria] = useState("");
  const [catego, setCatego] = useState([]);
  const [categoriaFornecedor, setCategoriaFornecedor] = useState("");

  const [produtos, setProdutos] = useState([]);
  const [produtosFiltro, setProdutosFiltro] = useState([]);
  const [produto, setProduto] = useState("");
  const [insumos, setInsumos] = useState([]);
  const [insumosFiltro, setInsumosFiltro] = useState([]);
  const [insumo, setInsumo] = useState("");

  const [buscar, setBuscar] = useState();

  const [fornecedorProdutos, setFornecedorProdutos] = useState([]);
  const [fornecedorProdutosFiltro, setFornecedorProdutosFiltro] = useState([]);
  const [fornecedorInsumos, setFornecedorInsumos] = useState([]);
  const [fornecedorInsumosFiltro, setFornecedorInsumosFiltro] = useState([]);
  const [produtoFornecedor, setProdutoFornecedor] = useState("");

  const [pageProdutos, setPageProdutos] = useState(0);
  const [totalPagesProdutos, setTotalPageProdutos] = useState();
  const [registrosProdutos, setRegistrosProdutos] = useState();
  const [pageInsumos, setPageInsumos] = useState(0);
  const [totalPagesInsumos, setTotalPagesInsumos] = useState();
  const [registrosInsumos, setRegistrosInsumos] = useState();

  const [botaoTodos, setBotaoTodos] = useState(false);

  const [fornecedorProdutosInsumosPage, setFornecedorProdutosInsumosPage] =
    useState(0);

  const loadingProdutos = async () => {
    try {
      const id = getId();
      const { data } = await api.get(
        `/fornecedor_produto/${id}/produto?page=${pageProdutos}`
      );
      setProdutos(data.content);
      setTotalPageProdutos(data.totalPages);
      setRegistrosProdutos(data.registros);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingProdutosFiltro = async () => {
    try {
      const id = getId();
      const { data } = await api.get(
        `/fornecedor_produto/${id}/produto_filtro`
      );
      setProdutosFiltro(
        data?.map((item) => ({
          value: item.id,
          label: item.nome,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const loadingProdutosFiltrado = async () => {
    try {
      const id = getId();
      const { data } = await api.get(
        `produto_nome/${id}/filtro?nomeProduto=${produto}&categoriaProduto=${categoria}`
      );
      setProdutos(data);
      setTotalPageProdutos(0);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingInsumos = async () => {
    try {
      const id = getId();
      const { data } = await api.get(
        `/fornecedor_insumo/${id}/insumo?page=${pageInsumos}`
      );
      setInsumos(data.content);
      setTotalPagesInsumos(data.totalPages);
      setRegistrosInsumos(data.registros);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingInsumosFiltro = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/fornecedor_insumo/${id}/insumo_filtro`);
      setInsumosFiltro(
        data?.map((item) => ({
          value: item.id,
          label: item.nome,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const loadingInsumosFiltrado = async () => {
    try {
      const id = getId();
      const { data } = await api.get(
        `insumo_nome/${id}/filtro?nomeInsumo=${insumo}&categoriaInsumo=${categoria}`
      );
      setInsumos(data);
      setTotalPagesInsumos(0);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingCategorias = async () => {
    try {
      const { data } = await api.get("/categoria_filtro");
      setCatego(
        data?.map((item) => ({
          value: item.id,
          label: item.nome,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const resetFilter = () => {

    setCategoriaFornecedor('')
    setCategoria('')
    setProdutoFornecedor("");
    setProduto("");
    setInsumo("");

    if (botao === 2) loadingProdutos();
    if (botao === 3) loadingInsumos();
  };

  useEffect(() => {
    loadingProdutos();
    loadingFornecedorProdutos(
      setFornecedorProdutos,
      produtoFornecedor,
      categoriaFornecedor
    );
    loadingFornecedorProdutosFiltro(setFornecedorProdutosFiltro);
    loadingInsumos();
    loadingFornecedorInsumos(
      setFornecedorInsumos,
      produtoFornecedor,
      categoriaFornecedor
    );
    loadingFornecedorInsumosFiltro(setFornecedorInsumosFiltro);
  }, [categoriaFornecedor, produtoFornecedor, pageProdutos, pageInsumos]);

  useEffect(() => {
    loadingCategorias();
    loadingProdutosFiltro();
    loadingInsumosFiltro();
  }, []);

  useEffect(() => {
    loadingProdutosFiltrado();
    loadingInsumosFiltrado();
  }, [produto, categoria, insumo]);

  const handleSubmitProduto = async () => {
    try {
      const id = getId();
      if (selected.length > 0) {
        await createFornecedorProduto(selected, id);
      } else {
        if (botaoTodos === true) {
          await createFornecedorProdutos(notSelectedProdutos, id, categoria);
        }
      }
      loadingFornecedorProdutos(setFornecedorProdutos);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitInsumo = async () => {
    try {
      const id = getId();
      if (selected.length !== 0) {
        await createFornecedorInsumo(selected, id);
      } else {
        if (botaoTodos === true) {
          await createFornecedorInsumos(notSelectedInsumos, id, categoria);
        }
      }
      loadingFornecedorInsumos(setFornecedorInsumos);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  // console.log('fornecedorInsumos:', fornecedorProdutos);

  const fornecedorProdutosInsumos = [
    ...fornecedorProdutos,
    ...fornecedorInsumos,
  ].slice(
    fornecedorProdutosInsumosPage * 10,
    fornecedorProdutosInsumosPage * 10 + 10
  );
  const toalPages = Math.ceil(
    [...fornecedorProdutos, ...fornecedorInsumos].length / 10
  );

  const produtosInsumosFiltro = [
    ...fornecedorProdutosFiltro,
    ...fornecedorInsumosFiltro,
  ];
  const filtroFornecedor = produtosInsumosFiltro?.map((item) => ({
    value: item.id,
    label: item.nome,
  }));

  const handleBuscar = () => {
    if (botao === 1) {
      if (buscar) {
        const buscaProdutos = fornecedorProdutos?.filter((item) =>
          item.nome.toLowerCase().includes(buscar.toLowerCase())
        );
        const buscaInsumos = fornecedorInsumos?.filter((item) =>
          item.nome.toLowerCase().includes(buscar.toLowerCase())
        );
        setFornecedorProdutos([...buscaProdutos]);
        setFornecedorInsumos([...buscaInsumos]);
        setBuscar([...buscaProdutos, ...buscaInsumos]);
        setFornecedorProdutosInsumosPage(0);
      }
    }

    if (botao === 2) {
      if (buscar) {
        const busca = produtos?.filter((item) =>
          item.nome.toLowerCase().includes(buscar.toLowerCase())
        );
        setProdutos([...busca]);
        setBuscar([...busca]);
        setTotalPageProdutos(0);
      }
    }

    if (botao === 3) {
      if (buscar) {
        const busca = insumos.filter((item) =>
          item.nome.toLowerCase().includes(buscar.toLowerCase())
        );
        setBuscar([...busca]);
        setInsumos([...busca]);
        setTotalPagesInsumos(0);
      }
    }
  };

  const customStyles = {
    control: () => ({
      width: 160,
      borderRadius: "8px",
      height: "35px",
      // backgroundColor: 'yellow'
    }),
    menuList: (provided) => ({
      ...provided,
      width: 160,
      display: "flex",
      flexDirection: "column",
      fontSize: 15,
    }),
    valueContainer: (provider) => ({
      ...provider,
      width: "100%",
      height: "100%",
    }),
  };

  const handleChangeProdutos = (event, value) => {
    let valorPage = value - 1;
    setPageProdutos(valorPage);
  };
  const handleChangeInsumos = (event, value) => {
    let valorPage = value - 1;
    setPageInsumos(valorPage);
  };

  return (
    <>
      {showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          produto={produtoSelected}
          setFornecedorProdutos={setFornecedorProdutos}
          setFornecedorInsumos={setFornecedorInsumos}
        />
      )}
      <Container>
        <Tabela>
          <Buscar>
            <input
              name="buscar"
              placeholder="buscar"
              onChange={(e) => setBuscar(e.target.value)}
            />
            <div id="button" type="button" onClick={() => handleBuscar()}>
              <RiSearchLine size={20} />
            </div>
          </Buscar>
          <div>
            <div
              id="p"
              type="button"
              onClick={() => setBotao(1)}
              style={{
                backgroundColor: botao === 1 ? "#20655F" : "#EFF1F9",
                color: botao === 1 ? "#FFF" : "#626679",
              }}
            >
              Produtos que Trabalha
            </div>
            {"|"}
            <div
              id="p"
              type="button"
              onClick={() => {
                setBotao(2);
                loadingProdutos();
              }}
              style={{
                backgroundColor: botao === 2 ? "#20655F" : "#EFF1F9",
                color: botao === 2 ? "#FFF" : "#626679",
              }}
            >
              Produtos
            </div>
            {"|"}
            <div
              id="p"
              type="button"
              onClick={() => {
                setBotao(3);
                loadingInsumos();
              }}
              style={{
                backgroundColor: botao === 3 ? "#20655F" : "#EFF1F9",
                color: botao === 3 ? "#FFF" : "#626679",
              }}
            >
              Insumos
            </div>
          </div>
        </Tabela>
        <Content>
          <div>
            <div>
              {botao === 1 && <h1>Produtos que trabalha</h1>}
              {botao === 2 && <h1>Lista de Produtos</h1>}
              {botao === 3 && <h1>Lista de Insumos</h1>}
            </div>

            <div>
              <button
                style={{
                  boxShadow: botao === 1 && "none",
                  backgroundColor: botao === 1 && "transparent",
                  cursor: botao !== 1 && "pointer",
                }}
                onClick={botao === 2 ? handleSubmitProduto : handleSubmitInsumo}
                disabled={botao === 1 && true}
              >
                {botao === 2 ? "Adicionar Produtos" : "Adicionar Insumos"}
              </button>
              <button
                style={{
                  boxShadow: botao === 1 && "none",
                  backgroundColor: botao === 1 && "transparent",
                  cursor: botao !== 1 && "pointer",
                }}
                onClick={() => {
                  botao === 2
                    ? setSelecionarTodosProdutos(!selecionarTodosProdutos)
                    : setSelecionarTodosInsumos(!selecionarTodosInsumos);
                  setBotaoTodos(true);
                }}
                disabled={botao === 1 || (produto && true)}
              >
                {botao === 2 ? "Todos Produtos" : "Todos Insumos"}
              </button>
            </div>
          </div>
          <div>
            <div>
              {botao === 1 && (
                <p>
                  Mais de {fornecedorProdutosInsumos.length} novos produtos{" "}
                </p>
              )}
              {botao === 2 && (
                <p>Mais de {registrosProdutos} novos produtos </p>
              )}
              {botao === 3 && <p>Mais de {registrosInsumos} novos produtos </p>}
            </div>
          </div>
        </Content>
        <Filtros>
          <section
            id="select"
            style={{
              boxShadow: "0px 5px 6px rgba(0,0,0,0.6)",
              marginRight: "25px",
            }}
          >
            <Select
              isMulti={false}
              value={
                (botao === 1 && produtoFornecedor != ""
                  ? { label: produtoFornecedor, value: "" }
                  : null) ||
                (botao === 2 && produto != ""
                  ? { label: produto, value: "" }
                  : null) ||
                (botao === 3 && insumo != ""
                  ? { label: insumo, value: "" }
                  : null)
              }
              onChange={(value) =>
                (botao === 1 && setProdutoFornecedor(value.label)) ||
                (botao === 2 && setProduto(value.label)) ||
                (botao === 3 && setInsumo(value.label))
              }
              placeholder={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RiFilterLine style={{ display: "flex" }} size={25} />
                  {botao === 1 && (
                    <p style={{ fontSize: "12px" }}>Filtrar Produtos</p>
                  )}
                  {botao === 2 && (
                    <p style={{ fontSize: "12px" }}>Filtrar por Produto</p>
                  )}
                  {botao === 3 && (
                    <p style={{ fontSize: "12px" }}>Filtrar por Insumo</p>
                  )}
                </div>
              }
              // options={botao === 1 ? filtroFornecedor : produtosFiltro}
              options={
                (botao === 1 && filtroFornecedor) ||
                (botao === 2 && produtosFiltro) ||
                (botao === 3 && insumosFiltro)
              }
              maxMenuHeight={140}
              menuPlacement="auto"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(12, 157, 164, 0.4)",
                  primary: "#20655F",
                },
              })}
            />
          </section>
          <section
            id="select"
            style={{ boxShadow: "0px 5px 6px rgba(0,0,0,0.6)" }}
          >
            <Select
              isMulti={false}
              value={ botao === 1
                ?
                (categoriaFornecedor != ""
                  ? { label: categoriaFornecedor, value: "" }
                  : null ) 
                  : (categoria != ""
                  ? { label: categoria, value: "" }
                  : null )
              }
              onChange={(value) =>
                botao === 1
                  ? setCategoriaFornecedor(value.label)
                  : setCategoria(value.label)
              }
              options={catego}
              placeholder={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RiFilterLine style={{ display: "flex" }} size={25} />
                  <p style={{ fontSize: "12px" }}>Filtrar por Categoria</p>
                </div>
              }
              maxMenuHeight={160}
              menuPlacement="auto"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(12, 157, 164, 0.4)",
                  primary: "#20655F",
                },
              })}
            />
          </section>
          <button
            style={{
              cursor: "pointer",
              color: "#20655F",
              fontWeight: "bold",
              padding: "8px 8px",
              borderRadius: "5px",
              marginLeft: "50px",
              fontSize: "12px",
              boxShadow: "0px 5px 6px rgba(0,0,0,0.6)",
            }}
            onClick={resetFilter}
          >
            Apagar Filtros
          </button>
        </Filtros>
        {botao === 1 &&
          fornecedorProdutosInsumos?.map((data, index) => (
            <ProdutosQueTrabalhaRow
              key={index}
              produto={data}
              setShowModalDelete={setShowModalDelete}
              setProdutoSelected={setProdutoSelected}
            />
          ))}
        {botao === 1 && (
          <Pagination
            count={toalPages}
            page={fornecedorProdutosInsumosPage + 1}
            onChange={(event, value) =>
              setFornecedorProdutosInsumosPage(value - 1)
            }
            shape="rounded"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "92px",
              marginBottom: "15px",
            }}
          />
        )}
        {botao === 2 &&
          produtos?.map((data) => (
            <ProdutosRow
              key={data.id}
              produtos={data}
              setSelected={setSelected}
              selected={selected}
              selecionarTodosProdutos={selecionarTodosProdutos}
              setNotSelectedProdutos={setNotSelectedProdutos}
              notSelectedProdutos={notSelectedProdutos}
            />
          ))}
        {botao === 2 && (
          <Pagination
            count={totalPagesProdutos}
            page={pageProdutos + 1}
            onChange={handleChangeProdutos}
            shape="rounded"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "92px",
            }}
          />
        )}
        {botao === 3 &&
          insumos?.map((data) => (
            <InsumosRow
              key={data.id}
              insumos={data}
              setSelected={setSelected}
              selected={selected}
              selecionarTodosInsumos={selecionarTodosInsumos}
              setNotSelectedInsumos={setNotSelectedInsumos}
              notSelectedInsumos={notSelectedInsumos}
            />
          ))}
        {botao === 3 && (
          <Pagination
            count={totalPagesInsumos}
            page={pageInsumos + 1}
            onChange={handleChangeInsumos}
            shape="rounded"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "92px",
            }}
          />
        )}
      </Container>
    </>
  );
}

export default ProdutosQueTrabalha;
