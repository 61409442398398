import React from 'react';
import { StepNumber, StepsDescriptionContainer, StepStatusContainer, StepStatusWrapper } from './styles';

const Steps = ({ steps, currentStep, ...rest }) => {
  return (
    <StepsDescriptionContainer>
      {
        steps
          .map((status, index) =>
            <StepStatusWrapper key={status} data-stepindex={index + 1} lastIndex={steps.length}>
              <StepNumber
                active={currentStep >= index + 1}
                data-activeindex={currentStep}
                data-stepindex={index + 1}
              >
                <span>{index + 1}</span>
              </StepNumber>
              {
                <StepStatusContainer active={currentStep >= index + 1}>{status}</StepStatusContainer>
              }
            </StepStatusWrapper>
          )
      }
    </StepsDescriptionContainer>
  );
}

export default Steps;
