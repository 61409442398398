import React, { useState, useEffect } from 'react'
import { format } from 'date-fns';
import { Container, Body, Image } from './styles';
import imagem from "../../assets/logo2.png"

const EnviadosAdminRow = ({ enviados, setShowModalEnvioOrcamento, setUserSelected, setShowModalProvider }) => {

    const [data, setData] = useState();
    const fornecedorOrcamentos = enviados?.fk_fornecedorOrcamentos;

    const valores = fornecedorOrcamentos?.map((data) => {
        return data.valor_orcamento
    });
    // const ranking = fornecedorOrcamentos?.map((data) => {
    //     return data.ranking
    // });
    const dataAtualizadas = fornecedorOrcamentos?.map((item) => {
        return item.updatedAt
    });
    // const fornecedor = fornecedorOrcamentos?.map((item) => {
    //     return item.fk_fornecedor?.nome_estabelecimento
    // });
    // const fornecedorEndereco = fornecedorOrcamentos?.map((item) => {
    //     return item.fk_fornecedor?.endereco
    // });

    useEffect(() => {
        if (dataAtualizadas) {
            setData(format(new Date(dataAtualizadas), 'dd/MM/yyyy'))
        }
    }, [dataAtualizadas]);

    return (
        <>
            <Container>
                <header>
                    <p>{enviados.fk_user.nome}</p>
                </header>
                <Body>
                    <p>{enviados.nome_comercial}</p>
                    <span>{enviados.marca}</span>
                </Body>
                {/* <Body>
                    <p>Total Orçado</p>
                    <span>{valores}</span>
                </Body> */}
                <Body>
                    <p>Data da Resposta</p>
                    <span>{data}</span>
                </Body>

                <Body>
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => { setShowModalEnvioOrcamento(true); setUserSelected(enviados) }}
                    >
                        {"ººº"}
                    </span>
                </Body>
            </Container>
        </>
    )
}

export default EnviadosAdminRow;
