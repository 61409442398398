import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { toast } from "react-toastify";

import CidadeRow from "../../../components/CidadeRow";
import RegiaoRow from "../../../components/RegiaoRow";
import api from "../../../services/api";
import ModalCreateCidade from "./ModaisCidade/ModalCreate";
import ModalDeleteCidade from "./ModaisCidade/ModalDelete";
import ModalEditCidade from "./ModaisCidade/ModalEdit";
import ModalCreate from "./ModaisRegiao/ModalCreate";
import ModalDelete from "./ModaisRegiao/ModalDelete";
import ModalEdit from "./ModaisRegiao/ModalEdit";
import { Buscar, Container, Content, Header } from "./styles";

function RegiaoCidades() {
  const [botao, setBotao] = useState();
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [userSelected, setUserSelected] = useState(null);

  const [regiao, setRegiao] = useState([]);
  const [buscarRegiao, setBuscarRegiao] = useState("");

  const [pageRegiao, setPageRegiao] = useState(0);
  const [totalPagesRegiao, setTotalPagesRegiao] = useState();

  const [cidade, setCidade] = useState([]);
  const [buscarCidade, setBuscarCidade] = useState("");

  const [pageCidades, setPageCidades] = useState(0);
  const [totalPagesCidades, setTotalPagesCidades] = useState();

  const loadingRegiao = async () => {
    try {
      const { data } = await api.get(
        `/regiao?page=${pageRegiao}&search=${buscarRegiao}`
      );
      setRegiao(data.content);
      setTotalPagesRegiao(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadingRegiao();
  }, [pageRegiao, buscarRegiao]);

  /*==================== Funções para Cidades ====================*/
  const loadingCidade = async () => {
    try {
      const { data } = await api.get(
        `/cidade?page=${pageCidades}&search=${buscarCidade}`
      );
      setCidade(data.content);
      setTotalPagesCidades(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadingCidade();
  }, [buscarCidade, pageCidades]);

  return (
    <>
      {botao === 1 && showModalCreate && (
        <ModalCreate
          setShowModalCreate={setShowModalCreate}
          loading={loadingRegiao}
        />
      )}
      {botao === 3 && showModalCreate && (
        <ModalCreateCidade
          setShowModalCreate={setShowModalCreate}
          loading={loadingCidade}
        />
      )}
      {botao === 1 && showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          user={userSelected}
          loading={loadingRegiao}
        />
      )}
      {botao === 3 && showModalDelete && (
        <ModalDeleteCidade
          setShowModalDelete={setShowModalDelete}
          user={userSelected}
          loading={loadingCidade}
        />
      )}
      {botao === 1 && showModalEdit && (
        <ModalEdit
          setShowModalEdit={setShowModalEdit}
          user={userSelected}
          loading={loadingRegiao}
        />
      )}
      {botao === 3 && showModalEdit && (
        <ModalEditCidade
          setShowModalEdit={setShowModalEdit}
          user={userSelected}
          loading={loadingCidade}
        />
      )}
      <Container>
        <Header>
          <div>
            <h1>Regiões</h1>
          </div>
          <Buscar style={{ width: "30%" }}>
            <input
              style={{ marginLeft: "10px" }}
              name="buscar"
              placeholder="buscar"
              onChange={(e) => {
                setPageRegiao(0);
                setBuscarRegiao(e.target.value);
              }}
            />
            <div id="button" type="button">
              <RiSearchLine size={20} />
            </div>
          </Buscar>
          <div>
            <button
              onClick={() => {
                setShowModalCreate(!showModalCreate);
                setBotao(1);
              }}
            >
              +
            </button>
          </div>
        </Header>
        {regiao?.map((data, index) => (
          <RegiaoRow
            key={index}
            regiao={data}
            setShowModalDelete={setShowModalDelete}
            setShowModalEdit={setShowModalEdit}
            setUserSelected={setUserSelected}
            setBotao={setBotao}
          />
        ))}
        <Pagination
          count={totalPagesRegiao}
          page={pageRegiao + 1}
          onChange={(event, value) => setPageRegiao(value - 1)}
          shape="rounded"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <Content>
          <div>
            <Header>
              <div>
                <h1>Cidades</h1>
              </div>
              <Buscar style={{ width: "30%" }}>
                <input
                  style={{ marginLeft: "10px" }}
                  name="buscar"
                  placeholder="buscar"
                  onChange={(e) => {
                    setPageCidades(0);
                    setBuscarCidade(e.target.value);
                  }}
                />
                <div id="button" type="button">
                  <RiSearchLine size={20} />
                </div>
              </Buscar>
              <div>
                <button
                  onClick={() => {
                    setShowModalCreate(!showModalCreate);
                    setBotao(3);
                  }}
                >
                  +
                </button>
              </div>
            </Header>
            {cidade?.map((data, index) => (
              <CidadeRow
                key={index}
                cidade={data}
                setShowModalDelete={setShowModalDelete}
                setShowModalEdit={setShowModalEdit}
                setUserSelected={setUserSelected}
                setBotao={setBotao}
              />
            ))}
            <Pagination
              count={totalPagesCidades}
              page={pageCidades + 1}
              onChange={(event, value) => setPageCidades(value - 1)}
              shape="rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </div>
        </Content>
      </Container>
    </>
  );
}

export default RegiaoCidades;
