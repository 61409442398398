import React, { useState, useEffect } from "react";
import {
    RiFilterLine,
} from "react-icons/ri";
import CotacoesRecebidasRespondidas from "./CotacoesRecebidasRespondidas";
import ContatoVisualizado from "./ContatoVisualizado";
import Select from "react-select";

import { Container, Header, Content, Filtros } from "./styles";

function GraficosFornecedor() {

    const [mes, setMes] = useState([]);
    const [filtroMesSelecionado, setFiltroMesSelecionado] = useState('');
    const [ano, setAno] = useState([]);
    const [filtroAnoSelecionado, setFiltroAnoSelecionado] = useState('');

    const [filtroMesContato, setFiltroMesContato] = useState([]);
    const [mesSelecionado, setMesSelecionado] = useState('');
    const [filtroAnoContato, setFiltroAnoContato] = useState([]);
    const [anoSelecionado, setAnoSelecionado] = useState('');

    const customStyles = {
        control: () => ({
            width: 150,
            borderRadius: '8px',
            height: '35px',
            // backgroundColor: 'yellow'
        }),
        menuList: (provided) => ({
            ...provided,
            width: 150,
            display: 'flex',
            flexDirection: 'column',
            fontSize: 15,
        }),
        valueContainer: (provider) => ({
            ...provider,
            width: '100%',
            height: '100%'
        })
    };
    return (
        <>
            <Container>
                <Header>
                    <div>
                        <h1>Gráficos Fornecedor</h1>
                    </div>
                </Header>
                <Content>
                    <div id='soma'>
                        <h1>Cotações Recebidos/Respondidos</h1>
                        <Filtros>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setFiltroMesSelecionado(value.label)}
                                    options={mes}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Mês</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setFiltroAnoSelecionado(value.label)}
                                    options={ano}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Ano</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="botao" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}
                                onClick={() => {
                                    setFiltroMesSelecionado('');
                                    setFiltroAnoSelecionado('');
                                }}>
                                <p>Limpar filtro</p>
                            </section>
                        </Filtros>
                    </div>
                    <div>
                        <CotacoesRecebidasRespondidas
                            setMes={setMes}
                            filtroMesSelecionado={filtroMesSelecionado}
                            setAno={setAno}
                            filtroAnoSelecionado={filtroAnoSelecionado}
                        />
                    </div>
                    <div id='soma'>
                        <h1>Contato Visualizado</h1>
                        <Filtros>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setMesSelecionado(value.label)}
                                    options={filtroMesContato}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Mês</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}>
                                <Select
                                    isMulti={false}
                                    onChange={value => setAnoSelecionado(value.label)}
                                    options={filtroAnoContato}
                                    placeholder={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <RiFilterLine style={{ display: 'flex' }} size={25} />
                                            <p style={{ fontSize: '12px' }}>Filtrar por Ano</p>
                                        </div>
                                    }
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </section>
                            <section id="botao" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', cursor: 'pointer' }}
                                onClick={() => {
                                    setMesSelecionado('');
                                    setAnoSelecionado('');
                                }}>
                                <p>Limpar filtro</p>
                            </section>
                        </Filtros>
                    </div>
                    <div>
                        <ContatoVisualizado
                            setFiltroMesContato={setFiltroMesContato}
                            mesSelecionado={mesSelecionado}
                            setFiltroAnoContato={setFiltroAnoContato}
                            anoSelecionado={anoSelecionado}
                        />
                    </div>
                </Content>
            </Container>
        </>
    )
}

export default GraficosFornecedor;
