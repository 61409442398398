import React, { createContext } from "react";
import { toast } from "react-toastify";
import api from "../services/api";
import { getId } from "../services/auth";

const AuthContextCategoria = createContext({});

const AuthProviderCategoria = ({ children }) => {

  /* Função para carregar usuário */
  const loadingCategoria = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/categoria/${id}`);
      return data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* Função para delatar usuário */
  const deleteCategoria = async (id) => {
    try {
      const { data } = await api.delete(`/categoria/${id}`);
      toast.success(data.message);
      return data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

      return false;
    }
  };

  /* Função para atualiza usuário */
  const updateCategoria = async (data, id) => {
    try {
      const resp = await api.put(`/categoria/${id}`, data);
      toast.success(resp?.data?.message);
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

      return false;
    }
  };

  /* Função para criar um novo usuário */
  const createCategoria = async (data) => {
    try {
      const resp = await api.post(`/categoria`, data);
      !resp?.data?.id ?
        toast.error(resp?.data?.message) :
        toast.success("Cadastrado com sucesso!");
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
      return false;
    }
  };

  return (
    <AuthContextCategoria.Provider
      value={{
        deleteCategoria,
        loadingCategoria,
        updateCategoria,
        createCategoria,
      }}
    >
      {children}
    </AuthContextCategoria.Provider>
  );
};

export { AuthContextCategoria, AuthProviderCategoria };