import React, { useRef } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Container, Forms, Body } from './styles';
import getValidationErrors from "../../../utils/getValidationsErrors";
import api from "../../../services/api";
import { toast } from "react-toastify";

import * as Yup from "yup";
import { RiMailLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";


function Recover() {
    const formRef = useRef(null);
    const History = useHistory();

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                email: Yup.string()
                    .required("e-mail obrigatório!")
                    .email("O email precisa ser válido"), 
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});

            // delete data.senha_confirmation;
            const email = data.email
            localStorage.setItem("recovery",email);
            const resp = await api.post(`/fornecedor/${email}/password/sendToken`);
            toast.success(resp?.data?.message);
            History.push('/recoveryPassword');

        } catch (err) {
            console.log(err);
            // const errors = getValidationErrors(err);
            // formRef.current?.setErrors(errors);
        }
    };

    return (
        <Container>
            <Body>
                <h1>Recuperação de Senha</h1>
                <span> Insira seu email para recuperar sua senha </span>
                <Forms ref={formRef} onSubmit={handleSubmit}>
                    <div>
                        <span>E-mail</span>
                        <Input name="email" placeholder="usuário@gmail.com"
                            icon={RiMailLine}
                        />
                    </div>
                    <Button style={{ fontSize: "20px" }}>Enviar</Button>
                </Forms>
                <div>
                    <a href="/">Cancelar</a>
                </div>
            </Body>
        </Container>
    );
}

export default Recover;