import React, { useContext, useEffect, useRef, useState } from "react";
import { RiBuilding4Line, RiCommunityLine, RiHotelLine, RiLock2Line, RiMailLine, RiMapPinUserLine, RiPhoneLine, RiRoadMapLine, RiWhatsappLine } from "react-icons/ri";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { AuthContextFornecedor } from "../../../../contexts/FornecedorContext";
import { getId } from "../../../../services/auth";
import colors from "../../../../styles/colors";
import getValidationErrors from "../../../../utils/getValidationsErrors";
import Cidade from "./Cidade";
import ModalCidade from "./ModalCidade";
import ModalRegiao from "./ModalRegiao";
import { Body, Column, Container, Content, Forms } from "./styles";

function Perfil() {
    const [user, setUser] = useState({});
    // console.log('user', user);
    const formRef = useRef(null);
    const { loadingFornecedor, updateFornecedor } = useContext(AuthContextFornecedor);
    const id = getId();

    const [showModalRegiao, setShowModalRegiao] = useState(false);
    const [regiaoSelected, setRegiaoSelected] = useState([]);
    const [regiaoUser, setRegiaoUser] = useState([]);

    const [showModalCidade, setShowModalCidade] = useState(false);
    const [cidadesSelecionadas, setCidadesSelecionadas] = useState([]);
    const [cidadesAtuacao, setCidadesAtuacao] = useState([]);

    const [showCidade, setShowCidade] = useState(false);
    const [cidadeSelecionada, setCidadeSelecionada] = useState('');

    useEffect(async () => {
        const u = await loadingFornecedor()
        console.log(u)
        setUser(u);
        setCidadesAtuacao(u.cidades_atuacao.split(", "))
        if (u.regiao) {
            setRegiaoUser(u?.regiao.split(", "));
            setRegiaoSelected(u?.regiao.split(", "));
        }
    }, []);

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                nome_estabelecimento: Yup.string()
                    .required("nome Estabelecimento Obrigatório!"),
                email: Yup.string()
                    .required("Email Obrigatório!"),
                telefone: Yup.string()
                    .required("Telefone Obrigatório!"),
                cidade: Yup.string()
                    .required("Cidade Obrigatória!"),
                endereco: Yup.string()
                    .required("Endereço Obrigatório!"),
                regiao: Yup.string()
                    .required("Regiao Obrigatória!"),
                // cidades_atuacao: Yup.string()
                //     .required("cidade atuação Obrigatória!"),
            });

            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});

            if (data.senha.length < 6 && data.senha.length > 0) {
                toast.error("A senha precisa ter no mínimo 6 caracteres");
                return false;
            }

            if (data.senha == "") delete data.senha;

            await updateFornecedor(data, id);
            setUser(await loadingFornecedor());
            window.location.reload();

        } catch (err) {
            console.log(err);
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    const cidades = cidadesSelecionadas?.map((data) => {
        return data
    });

    const regiaoSelecionada = regiaoSelected?.map((data) => {
        return data
    });

    return (
        <>
            {showModalRegiao && (
                <ModalRegiao
                    setShowModalRegiao={setShowModalRegiao}
                    regiaoSelected={regiaoSelected}
                    setRegiaoSelected={setRegiaoSelected}
                    regiaoUser={regiaoUser}
                />
            )}
            {showModalCidade && (
                <ModalCidade
                    showModalCidade={showModalCidade}
                    setShowModalCidade={setShowModalCidade}
                    regiao={regiaoSelecionada}
                    regiaoSelected={regiaoSelected}
                    cidadesSelecionadas={cidadesSelecionadas}
                    setCidadesSelecionadas={setCidadesSelecionadas}
                    cidadesAtuacao={cidadesAtuacao}
                    setCidadesAtuacao={setCidadesAtuacao}
                />
            )}
            {showCidade && (
                <Cidade
                    setShowCidade={setShowCidade}
                    setCidadeSelecionada={setCidadeSelecionada}
                />
            )}
            <Container>
                <h1>Editar seu Perfil</h1>
                <span>Edição de perfil</span>
                <Forms ref={formRef} onSubmit={handleSubmit}
                    initialData={{ ...user, senha: "" }}
                >
                    <Content>
                        <Column>
                            <div>
                                <span>Nome do Estabelecimento</span>
                                <Input name="nome_estabelecimento" placeholder="Nome do estabelecimento"
                                    icon={RiBuilding4Line}
                                />
                            </div>
                            <div>
                                <span>Email</span>
                                <Input name="email" placeholder="Seu email" color="#3F4254"
                                    icon={RiMailLine}
                                />
                            </div>
                            <div>
                                <span>Telefone para Contato</span>
                                <Input name="telefone" placeholder="(99)9999-9999"
                                    icon={RiPhoneLine} formatar="(99)9999-9999"
                                />
                            </div>
                            <div onClick={() => setShowCidade(true)} style={{ cursor: 'pointer' }}>
                                <span>Cidade</span>
                                <Input
                                    name="cidade"
                                    placeholder="Cidade"
                                    icon={RiCommunityLine}
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) =>{
                                        e.preventDefault();
                                        setShowCidade(true)
                                      }}
                                    value={cidadeSelecionada ? cidadeSelecionada : user?.cidade}
                                />
                            </div>
                        </Column>
                        <Column style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                            <div>
                                <span>Endereço Completo</span>
                                <Input name="endereco" placeholder="Seu Endereço"
                                    icon={RiMapPinUserLine}
                                />
                            </div>
                            {/* <div>
                                <span>Data da Assinatura</span>
                                <Input name="data_assinatura" placeholder="Data da sua assinatura"
                                    icon={RiCalendarTodoFill}
                                />
                            </div> */}
                            <div>
                                <span>Whatsapp</span>
                                <Input name="whatsapp" placeholder="(99)9999-9999"
                                    icon={RiWhatsappLine} formatar="(99)99999-9999"
                                />
                            </div>
                            <div>
                                <span>Senha</span>
                                <Input name="senha" placeholder="****" type="password"
                                    icon={RiLock2Line}
                                />
                            </div>
                            <div onClick={() => setShowModalRegiao(true)} style={{ cursor: 'pointer' }}>
                                <span >Região de Atuação</span>
                                <Input
                                    name="regiao"
                                    placeholder="Noroeste de Minas, Norte de Minas."
                                    icon={RiRoadMapLine}
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) =>{
                                        e.preventDefault();
                                        setShowModalRegiao(true)
                                      }}
                                    value={regiaoSelecionada.length > 0 ? regiaoSelecionada.join(', ') : user?.regiao}
                                />
                            </div>
                        </Column>
                        <Column>
                            <div onClick={() => setShowModalCidade(true)} style={{ cursor: 'pointer' }}>
                                <span>Cidades de Atuação</span>
                                <Input
                                    name="cidades_atuacao"
                                    placeholder="Joao Pinheiro, Lagamar..."
                                    icon={RiHotelLine}
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) =>{
                                        e.preventDefault();
                                        setShowModalCidade(true)
                                      }}
                                    value={cidadesSelecionadas.length > 0 ? cidades.join(", ") : user.cidades_atuacao}
                                />
                            </div>
                        </Column>
                    </Content>
                    <div id="button" style={{ backgroundColor: "#FFF" }}>
                        <Button style={{ fontSize: '15px', width: "20%" }} backgroundColor={colors.secondary}>
                            Salvar
                        </Button>
                    </div>
                </Forms>
            </Container>
        </>
    );
}

export default Perfil;