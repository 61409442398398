import styled from 'styled-components';
import colors from "../../../../../styles/colors";

export const StepNumber = styled.div`
  background: ${props => props.active ? colors.green : colors.extraLight};
  color: ${colors.white};
  font-size: 13px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: initial;

  span {
    z-index: 2;
  }

  &:before,
  &:after {
    background: ${props => props.active ? colors.green : colors.extraLight};
    content: '';
    display: block;
    height: 5px;
    width: 50%;
    position: absolute;
    z-index: 0;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  &[data-stepindex="1"]:before {
    width: 75%;
  }

  &[data-activeindex="1"][data-stepindex="1"]:after {
    width: 25%;
    background: ${colors.extraLight};
  }

  &[data-activeindex="4"][data-stepindex="5"]:before {
    width: 25%;
    background: ${colors.green};
  }

  &[data-stepindex="5"]:after {
    width: 75%;
  }
`;

export const StepsDescriptionContainer = styled.div`
  display: flex;
  font-weight: bold;
  line-height: 1.2;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-light-grey);
`;

export const StepStatusContainer = styled.div`
  font-size: 13px;
  color: ${props => props.active ? colors.green : colors.extraLight};
  padding: 10px 0;
  word-wrap: break-word;
`;



export const StepStatusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  &[data-stepindex="1"] {
    align-items: start;
  }

  &[data-stepindex="${props => props.lastIndex}"] {
    align-items: flex-end;
  }
`;
