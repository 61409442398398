import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { RiFilterLine, RiSearchLine } from "react-icons/ri";
import Select from "react-select";
import { toast } from "react-toastify";

import UserRow from "../../../components/UserRow";
import api from "../../../services/api";
import ModalCreate from "./ModalCreate";
import ModalDelete from "./ModalDelete";
import ModalEdit from "./ModalEdit";
import { Buscar, Container, Content, Filtros } from "./styles";

function ListagemUser() {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [userSelected, setUserSelected] = useState(null);

  const [users, setUsers] = useState([]);
  const [usersBusca, setUsersBusca] = useState([]);

  const [buscar, setBuscar] = useState("");

  const [status, setStatus] = useState("");
  const [cultura, setCultura] = useState([]);
  const [selectedCultura, setSelectedCultura] = useState("");
  const [cidades, setCidades] = useState([]);
  const [selectedCidade, setSelectedCidade] = useState("");
  const [regiao, setRegiao] = useState([]);
  const [selectedRegiao, setSelectedRegiao] = useState("");

  const [page, setPage] = useState(0);

  const [pages, setPages] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [pagesBusca, setPagesBusca] = useState(0);
  const [totalPagesBusca, setTotalPagesBusca] = useState();

  const [registros, setRegistros] = useState();

  const loadingUsers = async () => {
    try {
      const { data } = await api.get(`/users?page=${pages}`);
      setUsers(data);
      // setTotalPages(data.totalPages);
      setRegistros(data.length);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingFiltros = async () => {
    try {
      const { data } = await api.get(`users_filtro`);
      const teste = data?.map((item) => item.cultura.split(",")).join(", ");
      const cultura = [];
      teste.split(", ")?.map((item) => {
        if (!cultura.includes(item) && item !== "") {
          cultura.push(item);
        }
      });
      setCultura(
        cultura?.map((item) => ({
          value: item,
          label: item,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const loadingUserNome = async () => {
    try {
      const { data } = await api.get(
        `/users_nome?nome=${buscar}&cultura=${selectedCultura}&cidade=${selectedCidade}&regiao=${selectedRegiao}`
      );
      if (data.length === 0) {
        toast.error("Usuário não encontrado");
        return false;
      }
      setUsersBusca(data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingCidades = async () => {
    try {
      const { data } = await api.get(`/cidade_all`);
      let cd = [];
      data?.map((item) => {
        if (!cd.includes(item.nome)) {
          cd.push(item);
        }
      });
      setCidades(
        cd?.map((item) => ({
          value: item.nome,
          label: item.nome,
        }))
      );
    } catch (err) {
      console.log("loadingCidadesError:", err);
    }
  };

  const loadingRegião = async () => {
    const { data } = await api.get(`/regiao_all`);
    let regiao = [];
    data?.map((item) => {
      if (!regiao.includes(item.nome)) {
        regiao.push(item);
      }
      setRegiao(
        regiao?.map((item) => ({
          value: item.nome,
          label: item.nome,
        }))
      );
    });
  };

  const resetFilter = () => {
    setSelectedRegiao("");
    setSelectedCidade("");
    setSelectedCultura("");

    loadingUsers();
  };

  useEffect(() => {
    if (usersBusca.length > 0) {
      const buscaUser = [...usersBusca].slice(
        pagesBusca * 10,
        pagesBusca * 10 + 10
      );
      setUsers(buscaUser);

      const totalPages = Math.ceil([...usersBusca].length / 10);
      setTotalPagesBusca(totalPages);
    }
  }, [usersBusca, pagesBusca]);

  useEffect(() => {
    if(buscar === '') {loadingUsers()};

  }, [buscar]);

  useEffect(() => {
    if (selectedCultura || selectedCidade || selectedRegiao) {
      loadingUserNome();
    }
  }, [selectedCultura, selectedCidade, selectedRegiao]);

  const handleChange = (event, value) => {
    const valorPage = value - 1;
    usersBusca.length > 0 ? setPagesBusca(valorPage) : setPage(valorPage);
  };

  const customStyles = {
    control: () => ({
      width: 150,
      borderRadius: "8px",
      height: "35px",
      // backgroundColor: 'yellow'
    }),
    menuList: (provided) => ({
      ...provided,
      width: 150,
      display: "flex",
      flexDirection: "column",
      fontSize: 15,
    }),
    valueContainer: (provider) => ({
      ...provider,
      width: "100%",
      height: "100%",
    }),
  };

  const usersPaginado = users.slice(page * 10, page * 10 + 10);
  const pagesTotal = Math.ceil(users.length / 10);

  return (
    <>
      {showModalEdit && (
        <ModalEdit
          setShowModalEdit={setShowModalEdit}
          user={userSelected}
          loading={loadingUsers}
        />
      )}
      {showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          user={userSelected}
          loading={loadingUsers}
        />
      )}
      {showModalCreate && (
        <ModalCreate
          setShowModalCreate={setShowModalCreate}
          loading={loadingUsers}
        />
      )}

      <Container>
        <Content>
          <div style={{ marginBottom: "15px" }}>
            <p>Usuários</p>
            <Buscar>
              <input
                style={{ marginLeft: "10px" }}
                name="buscar"
                placeholder="buscar"
                onChange={(e) => setBuscar(e.target.value)}
              />
              <div id="button" type="button" onClick={() => loadingUserNome()}>
                <RiSearchLine size={20} />
              </div>
            </Buscar>

            <button onClick={() => setShowModalCreate(!showModalCreate)}>
              +
            </button>
          </div>
          <div>
            <p id="p">Mais de {registros} novos usuários</p>
          </div>
        </Content>
        <Filtros>
          <section
            id="select"
            style={{
              boxShadow: "0px 5px 6px rgba(0,0,0,0.6)",
              marginRight: "25px",
            }}
          >
            <Select
              value={
                selectedCultura != ""
                  ? { label: selectedCultura, value: "" }
                  : null
              }
              isMulti={false}
              onChange={(value) => setSelectedCultura(value.label)}
              options={cultura}
              placeholder={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RiFilterLine style={{ display: "flex" }} size={25} />
                  <p style={{ fontSize: "12px" }}>Filtrar por Cultura</p>
                </div>
              }
              maxMenuHeight={160}
              menuPlacement="auto"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(12, 157, 164, 0.4)",
                  primary: "#20655F",
                },
              })}
            />
          </section>
          <section
            id="select"
            style={{ boxShadow: "0px 5px 6px rgba(0,0,0,0.6)" }}
          >
            <Select
              value={
                selectedCidade != ""
                  ? { label: selectedCidade, value: "" }
                  : null
              }
              isMulti={false}
              onChange={(value) => setSelectedCidade(value.label)}
              options={cidades}
              placeholder={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RiFilterLine style={{ display: "flex" }} size={25} />
                  <p style={{ fontSize: "12px" }}>Filtrar por Cidade</p>
                </div>
              }
              maxMenuHeight={160}
              menuPlacement="auto"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(12, 157, 164, 0.4)",
                  primary: "#20655F",
                },
              })}
            />
          </section>
          <section
            id="select"
            style={{ boxShadow: "0px 5px 6px rgba(0,0,0,0.6)" }}
          >
            <Select
              value={
                selectedRegiao != ""
                  ? { label: selectedRegiao, value: "" }
                  : null
              }
              isMulti={false}
              onChange={(value) => setSelectedRegiao(value.label)}
              options={regiao}
              placeholder={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RiFilterLine style={{ display: "flex" }} size={25} />
                  <p style={{ fontSize: "12px" }}>Filtrar por Região</p>
                </div>
              }
              maxMenuHeight={160}
              menuPlacement="auto"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(12, 157, 164, 0.4)",
                  primary: "#20655F",
                },
              })}
            />
          </section>
          <button
            style={{
              cursor: 'pointer',
              color:  "#20655F",
              fontWeight: 'bold',
              padding: '8px 8px',
              borderRadius:  '5px',
              marginLeft: "50px",
              fontSize: "12px",
              boxShadow: "0px 5px 6px rgba(0,0,0,0.6)",
            }}
            onClick={resetFilter}
          >
            Apagar Filtros
          </button>
        </Filtros>
        {usersPaginado?.map((user, index) => (
          <UserRow
            key={index}
            users={user}
            setUserSelected={setUserSelected}
            setShowModalEdit={setShowModalEdit}
            setShowModalDelete={setShowModalDelete}
          />
        ))}
        <Pagination
          count={usersBusca.length > 0 ? totalPagesBusca : pagesTotal}
          page={usersBusca.length > 0 ? pagesBusca + 1 : page + 1}
          onChange={handleChange}
          shape="rounded"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "12px",
          }}
        />
      </Container>
    </>
  );
}

export default ListagemUser;
