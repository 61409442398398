import React, { useState, useEffect } from "react";

import { RiSearchLine } from "react-icons/ri";
import { Container, Body, Content, Image, Buscar } from "./styles";
import RegiaoUserRow from "../../../../../components/RegiaoUserRow";
import api from "../../../../../services/api";

function Regiao({ setShowModalRegiao, setRegiaoSelected }) {

    const [regiao, setRegiao] = useState([]);
    const [buscar, setBuscar] = useState('');

    const loadingRegiao = async () => {
        try {
            const { data } = await api.get(`/regiao_all`);
            setRegiao(data);
        } catch (err) {
            console.log(err);
        }
    };

    const loadingRegiaoNome = async () => {
        try {
            if (buscar) {
                const { data } = await api.post(`/regiao_nome`, { nome: buscar });
                setRegiao(data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadingRegiao();
    }, []);

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Regiões</h1>
                    <Image />
                </div>
                <p>Selecione sua regiao</p>
                <Buscar>
                    <input style={{ marginLeft: '10px' }} name="buscar" placeholder="buscar" onChange={(e) => setBuscar(e.target.value)} />
                    <div id='botao' style={{height: '35px'}} type="button" onClick={() => loadingRegiaoNome()}>
                        <RiSearchLine size={20} />
                    </div>
                </Buscar>
                <Content>
                    <div>
                        {regiao?.map((data, index) => (
                            <RegiaoUserRow
                                key={index}
                                regiao={data}
                                setShowModalRegiao={setShowModalRegiao}
                                setRegiaoSelected={setRegiaoSelected}
                            />
                        ))}
                    </div>
                </Content>
                <a href="#" onClick={() => setShowModalRegiao(false)}>
                    Voltar
                </a>
            </Body>
        </Container>
    );
}

export default Regiao;