import React, { useRef } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Container, Forms, Body } from './styles';
import getValidationErrors from "../../../utils/getValidationsErrors";
import api from "../../../services/api";
import { toast } from "react-toastify";

import * as Yup from "yup";
import { RiMailLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";

function RecoveryPassword() {
    const formRef = useRef(null);
    const History = useHistory();

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                token: Yup.string()
                .required("token obrigatório!"),
                senha: Yup.string()
                .required("senha obrigatório!")
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});

            // delete data.senha_confirmation;
            const email = localStorage.getItem("recovery");
            const token = data.token
            const senha = data.senha
            const resp = await api.post(`/fornecedor/${email}/password/reset`, { token: token, senha: senha });
            toast.success(resp?.data?.message);
            History.push("/")

        } catch (err) {
            console.log(err);
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    return (
        <Container>
            <Body>
                <h1>Recuperação de Senha</h1>
                <span> Insira o código de confirmação e nova senha </span>
                <Forms ref={formRef} onSubmit={handleSubmit}>
                    <div>
                        <span>Código</span>
                        <Input name="token" placeholder="Código de confirmação"
                            icon={RiMailLine}
                        />
                    </div>
                    <div>
                        <span>Senha</span>
                        <Input name="senha" placeholder="Nova senha" type="password"
                            icon={RiMailLine}
                        />
                    </div>
                    <Button style={{ fontSize: "20px" }}>Enviar</Button>
                </Forms>
                <div>
                    <a href="/">Cancelar</a>
                </div>
            </Body>
        </Container>
    );
}

export default RecoveryPassword;