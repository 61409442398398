import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { toast } from "react-toastify";

import CategoriasRow from "../../../components/CategoriasRow";
import InsumosRow from "../../../components/InsumosRow";
import ProdutosRow from "../../../components/ProdutosRow";
import api from "../../../services/api";
import ModalCreate from "./ModaisCategoria/ModalCreate";
import ModalDelete from "./ModaisCategoria/ModalDelete";
import ModalEdit from "./ModaisCategoria/ModalEdit";
import ModalCreateInsumo from "./ModaisInsumo/ModalCreate";
import ModalDeleteInsumo from "./ModaisInsumo/ModalDelete";
import ModalEditInsumo from "./ModaisInsumo/ModalEdit";
import ModalCreateProduto from "./ModaisProduto/ModalCreate";
import ModalDeleteProduto from "./ModaisProduto/ModalDelete";
import ModalEditProduto from "./ModaisProduto/ModalEdit";
import { Buscar, Container, Content, Header } from "./styles";

function ProdutosInsumos() {
  const [botao, setBotao] = useState();
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [userSelected, setUserSelected] = useState(null);

  const [categorias, setCategorias] = useState([]);
  const [buscarCategorias, setBuscarCategorias] = useState("");
  const [pageCategorias, setPageCategorias] = useState(0);
  const [totalPagesCategorias, setTotalPagesCategorias] = useState();

  const [produtos, setProdutos] = useState([]);
  const [buscarProdutos, setBuscarProdutos] = useState("");
  const [pageProdutos, setPageProdutos] = useState(0);
  const [totalPagesProdutos, setTotalPagesProdutos] = useState(0);

  const [insumos, setInsumos] = useState([]);
  const [buscarInsumos, setBuscarInsumos] = useState("");
  const [pageInsumos, setPageInsumos] = useState(0);
  const [totalPagesInsumos, setTotalPagesInsumos] = useState();

  /*==================== Funções para categorias ====================*/
  const loadingCategorias = async () => {
    try {
      const { data } = await api.get(
        `/categoria?page=${pageCategorias}&search=${buscarCategorias}`
      );
      setCategorias(data.content);
      setTotalPagesCategorias(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadingCategorias();
  }, [buscarCategorias, pageCategorias]);

  /*==================== Funções para produtos ====================*/
  const loadingProdutos = async () => {
    try {
      const { data } = await api.get(
        `/produto?page=${pageProdutos}&search=${buscarProdutos}`
      );
      setProdutos(data.content);
      setTotalPagesProdutos(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadingProdutos();
  }, [pageProdutos, buscarProdutos]);

  /*==================== Funções para insumos ====================*/
  const loadingInsumos = async () => {
    try {
      const { data } = await api.get(
        `/insumo?page=${pageInsumos}&search=${buscarInsumos}`
      );
      setInsumos(data.content);
      setTotalPagesInsumos(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadingInsumos();
  }, [buscarInsumos, pageInsumos]);

  return (
    <>
      {botao === 1 && showModalCreate && (
        <ModalCreate
          setShowModalCreate={setShowModalCreate}
          loading={loadingCategorias}
        />
      )}
      {botao === 2 && showModalCreate && (
        <ModalCreateInsumo
          setShowModalCreate={setShowModalCreate}
          loading={loadingInsumos}
        />
      )}
      {botao === 3 && showModalCreate && (
        <ModalCreateProduto
          setShowModalCreate={setShowModalCreate}
          loading={loadingProdutos}
        />
      )}
      {botao === 1 && showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          user={userSelected}
          loading={loadingCategorias}
        />
      )}
      {botao === 2 && showModalDelete && (
        <ModalDeleteInsumo
          setShowModalDelete={setShowModalDelete}
          user={userSelected}
          loading={loadingInsumos}
        />
      )}
      {botao === 3 && showModalDelete && (
        <ModalDeleteProduto
          setShowModalDelete={setShowModalDelete}
          user={userSelected}
          loading={loadingProdutos}
        />
      )}
      {botao === 1 && showModalEdit && (
        <ModalEdit
          setShowModalEdit={setShowModalEdit}
          user={userSelected}
          loading={loadingCategorias}
        />
      )}
      {botao === 2 && showModalEdit && (
        <ModalEditInsumo
          setShowModalEdit={setShowModalEdit}
          user={userSelected}
          loading={loadingInsumos}
        />
      )}
      {botao === 3 && showModalEdit && (
        <ModalEditProduto
          setShowModalEdit={setShowModalEdit}
          user={userSelected}
          loading={loadingProdutos}
        />
      )}
      <Container>
        <Header>
          <div>
            <h1>Categorias</h1>
          </div>
          <Buscar style={{ width: "30%" }}>
            <input
              style={{ marginLeft: "10px" }}
              name="buscar"
              placeholder="buscar"
              onChange={(e) => {
                setPageCategorias(0);
                setBuscarCategorias(e.target.value);
              }}
            />
            <div id="button" type="button">
              <RiSearchLine size={20} />
            </div>
          </Buscar>
          <div style={{ margin: "none" }}>
            <button
              onClick={() => {
                setShowModalCreate(!showModalCreate);
                setBotao(1);
              }}
            >
              +
            </button>
          </div>
        </Header>
        {categorias?.map((data, index) => (
          <CategoriasRow
            key={index}
            categorias={data}
            setShowModalDelete={setShowModalDelete}
            setShowModalEdit={setShowModalEdit}
            setUserSelected={setUserSelected}
            setBotao={setBotao}
          />
        ))}
        <Pagination
          count={totalPagesCategorias}
          page={pageCategorias + 1}
          onChange={(event, value) => setPageCategorias(value - 1)}
          shape="rounded"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "92px",
          }}
        />
        <Content>
          <div>
            <Header>
              <div>
                <h1>Produtos</h1>
              </div>
              <Buscar style={{ width: "40%" }}>
                <input
                  name="buscar"
                  placeholder="buscar"
                  style={{ marginLeft: "10px" }}
                  onChange={(e) => setBuscarProdutos(e.target.value)}
                />
                <div id="button" type="button">
                  <RiSearchLine size={20} />
                </div>
              </Buscar>
              <div>
                <button
                  onClick={() => {
                    setShowModalCreate(!showModalCreate);
                    setBotao(3);
                  }}
                >
                  +
                </button>
              </div>
            </Header>
            {produtos?.map((data, index) => (
              <ProdutosRow
                key={index}
                produtos={data}
                setShowModalDelete={setShowModalDelete}
                setShowModalEdit={setShowModalEdit}
                setUserSelected={setUserSelected}
                setBotao={setBotao}
              />
            ))}
            <Pagination
              count={totalPagesProdutos}
              page={pageProdutos + 1}
              onChange={(event, value) => setPageProdutos(value - 1)}
              shape="rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "92px",
              }}
            />
          </div>
          <div>
            <Header>
              <div>
                <h1>Insumos</h1>
              </div>
              <Buscar style={{ width: "40%" }}>
                <input
                  name="buscar"
                  placeholder="buscar"
                  style={{ marginLeft: "10px" }}
                  onChange={(e) => {
                    setPageInsumos(0);
                    setBuscarInsumos(e.target.value);
                  }}
                />
                <div id="button" type="button">
                  <RiSearchLine size={20} />
                </div>
              </Buscar>
              <div>
                <button
                  onClick={() => {
                    setShowModalCreate(!showModalCreate);
                    setBotao(2);
                  }}
                >
                  +
                </button>
              </div>
            </Header>
            {insumos?.map((data, index) => (
              <InsumosRow
                key={index}
                insumos={data}
                setShowModalDelete={setShowModalDelete}
                setShowModalEdit={setShowModalEdit}
                setUserSelected={setUserSelected}
                setBotao={setBotao}
              />
            ))}
            <Pagination
              count={totalPagesInsumos}
              page={pageInsumos + 1}
              onChange={(event, value) => setPageInsumos(value - 1)}
              shape="rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "92px",
              }}
            />
          </div>
        </Content>
      </Container>
    </>
  );
}

export default ProdutosInsumos;
