import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { Fragment, useContext } from 'react';

import { AuthContextBuyPlan } from '../../../../../contexts/BuyPlanContext';

export default function Review() {
  const { requestBody } = useContext(AuthContextBuyPlan)
  const paymentsRender = () => {
    let newNumber = []
    for(let i = 0; i < requestBody.card.number.length; i++){
      if(requestBody.card.number[i] === ' ')
        newNumber.push("-");
      else if(i < requestBody.card.number.length-4)
        newNumber.push("*");
      else
        newNumber.push(requestBody.card.number[i]);
    }
    newNumber = newNumber.join("");

    return [
        { name: 'Tipo de cartão', detail: requestBody.card.brand },
        { name: 'Titular do cartão', detail: requestBody.card.holderName },
        { name: 'Número do cartão', detail: newNumber },
        { name: 'Data de validade', detail: requestBody.card.expDate },
    ];
  }
  const formatPrice = (value) => {
    if (!isFinite(value)) return "---";
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };
  
  return (
    <Fragment>
      <Typography variant="h6" gutterBottom>
        Resumo do pedido
      </Typography>
      <List disablePadding>
          <ListItem key={requestBody.plan.description} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={requestBody.plan.description} secondary={""} />
            <Typography variant="body2">{formatPrice(requestBody.plan.price)}</Typography>
          </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
          {formatPrice(requestBody.plan.price)}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Endereço de cobrança
          </Typography>
          <Typography gutterBottom>{requestBody.billingAddress.line1}, {requestBody.billingAddress.number}</Typography>
          {requestBody.billingAddress.line2 && 
            <Typography gutterBottom>{requestBody.billingAddress.line2}</Typography>
          }
          <Typography gutterBottom>{requestBody.billingAddress.city} / {requestBody.billingAddress.state}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Detalhes do pagamento
          </Typography>
          <Grid container>
            {requestBody.paymentMethod != 'boleto' ? paymentsRender().map((payment) => (
              <Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </Fragment>
            )) : <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Boleto à vista
          </Typography>}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}