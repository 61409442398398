import CryptoJS from "crypto-js";
import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import api from "../services/api";
import { getId } from "../services/auth";
import Key from "../services/secret_key.json"

const AuthContextBuyPlan = createContext({});

const AuthProviderBuyPlan = ({ children, ...rest }) => {
	const [activeStep, setActiveStep] = useState(1);
  const [checkForm, setCheckForm] = useState(false);
  const [requestBody, setRequestBody] = useState({});

  const [error, setError] = useState(undefined);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [plans, setPlans] = useState(undefined);
  const [statistics, setStatistics] = useState(undefined);

  useEffect(()=>{
    if(activeStep === 2 || activeStep === 3) setCheckForm(false)
  },[activeStep])



  useEffect(()=>{
    getAllPlans()
  },[])




	const handleNext = () => {
    if(activeStep === 2 || activeStep === 3) setCheckForm(true)
    else setActiveStep(activeStep + 1);
  };

  const handleNextForm = () => {
    setCheckForm(false)
    setActiveStep(activeStep + 1);
  };

  const invalidForm = () => {
    setCheckForm(false)
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleCreateSubscription = async () => {
    setLoadingCheckout(true)
    setError(undefined)
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(requestBody), Key["key_cryptoJS"]).toString();
    try {
      await api.post(`/subscription`, { ciphertext: ciphertext  });
      // handleNext()
      setActiveStep(1)
      getAllPlans()
      
    } catch (error) {
      toast.error(error?.response?.data?.error || "Ops... Ocorreu um erro");
      // setError(error?.response?.data?.error || "Ops... Ocorreu um erro")
    }
    setLoadingCheckout(false)
  };

  const handleUpgradeSubscription = async (plan_id) => {
    setLoadingCheckout(true)
    setError(undefined)
    const sendData = {
      user_id: getId(),
      plan_id: plan_id
    }
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(sendData), Key["key_cryptoJS"]).toString();
    try {
      await api.post(`/subscription/changeSubscription`, { ciphertext: ciphertext  });
      // handleNext()
      setActiveStep(1)
      getAllPlans()
      
    } catch (error) {
      toast.error(error?.response?.data?.error || "Ops... Ocorreu um erro");
      // setError(error?.response?.data?.error || "Ops... Ocorreu um erro")
    }
    setLoadingCheckout(false)
  };


  //Admin alterar plano do usuario.
  const handleAdminUpgradeSubscription = async (plan_id, user_id) => {
    setLoadingCheckout(true)
    setError(undefined)
    const sendData = {
      user_id,
      plan_id
    }
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(sendData), Key["key_cryptoJS"]).toString();
    try {
      await api.post(`/subscription/changeSubscription`, { ciphertext: ciphertext  });
      // handleNext()
      setActiveStep(1)
      getAllPlans()
      
    } catch (error) {
      toast.error(error?.response?.data?.error || "Ops... Ocorreu um erro");
      // setError(error?.response?.data?.error || "Ops... Ocorreu um erro")
    }
    setLoadingCheckout(false)
  };

  const handleCancelSubscription = async () => {
    setLoadingCheckout(true)
    setError(undefined)
    try {
      const resp = await api.post(`/subscription/${getId()}/cancelSubscription`);
      setStatistics(resp.data)
      
    } catch (error) {
      toast.error(error?.response?.data?.error || "Ops... Ocorreu um erro");
      // setError(error?.response?.data?.error || "Ops... Ocorreu um erro")
    }
    setTimeout(() => {
      setLoadingCheckout(false)
    }, 1000);
  };

  const checkSubscriptionActive = async () => {
    try {
      const resp = await api.get(`/subscription/${getId()}/checkSubscriptionActive`);
      return resp.data
    } catch (error) {
      // toast.error(error?.response?.data?.error || "Ops... Ocorreu um erro");
      if(error?.response?.data) return error?.response?.data
      else return { error: "Ops... Ocorreu um erro" }
    }
  };

  const getAllPlans = async () => {
    setLoadingCheckout(true)
   
    try {
      const resp = await api.get(`/planos/${getId()}/userPlans`);
      setStatistics(resp.data.statistics)
      const plansFiltered = resp.data.plans.filter(item => item.id < 4) //filtro utilizado para retirar os planos free das telas do app
      const newPlans = plansFiltered.map(item => {
        const list = JSON.parse(item.list)
        return {
          ...item,
          list: list
        }
      })
      setPlans(newPlans)
      setError(undefined)
    } catch (error) {
      setError(error?.response?.data?.error || "Ops... Ocorreu um erro")
    }
    setTimeout(() => {
      setLoadingCheckout(false)
    }, 1000);
  };

  const handleFreePlan = (plan_id) => {
    setRequestBody({})
    setRequestBody({
      user_id: getId(),
      plan_id: plan_id
    })
  };

  useEffect(()=>{
    //free
    if(requestBody && requestBody.plan_id === 1) handleCreateSubscription()
  },[requestBody])

  const handleFillRequestBody = (data) => {
    switch (activeStep) {
      case 1:
        setRequestBody((oldData)=>{
          let newData = {
            user_id: getId(),
            plan_id: data.plan_id,
            plan: {
              description: data.description,
              price: data.price
            }
          }
          return newData;
        })
        break;
      case 2:
      
        setRequestBody((oldData)=>{
          let newData = {
            ...oldData,
            billingAddress: { ...data }
          }
          return newData; 
        })
        break;
      case 3:
        if(data.paymentMethod == "boleto") {
          setRequestBody((oldData) => {
            let newData = {
              ...oldData,
              paymentMethod: 'boleto',

            }
            return newData;
          }) 
        } else {
        setRequestBody((oldData)=>{
         
          let newData = {
            ...oldData,
            paymentMethod: 'credit_card',
            card: { 
              ...data,
              expMonth: parseInt(data.expDate.replace(/[^0-9]/g, '').substring(0, 2)),
              expYear: parseInt(data.expDate.replace(/[^0-9]/g, '').substring(2, 4)),
            }
          }
          return newData;
        })}
        break;
      case 4:
        break;
      default:
        break;
    }
  };

  return (
    <AuthContextBuyPlan.Provider
      value={{
				activeStep,
        checkForm,
        requestBody,
        statistics,
        plans,
        error,
        loadingCheckout,
        invalidForm,
				handleNext,
        handleNextForm,
				handleBack,
        handleFillRequestBody,
        handleCreateSubscription,
        checkSubscriptionActive,
        handleFreePlan,
        handleUpgradeSubscription,
        handleAdminUpgradeSubscription,
        handleCancelSubscription
      }}
    >
      {children}
    </AuthContextBuyPlan.Provider>
  );
};

export { AuthContextBuyPlan, AuthProviderBuyPlan };