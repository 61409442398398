
import React from "react";
import { AuthProviderBuyPlan } from "../../../contexts/BuyPlanContext";
import Checkout from "./pages/Checkout";

function BuyPlan() {
console.log('Index');

  return (
    <AuthProviderBuyPlan>
      <Checkout/>
    </AuthProviderBuyPlan>
  );
}

export default BuyPlan;
