import React from 'react';
import { RiArrowRightSLine } from "react-icons/ri";

import { Container, Buttons } from './styles';

const CidadePerfil = ({ cidade, setShowCidade, setCidadeSelecionada }) => {

    return (
        <>
            <Container>
                <header>
                    <p id='p'>{cidade.nome}</p>
                </header>
                <Buttons>
                    <button style={{ color: "#20655F" }}
                        onClick={() => {setShowCidade(false); setCidadeSelecionada(cidade.nome)}}>
                        <RiArrowRightSLine size={30} />
                    </button>
                </Buttons>
            </Container>
        </>
    )
}

export default CidadePerfil;
