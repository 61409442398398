import { parse, isValid as isValidDate, format, isAfter as isAfterDate } from 'date-fns';
import moment from "moment"

const INVALID_CREDICARD_NUMBER_ERROR_MESSAGE = "Número de cartão inválido!";

export function isValidCredicardNumber(message) {
	return this.test("isValidCredicardNumber", message, function (value) {
		const { path, createError } = this;
		const { formatted, isValid, brand} = formatCredicardNumber(value);

		if(value.length === 0) {
			return createError({ path, message: message ?? "Número do cartão é obrigatório!" });
		}
		if(isValid === false) {
			return createError({ path, message: message ?? INVALID_CREDICARD_NUMBER_ERROR_MESSAGE });
		}
		if ((brand === 'Visa' || brand === 'Master Card' || brand === 'Discover') && formatted.replace(/\D/g, '').length !== 16 ) {
			return createError({ path, message: message ?? INVALID_CREDICARD_NUMBER_ERROR_MESSAGE });
		}
		if (brand === 'American Express' && formatted.replace(/\D/g, '').length !== 15 ) {
			return createError({ path, message: message ?? INVALID_CREDICARD_NUMBER_ERROR_MESSAGE });
		}

		return true;
	});
}


export function isValidCredicardExpDate(message) {
	return this.test("isValidCredicardExpDate", message, function (value) {
		const { path, createError } = this;
		const { formatted, isValid } = formatExpDate(value);
		if(value.length === 0) {
			return createError({ path, message: message ?? "Data de validade é obrigatório!" });
		}

		if(isValid === false) {
			return createError({ path, message: message ?? "Data de validade inválida!" });
		}

		return true;
	});
}

export function formatExpDate(data) {
	var numString=data.replace(/[^0-9]/g, '');
	var block1 = numString.substring(0, 2);
	var block2 = numString.substring(2, 4);
	
	if(block2.length >= 1 ){
		block1 = block1 + '/'
	}

	var formatted = block1 + block2

	const date = moment(`20${block2}-${block1.substring(0, 2)}-01 00:00:00`, 'YYYY-MM-DD HH:mm:ss')
	const today = moment(`${moment().year()}-${moment().month() + 1}-01 00:00:00`, 'YYYY-MM-DD HH:mm:ss')
	var isValid = true;

	if(date.isSame(today)){
		isValid=true
	} else {
		isValid = date.isValid() && date.isAfter(today)
	}
	

	return {
			formatted,
			isValid,
	}
}

export function formatCredicardNumber(number) {
	// supports Amex, Master Card, Visa, and Discover
	// parameter 1 ccid= id of credit card number field
	var isValid = true;
	var ccNumString=number;
	ccNumString=ccNumString.replace(/[^0-9]/g, '');
	// Master Card, starts with - 51 to 55
	// Visa, starts with - 4
	// Discover, starts with 6011, 622126-622925, 644-649, 65
	// American Express, starts with 34 or 37
	var typeCheck = ccNumString.substring(0, 2);
	var cType='';
	var block1='';
	var block2='';
	var block3='';
	var block4='';
	var formatted='';

	if  (typeCheck.length==2) {
			typeCheck=parseInt(typeCheck);
			if (typeCheck >= 40 && typeCheck <= 49) {
					cType='Visa';
			}
			else if (typeCheck >= 51 && typeCheck <= 55) {
					cType='Master Card';
			}
			else if ((typeCheck >= 60 && typeCheck <= 62) || (typeCheck == 64) || (typeCheck == 65)) {
					cType='Discover';
			}
			else if (typeCheck==34 || typeCheck==37) {
					cType='American Express';
			}
			else {
					cType='Invalid';
			}
	}

	// all support card types have a 4 digit firt block
	block1 = ccNumString.substring(0, 4);

	if (cType == 'Visa' || cType == 'Master Card' || cType == 'Discover') {
			// for 4X4 cards
			block2 = ccNumString.substring(4, 8);
			block3 = ccNumString.substring(8, 12);
			block4 = ccNumString.substring(12, 16);
			if (block2.length >= 1) {
				block1=block1 + ' ';
			}
			if (block3.length >= 1) {
					block2=block2 + ' ';
			}
			if (block4.length >= 1) {
					block3=block3 + ' ';
			}
	}
	else if (cType == 'American Express') {
			// for Amex cards
			// 4x6x5
			block2 =  ccNumString.substring(4, 10);
			block3 =  ccNumString.substring(10, 15);
			if (block2.length >= 1) {
				block1=block1 + ' ';
			}
			if (block3.length >= 1) {
					block2=block2 + ' ';
			}
			block4='';
	}
	else if (cType == 'Invalid') {
			// for Amex cards
			block1 = ccNumString;
			block2='';
			block3='';
			block4='';
			isValid = false;
	}

	formatted=block1 + block2 + block3 + block4;

	return {
			formatted,
			isValid,
			brand: cType
	}
}


export const estados = [
	'AC',
	'AL',
	'AP',
	'AM',
	'BA',
	'CE',
	'DF',
	'ES',
	'GO',
	'MA',
	'MS',
	'MT',
	'MG',
	'PA',
	'PB',
	'PR',
	'PE',
	'PI',
	'RJ',
	'RN',
	'RS',
	'RO',
	'RR',
	'SC',
	'SP',
	'SE',
	'TO',
]
