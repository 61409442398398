/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-dupe-keys */
export default {
  primary: "#20655F",
  secondary: "#F8AA27",
  background: "#EFF1F9",
  darkCyan: "#0C9DA4",
  darkCyan2: "#22A6AC",
  dark: "#3F4254",
  darkGrayishBlue: "#626679",
  purple: "#7F63F4",
  lightGray: "#F3F6F9",
  white: "#FFFFFF",
  blue: "#03014C",
  gray: "#6A707E",
  extraLightGray: "#ABAFB3",
  darKGray: "#464A53",
  gelo: "#f3f3f3",
  extraLight: "#aaaaaa",
  orange: "#FFA500",
  placeHolder: "#77838F",
  green: "#20655f",
  orange: "#F8AA27", 
  darkOrange: "#cf8f23", 
  error: "#ff4f4f"
};