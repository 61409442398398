import React, { createContext } from "react";
import { toast } from "react-toastify";
import api from "../services/api";

const AuthContextCidade = createContext({});

const AuthProviderCidade = ({ children }) => {

  /* Função para carregar cidades */
  const loadingCidade = async () => {
    try {
      const { data } = await api.get(`/cidade`);
      return data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* Função para delatar cidade */
  const deleteCidade = async (id) => {
    try {
      const { data } = await api.delete(`/cidade/${id}`);
      toast.success(data.message);
      return data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
      return false;
    }
  };

  /* Função para atualizar cidade */
  const updateCidade = async (data, id) => {
    try {
      const resp = await api.put(`/cidade/${id}`, data);
      resp?.data?.id ?
        toast.error(resp?.data?.message) :
        toast.success("Atualizado com sucesso!");
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
      return false;
    }
  };

  /* Função para criar uma nova cidade */
  const createCidade = async (data) => {
    try {
      const resp = await api.post(`/cidade`, data);
      !resp?.data?.id ?
        toast.error(resp?.data?.message) :
        toast.success("Cadastrado com sucesso!");
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
      return false;
    }
  };

  return (
    <AuthContextCidade.Provider
      value={{
        deleteCidade,
        loadingCidade,
        updateCidade,
        createCidade,
      }}
    >
      {children}
    </AuthContextCidade.Provider>
  );
};

export { AuthContextCidade, AuthProviderCidade };