import React, { useContext, useEffect, useState } from "react";
import deleteUser from "../../../../../assets/deleteUser.png";

import { Container, Image, Content, Body, ButtonSecudary } from "./styles";
import { AuthContextFornecedorProduto } from "../../../../../contexts/FornecedorProdutosContext";
import { AuthContextFornecedorInsumo } from "../../../../../contexts/FornecedorInsumosContext";
import { getId } from "../../../../../services/auth";
import Button from "../../../../../components/Button";

function ModalDelete({ setShowModalDelete, produto, setFornecedorProdutos, setFornecedorInsumos }) {

  const [insumo, setInsumo] = useState(false);

  const { deleteFornecedorProduto, loadingFornecedorProdutos } = useContext(AuthContextFornecedorProduto);
  const { deleteFornecedorInsumo, loadingFornecedorInsumos } = useContext(AuthContextFornecedorInsumo);

  useEffect(() => {
    if (produto.fornecedor_insumos) {
      setInsumo(true)
    }
  });

  return (
    <Container>
      <Body>
        <Image src={deleteUser} />
        <Content>
          <h1>Você realmente deseja excluir o {insumo === true ? 'Insumo' : 'Produto'}?</h1>
          <div>
            <ButtonSecudary
              onClick={async () => {
                const userId = getId();

                if (insumo === true) {
                  await deleteFornecedorInsumo(produto.id, userId);
                  setShowModalDelete(false);
                  loadingFornecedorInsumos(setFornecedorInsumos);
                } else {
                  await deleteFornecedorProduto(produto.id, userId);
                  setShowModalDelete(false);
                  loadingFornecedorProdutos(setFornecedorProdutos);
                }
                window.location.reload();
              }}
            >
              CONFIRMAR
            </ButtonSecudary>
            <Button
              style={{ width: "30%", fontSize: '18px' }}
              onClick={() => setShowModalDelete(false)}
            >
              Não
            </Button>
          </div>
        </Content>
      </Body>
    </Container>
  );
}

export default ModalDelete;