import styled from "styled-components";
import colors from "../../../../../styles/colors";
import fonts from "../../../../../styles/fonts";
import imageLogo from "../../../../../assets/logo2.png";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgb(12, 157, 164, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: 600px;
  height: 600px;
  border-radius: 30px;
  /* padding-bottom: 50px; */
  /* background-color: yellow; */
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    /* background-color: ${colors.darkGrayishBlue}; */
  }
  h1 {
    ${fonts[700]};
    color: ${colors.dark};
    margin-top: 40px;
  }
  a {
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    ${fonts[700]};
    color: ${colors.dark};
    /* background-color: blue; */
    :hover {
      color: ${colors.secondary};
    }
  }
  > p {
    ${fonts[400]};
    color: ${colors.darkGrayishBlue};
    margin-bottom: 10px;
  }
  > #button {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  
  overflow: auto;
  -ms-overflow-style: none;
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 30px !important;
    margin-right: 70px !important;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    border-radius: 30px !important;
    margin-right: 30px;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 30px !important;
    margin-right: 30px !important;
  }
`;

export const Image = styled.div`
  width: 60px;
  height: 67px;
  margin-top: 30px;
  /* background-color: #7F63F4; */
  background-image: url(${imageLogo});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  /* display: flex; */
  /* flex-direction: row; */
    /* background-color: yellow; */
    width: 100%;
    align-items: center;
    justify-content: center;
    /* background-color: yellow; */
`;

export const Buscar = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.lightGray};
  width: 50%;
  height: 50px;
  margin-bottom: 5px;
  border-radius: 4px;
  /* background-color: red; */
  input {
    width: 100%;
    height: 100%;
    color: ${colors.darkGrayishBlue};
    background-color: transparent;
    font-size: 18px;
    outline: none;
    /* background-color: blue; */
  }
  #botao {
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: green; */
    cursor: pointer;
    :hover {
      color: ${colors.purple};
    }
  }
  svg {
    color: ${colors.darkGrayishBlue};
    :hover {
      color: #000;
    }
  }
`;