import React from 'react';
import { RiArrowRightSLine } from "react-icons/ri";

import { Container, Buttons } from './styles';

const RegiaoRow = ({ regiao, setRegiaoSelected, setShowModalRegiao }) => {

    return (
        <>
            <Container>
                <header>
                    <p id='p'>{regiao.nome}</p>
                </header>
                <Buttons>
                    <button style={{ color: "#20655F" }}
                        onClick={() => { setShowModalRegiao(false); setRegiaoSelected(regiao.nome) }}>
                        <RiArrowRightSLine size={30} />
                    </button>
                </Buttons>
            </Container>
        </>
    )
}

export default RegiaoRow;
