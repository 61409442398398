import React from "react";
import { Route, Redirect } from "react-router-dom";
import {
  RiTeamLine,
  RiProfileFill,
  RiDashboardLine,
  RiUserSharedLine,
  RiSurveyLine,
  RiInboxArchiveLine,
  RiBriefcase2Line,
  RiRoadMapLine,
} from "react-icons/ri";
import SignIn from "../views/pages/SignIn";
import SignInUser from "../views/pages/SignInUser";
import SignUp from "../views/pages/SignUp";
import Recover from "../views/pages/Recover";
import RecoveryPassword from "../views/pages/RecoveryPassword";
import { getToken } from "../services/auth";
import ListagemUser from "../views/pages/ListUsers";
import ListagemFornecedor from "../views/pages/ListFornecedores";
import Perfil from "../views/pages/ListFornecedores/Perfil";
import Enviados from "../views/pages/Enviados";
import EnviadosAdmin from "../views/pages/EnviadosAdmin";
import Solicitacoes from "../views/pages/Solicitacoes";
import SolicitacoesAdmin from "../views/pages/SolicitacoesAdmin";
import ProdutosInsumos from "../views/pages/ProdutosInsumos";
import ProdutosQueTrabalha from "../views/pages/ListFornecedores/ProdutosQueTrabalha";
import RegiaoCidades from "../views/pages/RegiaoCidades";
import BuyPlan from "../views/pages/BuyPlan";
import GraficosAdm from "../views/pages/Dashboard";
import GraficosFornecedor from "../views/pages/DashboardFornecedor";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...{ rest }}
      render={
        (props) =>
          getToken() != null ? (
            <Component {...{ props }} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  );
};

export const routes = {
  plan: [
    {
      path: "/dashboard/BuyPlan",
      title: "Plano",
      role: [55],
      icon: RiDashboardLine,
      component: () => <BuyPlan />,
    },
  ],
  protected: [
    {
      path: "/dashboard",
      title: "Dashboard",
      role: [999],
      icon: RiDashboardLine,
      component: () => <GraficosAdm />,
    },
    {
      path: "/dashboard/GraficosFornecedor",
      title: "Dashboard",
      role: [1],
      icon: RiDashboardLine,
      component: () => <GraficosFornecedor />,
    },
    
    {
      path: "/dashboard/users",
      title: "Usuários",
      role: [999],
      icon: RiTeamLine,
      component: () => <ListagemUser />,
    },
    {
      path: "/dashboard/fornecedor",
      title: "Fornecedores",
      role: [999],
      icon: RiUserSharedLine,
      component: () => <ListagemFornecedor />,
    },
    {
      path: "/dashboard/enviados",
      title: "Enviados",
      role: [1],
      icon: RiSurveyLine,
      component: () => <Enviados />,
    },
    {
      path: "/dashboard/enviadosAdmin",
      title: "Enviados Admin",
      role: [999],
      icon: RiSurveyLine,
      component: () => <EnviadosAdmin />,
    },
    {
      path: "/dashboard/solicitacoes",
      title: "Solicitações",
      role: [1],
      icon: RiBriefcase2Line,
      component: () => <Solicitacoes />,
    },
    {
      path: "/dashboard/solicitacoesAdmin",
      title: "Solicitações Admin",
      role: [999],
      icon: RiBriefcase2Line,
      component: () => <SolicitacoesAdmin />,
    },
    {
      path: "/dashboard/produtosInsumos",
      title: "Produtos e Insumos",
      role: [999],
      icon: RiInboxArchiveLine,
      component: () => <ProdutosInsumos />,
    },
    {
      path: "/dashboard/produtosQueTrabalha",
      title: "Produtos que Trabalha",
      role: [1],
      icon: RiInboxArchiveLine,
      component: () => <ProdutosQueTrabalha />,
    },
    {
      path: "/dashboard/regiaoCidades",
      title: "Regiões e Cidades",
      role: [999],
      icon: RiRoadMapLine,
      component: () => <RegiaoCidades />,
    },
    {
      path: "/dashboard/profile",
      title: "Perfil",
      role: [999, 1],
      icon: RiProfileFill,
      component: () => <Perfil />,
    },
  ],
  public: [
    {
      path: "/",
      component: () => <SignIn />,
    },
    {
      path: "/signInUser",
      component: () => <SignInUser />,
    },
    {
      path: "/register",
      component: () => <SignUp />,
    },
    {
      path: "/recover",
      component: () => <Recover />,
    },
    {
      path: "/recoveryPassword",
      component: () => <RecoveryPassword />,
    },
  ],
};