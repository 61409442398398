import styled from "styled-components";
import colors from "../../../../../styles/colors";
import fonts from "../../../../../styles/fonts";
import { darken, lighten, transparentize } from 'polished';

export const PlanosContainer = styled.div`
	height: ${({ renderCancelButton, renderTextButton}) => (220 + (renderTextButton ? renderCancelButton ?  50 : 0: -40)) }px;
	border-radius: 10px;
	margin-bottom: 30;
	box-shadow: 3px 3px 10px grey;

	@media(max-width: 728px) {
		// height: 220px;
		height: ${({ renderCancelButton, renderTextButton}) => (220 + (renderTextButton ? renderCancelButton ?  50 : 0: -40)) }px;
  	}

	@media(max-width: 600px) {
		// height: 190px;
		height: ${({ renderCancelButton, renderTextButton}) => (220 + (renderTextButton ? renderCancelButton ?  50 : 0: -40)) }px;
  	}

	@media(max-width: 234px) {
		// height: 230px;
		height: ${({ renderCancelButton, renderTextButton}) => (230 + (renderTextButton ? renderCancelButton ?  50 : 0: -40)) }px;
  	}
`

export const PlanosHeader = styled.div`
	display: flex;
	height: 41px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	justify-content: center;
	align-items: center;
	// background-color: ${({isBlack}) => isBlack ? 'black' : colors.green}; 
	background-color: ${({backgroundColor}) => backgroundColor}; 
	text-align: center;
	span {
			color: ${colors.white};
			${fonts[700]};
	}
`

export const PlanosBody = styled.div`
	display: flex;
	flex-direction: column;
	// justify-content: center;
	// align-self: center;
	// align-items: center;
	margin-top: 15px;
	padding: 10;
`

export const DivRow = styled.div`
	display: flex;
	// align-self: stretch;
	// flex-direction: row;
	// align-self: center;
	// justify-content: center;
	align-items: center;

	gap: 5px;
`

export const DivIcon = styled.div`
	flex: 0.1;
	margin-right: 14;
`

export const DivText = styled.div`
	flex: 1;
	span {
		${fonts[400]};
}
`

export const Button = styled.button`
	flex: 1;
	color: ${colors.white};
	background-color: ${colors.orange};
	font-weight: bold;
  	cursor: pointer;
  	transition: all 0.2s;
  	//display: flex;
	border-radius: 5rem;
	border: 1px solid ${colors.orange};
	padding: 0.5rem 1rem;
	align-self: center;
	margin-top: 15px;

	&:hover {
		background: ${colors.darkOrange};
		border-color: ${colors.darkOrange};
	}

	&:disabled,
	&[disabled] {
		cursor: not-allowed;
		background: ${darken(0.2, colors.orange)};
		border-color: ${darken(0.2, colors.orange)};
	}

`
