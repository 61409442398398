import { Form } from "@unform/web";
import styled from "styled-components";
import colors from "../../../../styles/colors";
import fonts from "../../../../styles/fonts";
import imageLogo from "../../../../assets/logo2.png";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgb(12, 157, 164, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: 1000px;
  height: 90%;
  border-radius: 30px;
  padding-bottom: 30px;
  #header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    /* background-color: ${colors.darkGrayishBlue}; */
  }
  h1 {
    ${fonts[700]};
    color: ${colors.dark};
    margin-top: 30px;
    /* background-color: yellow; */
  }
  a {
    text-decoration: none;
    margin-top: 20px;
    font-size: 18px;
    ${fonts[700]};
    color: ${colors.dark};
    :hover {
      color: ${colors.secondary};
    }
  }
  overflow: auto;
  -ms-overflow-style: none;
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 30px !important;
    margin-right: 70px !important;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    border-radius: 30px !important;
    margin-right: 30px;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 30px !important;
    margin-right: 30px !important;
  }
`;

export const SubHeader = styled.div`
    width: 80%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
    /* background-color: ${colors.darkGrayishBlue}; */
    #cards {
      display: flex;
      width: 40%;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 8px;
      box-shadow: 0px 5px 6px rgba(0,0,0,0.2);
      /* background-color: ${colors.gelo}; */
      p {
        font-size: 18px;
        ${fonts[700]};
        color: ${colors.dark};
      }
    }
`;

export const Body = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: ${colors.darkGrayishBlue}; */
    #contentBody {
      width: 100%;
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
    /* background-color: ${colors.lightGray}; */

    }
`;

export const ContainerCard = styled.div`
    width: 40%;
    height: 70px;
    display: flex;
    margin: 15px;
    border-radius: 8px;
    box-shadow: 0px 5px 6px rgba(0,0,0,0.2);
    /* background-color: ${colors.darkGrayishBlue}; */
    #nome {
      display: flex;
      width: 70%;
      height: 100%;
      align-items: center;
      justify-content: center;
      /* background-color: ${colors.gelo}; */
      p {
        font-size: 18px;
        ${fonts[700]};
        color: ${colors.dark};
      }
    }
    #valor {
      display: flex;
      width: 30%;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      /* background-color: ${colors.gelo}; */
      p {
        font-size: 18px;
        ${fonts[700]};
        color: ${colors.dark};
      }
    }
    #analise {
      display: flex;
      width: 30%;
      height: 100%;
      align-items: center;
      justify-content: center;
      /* background-color: ${colors.gelo}; */
      p {
        font-size: 18px;
        ${fonts[700]};
        color: ${colors.dark};
      }
    }
`;

export const Column = styled.div`
  > div {
    width: 300px;
    height: 76px;
    margin-bottom: 26px;
    padding-left: 36px;  
    border-radius: 8px;
    /* border: 1px solid black; */
    /* background-color: ${({ backgroundColor }) => backgroundColor ?? '#FFF'}; */
    box-shadow: 0px 5px 6px rgba(0,0,0,0.2);
     span {
      ${fonts[400]};
      color: ${colors.dark};
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      /* background-color: blue; */
    }
    svg {
      color: ${colors.darkGrayishBlue}
    }
    /* background-color: yellow;    */
  } 
`;

export const Image = styled.div`
  width: 70px;
  height: 77px;
  margin-top: 30px;
  /* background-color: #7F63F4; */
  background-image: url(${imageLogo});
  background-size: contain;
  background-repeat: no-repeat;
`;