import React, { useContext } from "react";
import deleteUser from "../../../../../assets/deleteUser.png";
import Button from "../../../../../components/Button";
import { AuthContextCidade } from "../../../../../contexts/CidadeContext";

import { Container, Image, Content, Body, ButtonSecondary } from "./styles";

function ModalDeleteCidade({ setShowModalDelete, user, loading }) {

  const { deleteCidade } = useContext(AuthContextCidade);

  return (
    <Container>
      <Body>
        <Image src={deleteUser} />
        <Content>
          <h1>Você realmente deseja excluir a Cidade?</h1>
          <div>
            <ButtonSecondary
              onClick={
                async () => {
                  await deleteCidade(user?.id);
                  loading();
                  setShowModalDelete(false);
                }}
            >
              CONFIRMAR
            </ButtonSecondary>
            <Button
              style={{ width: "30%", fontSize: '18px' }}
              onClick={() => setShowModalDelete(false)}
            >
              Não
            </Button>
          </div>
        </Content>
      </Body>
    </Container>
  );
}

export default ModalDeleteCidade;