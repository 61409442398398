import Pagination from '@mui/material/Pagination';
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";

import SolicitacoesRow from "../../../components/SolicitacoesRow";
import api from "../../../services/api";
import { getId } from "../../../services/auth";
import ModalDelete from "./ModalDelete";
import ModalEnvioOrcamento from "./ModalEnvioOrcamento";
import { Buscar, Container, Content, Filtro, Header } from "./styles";

function Solicitacoes() {

    const [botao, setBotao] = useState();
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalEnvioOrcamento, setShowModalEnvioOrcamento] = useState(false);
    const [userSelected, setUserSelected] = useState(null);
    const [solicitacoes, setSolicitacoes] = useState([]);
    
    const [buscar, setBuscar] = useState('');
    const [paginas, setPaginas] = useState(0);
    const [page, setPage] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const loadingSolicitacoes = async () => {
        try {
            const id = getId();
            const { data } = await api.get(`/fornecedor/${id}/orcamento?page=${page}&search=${buscar}`);
            setSolicitacoes(data.content);
            setTotalRegistros(data.totalRegistros)
            setPaginas(data.totalPages)
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadingSolicitacoes();
    }, [buscar, page]);

    // const solicitacoesPaginado = solicitacoes?.slice(paginas * 10, paginas * 10 + 10);
    // const totalPages = Math.ceil(solicitacoes?.length / 10);

    const handleBuscar = () => {
        if (buscar) {
            const busca = solicitacoes?.filter(item => item.nome_comercial.toLowerCase().includes(buscar.toLowerCase()));
            setSolicitacoes([...busca]);
        }
    };

    // const handleChange = (event, value) => {
    //     let valorPage = value - 1;
    //     setPage(valorPage);
    // };

    return (
        <>
            {showModalDelete && (
                <ModalDelete
                    setShowModalDelete={setShowModalDelete}
                    user={userSelected}
                    loading={loadingSolicitacoes}
                />
            )}
            {showModalEnvioOrcamento && (
                <ModalEnvioOrcamento
                    setShowModalEnvioOrcamento={setShowModalEnvioOrcamento}
                    user={userSelected}
                    loading={loadingSolicitacoes}
                />
            )}
            <Container>
                <Content>
                    <Header>
                        <div>
                            <h1>Solicitações</h1>
                        </div>
                        <Buscar style={{ width: '30%' }}>
                            <input style={{ marginLeft: '10px' }} name="buscar" placeholder="buscar" onChange={(e) => setBuscar(e.target.value)} />
                            <div id='button' type="button" onClick={() => handleBuscar()}>
                                <RiSearchLine size={20} />
                            </div>
                        </Buscar>
                    </Header>
                    <div>
                        <div>
                            <p>Mais de {totalRegistros} novos orçamentos</p>
                        </div>
                        {/* <Filtro>
                            <div id="p" type="button" onClick={() => setBotao(1)}
                                style={{
                                    backgroundColor: botao === 1 ? "#20655F" : "#EFF1F9",
                                    color: botao === 1 ? "#FFF" : "#626679"
                                }}>
                                Mês
                            </div>
                            {"|"}
                            <div id="p" type="button" onClick={() => setBotao(2)}
                                style={{
                                    backgroundColor: botao === 2 ? "#20655F" : "#EFF1F9",
                                    color: botao === 2 ? "#FFF" : "#626679"
                                }}>
                                Semana
                            </div>
                            {"|"}
                            <div id="p" type="button" onClick={() => setBotao(3)}
                                style={{
                                    backgroundColor: botao === 3 ? "#20655F" : "#EFF1F9",
                                    color: botao === 3 ? "#FFF" : "#626679"
                                }}>
                                Dia
                            </div>
                        </Filtro> */}
                    </div>

                </Content>
                {solicitacoes?.map((data, index) => (
                    <SolicitacoesRow
                        key={index}
                        solicitacoes={data}
                        setShowModalDelete={setShowModalDelete}
                        setShowModalEnvioOrcamento={setShowModalEnvioOrcamento}
                        setUserSelected={setUserSelected}
                        paginas={paginas}
                    />
                ))}
                <Pagination
                    count={paginas}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)}
                    shape="rounded"
                    style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '10px'
                    }}
                />
            </Container>
        </>
    );
}

export default Solicitacoes;