import React, { useRef, useContext, useState, useEffect } from "react";
import * as Yup from "yup";
import { AuthContextSolicitacoes } from "../../../../contexts/SolicitacoesContext";
import getValidationErrors from "../../../../utils/getValidationsErrors";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import {
    RiHandCoinLine,
    RiHotelLine,
    RiPriceTag2Line,
    RiDraftLine,
    RiStackLine,
    RiWallet3Line,
    RiHandHeartLine,
    RiRoadMapLine,
    RiCommunityLine,
    RiMoneyDollarBoxLine,
    RiBubbleChartLine,
} from "react-icons/ri";
import { useLocation } from "react-router-dom";
import api from "../../../../services/api";
import colors from "../../../../styles/colors";
import Select from "react-select";

import { Container, Forms, Body, Content, Column, Image, Buttons } from "./styles";

function ModalEnvioOrcamento({ setShowModalEnvioOrcamento, user, loading }) {
    const formRef = useRef(null);
    const { updateSolicitacoes } = useContext(AuthContextSolicitacoes);
    const location = useLocation();

    const [disabledInput, setDisabledInput] = useState(false);
    const [regiao, setRegiao] = useState([]);
    const [checked, setChecked] = useState(false);

    const [insumos, setInsumos] = useState([]);
    const [selectedInsumos, setSelectedInsumos] = useState([]);

    const valorOrcamento = user.fk_fornecedorOrcamentos;
    const valor = valorOrcamento[0]?.valor_orcamento;
    // const valor = valorOrcamento.map((item) => {
    //     return item.valor_orcamento
    // });
    const observacao = valorOrcamento.map((item) => {
        return item.observacao
    });
    const naoPossui = valorOrcamento.map((item) => {
        return item.naoPossui
    });
    const fornecedorOrcamentos_id = valorOrcamento.map((item) => {
        return item.id
    });
    const culturaUser = user.fk_user?.cultura;

    const loadingRegiaoNome = async () => {
        try {
            let regiao = user.regiao
            const { data } = await api.post(`/regiao_nome`, { nome: regiao });
            setRegiao(data);
        } catch (err) {
            console.log(err);
        }
    };

    const loadingInsumos = async () => {
        try {
            const { data } = await api.get(`/insumo_nome`);
            setInsumos(data?.map(item => ({
                value: item.id, label: item.nome
            })));
        } catch (err) {
            console.log(err);
        }
    };

    const valorInicialSelect = () => {
        valorOrcamento?.map((data) => {
            if (data.fornecedor_insumo != null) {
                setSelectedInsumos({
                    value: data.fornecedor_id, label: data.fornecedor_insumo
                })
            }
        })
    };

    useEffect(() => {
        if (location.pathname === '/dashboard/enviadosAdmin') {
            setDisabledInput(true)
        }
        loadingRegiaoNome();
        loadingInsumos();
        valorInicialSelect();
    }, []);

    useEffect(() => {
        naoPossui == 0 ? setChecked(true) : setChecked(false)
    }, []);

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                nome_comercial: Yup.string()
                    .required("Nome obrigatório!"),
                marca: Yup.string()
                    .required("Marca obrigatória!"),
                quantidade: Yup.string()
                    .required("Quantidade obrigatória!"),
                forma_pagamento: Yup.string()
                    .required("Forma de pagamento obrigatória!"),
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});

            await updateSolicitacoes(data, user?.id, fornecedorOrcamentos_id, checked, selectedInsumos, valor);
            loading();
            setShowModalEnvioOrcamento(false);

        } catch (err) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Envio de Orçamento</h1>
                    <Image />
                </div>
                <p>Confira os dados abaixo</p>
                <Forms ref={formRef} onSubmit={handleSubmit}
                    initialData={{
                        ...user,
                        valor: valor,
                        observacao: observacao,
                        cultura: culturaUser,
                        principio_ativo: user.fk_insumo?.principio_ativo
                    }}>
                    <Content>
                        <Column>
                            <div>
                                <span>Produto</span>
                                <Input
                                    name="nome_comercial"
                                    placeholder="Produto"
                                    icon={RiHandCoinLine}
                                    disabled={true}
                                />
                            </div>
                            <div>
                                <span>Nome Comercial</span>
                                <Input
                                    name="nome_comercial"
                                    placeholder="Nome Comercial"
                                    icon={RiHotelLine}
                                    disabled={true}
                                />
                            </div>
                            <div>
                                <span>Marca</span>
                                <Input
                                    name="marca"
                                    placeholder="Marca"
                                    icon={RiPriceTag2Line}
                                    disabled={true}
                                />
                            </div>
                            {user.fk_produto == null &&
                                <div>
                                    <span>Princípio Ativo</span>
                                    <Input
                                        name="principio_ativo"
                                        placeholder="Princípio Ativo"
                                        icon={RiBubbleChartLine}
                                        disabled={true}
                                    />
                                </div>}
                            <div style={{ width: '86%', paddingRight: '10px' }}>
                                <span>Insumo do Fornecedor</span>
                                <Select
                                    // isMulti={true}
                                    value={selectedInsumos}
                                    onChange={value => setSelectedInsumos(value)}
                                    options={insumos}
                                    placeholder='Selecione'
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </div>
                            <div>
                                <span>Alguma Observação</span>
                                <Input
                                    name="observacao"
                                    placeholder="Observação"
                                    icon={RiDraftLine}
                                    disabled={disabledInput}
                                />
                            </div>
                        </Column>
                        <Column>
                            <div>
                                <span>Quantidade</span>
                                <Input
                                    name="quantidade"
                                    placeholder="Quantidade"
                                    icon={RiStackLine}
                                    disabled={true}
                                />
                            </div>
                            <div>
                                <span>Região</span>
                                <Input
                                    name="regiao"
                                    placeholder="Região"
                                    icon={RiRoadMapLine}
                                    disabled={true}
                                />
                            </div>
                            <div>
                                <span>Forma de Pagamento</span>
                                <Input
                                    name="forma_pagamento"
                                    placeholder="Forma de Pagamento"
                                    icon={RiWallet3Line}
                                    disabled={true}
                                />
                            </div>
                            <div>
                                <span>Cultura Praticada</span>
                                <Input
                                    name="cultura"
                                    placeholder="Cultura Praticada"
                                    icon={RiHandHeartLine}
                                    disabled={true}
                                // value={culturaUser}
                                />
                            </div>
                            <div>
                                <span style={{ padding: 5 }}>Valor</span>
                                <Input
                                    name="valor"
                                    placeholder="Valor do orçamento"
                                    disabled={disabledInput}
                                    // type='hidden'
                                    icon={RiMoneyDollarBoxLine}
                                />
                            </div>
                        </Column>
                    </Content>
                    {/* <Buttons>
                        <div
                            type='checkbox'
                            value={checked}
                            onClick={() => setChecked(!checked)}
                            style={{ backgroundColor: checked && colors.secondary, marginRight: '18px' }}
                        />
                        <span>
                            Não Possui o produto
                        </span>
                    </Buttons> */}
                    {disabledInput === false &&
                        <div id="button" style={{ backgroundColor: "#FFF" }}>
                            <Button style={{ fontSize: '18px', width: "30%" }}  >
                                Enviar
                            </Button>
                        </div>}
                </Forms>
                <a href="#" onClick={() => setShowModalEnvioOrcamento(false)} >
                    Cancelar
                </a>
            </Body>
        </Container>
    );
}

export default ModalEnvioOrcamento;