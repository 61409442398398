import React, { useState, useEffect } from "react";
import Button from "../../../../../components/Button";

import { RiSearchLine } from "react-icons/ri";
import { Container, Body, Content, Image, Buscar } from "./styles";
import CidadePerfil from "../../../../../components/CidadePerfil";
import api from "../../../../../services/api";

function Cidade({ setShowCidade, setCidadeSelecionada }) {

    const [cidades, setCidades] = useState([]);
    const [buscar, setBuscar] = useState('');

    const loadingCidades = async () => {
        try {
            const { data } = await api.get(`/cidade_all`);
            setCidades(data);
        } catch (err) {
            console.log(err);
        }
    };

    const loadingCidadeNome = async () => {
        try {
            if (buscar) {
                const { data } = await api.post(`/cidadeNome`, { nome: buscar });
                setCidades(data);
            }

        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadingCidades();
    }, []);

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Cidades</h1>
                    <Image />
                </div>
                <p>Selecione sua cidade</p>
                <Buscar>
                    <input style={{ marginLeft: '10px' }} name="buscar" placeholder="buscar" onChange={(e) => setBuscar(e.target.value)} />
                    <div id='botao' style={{ height: '35px'}} type="button" onClick={() => loadingCidadeNome()}>
                        <RiSearchLine size={20} />
                    </div>
                </Buscar>
                <Content>
                    <div>
                        {cidades?.map((data, index) => (
                            <CidadePerfil
                                key={index}
                                cidade={data}
                                setShowCidade={setShowCidade}
                                setCidadeSelecionada={setCidadeSelecionada}
                            />
                        ))}
                    </div>
                </Content>
                <a href="#" onClick={() => setShowCidade(false)}>
                    Voltar
                </a>
            </Body>
        </Container>
    );
}

export default Cidade;