import React, { useRef, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import { AuthContextRegiao } from "../../../../../contexts/RegiaoContext";
import getValidationErrors from "../../../../../utils/getValidationsErrors";

import { Container, Forms, Body, Content, Column, Image } from "./styles";

function ModalEdit({ setShowModalEdit, user, loading }) {
    const formRef = useRef(null);

    const { updateRegiao } = useContext(AuthContextRegiao);

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                nome: Yup.string()
                    .required("Nome Obrigatório"),
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});

            // data.produtos = data.produtos.split(/(,[ ]?)/g);
            await updateRegiao(data, user?.id);
            loading();
            setShowModalEdit(false);
            // window.location.reload();
        } catch (err) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    const cidade = user.fk_cidades;
    const cidades = cidade.map((data) => {
        return data.nome
    });

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Editar Região</h1>
                    <Image />
                </div>
                <p>Insira os dados referentes a região</p>
                <Forms ref={formRef} onSubmit={handleSubmit}
                    initialData={
                        {
                            ...user,
                            cidades: cidades.join(', '),
                        }}>
                    <Content>
                        <Column>
                            <div>
                                <span>Nome</span>
                                <Input name="nome" placeholder="Nome da Categoria"
                                // icon={RiBuilding4Line}
                                />
                            </div>
                            <div>
                                <span>Cidades Vinculadas</span>
                                <Input name="cidades" placeholder="Cidades Vinculadas" disabled={true}
                                // icon={RiMailLine} 
                                />
                            </div>
                        </Column>
                    </Content>
                    <div style={{ backgroundColor: "#FFF" }}>
                        <Button style={{ fontSize: '18px', width: "30%" }}>Salvar</Button>
                    </div>
                </Forms>
                <a href="#" onClick={() => setShowModalEdit(false)}>
                    Voltar
                </a>
            </Body>
        </Container>
    );
}

export default ModalEdit;