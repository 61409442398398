import React, { useRef } from "react";
import Input from "../../../../components/Input";
import {
    RiMailLine,
    RiPhoneLine,
    RiRoadMapLine,
    RiBuilding4Line,
    RiCommunityLine,
    RiMapPinUserLine,
    RiHotelLine,
} from "react-icons/ri";

import { Container, Forms, Body, Content, Column, Image } from "./styles";

function ModalProvider({ setShowModalProvider, fornecedor }) {
    const formRef = useRef(null);

    const fornecedorDados = fornecedor?.fk_fornecedorOrcamentos[0].fk_fornecedor;

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Fornecedor</h1>
                    <Image />
                </div>
                <p>Dados do Fornecedor abaixo</p>
                <Forms ref={formRef} initialData={{ ...fornecedorDados}}>
                    <Content>
                        <Column>
                            <div>
                                <span>Fornecedor</span>
                                <Input name="nome_estabelecimento" placeholder="Fornecedor"
                                    icon={RiBuilding4Line}
                                />
                            </div>
                            <div>
                                <span>Email</span>
                                <Input name="email" placeholder="Email"
                                    icon={RiMailLine}
                                />
                            </div>
                            <div>
                                <span>Telefone</span>
                                <Input name="telefone" placeholder="Telefone"
                                    icon={RiPhoneLine} formatar="(99)9999-9999"
                                />
                            </div>
                            <div>
                                <span>Cidade</span>
                                <Input name="cidade" placeholder="Cidade"
                                    icon={RiCommunityLine}
                                />
                            </div>
                            
                        </Column>
                        <Column>
                            <div>
                                <span>Endereço</span>
                                <Input name="endereco" placeholder="Endereço"
                                    icon={RiMapPinUserLine}
                                />
                            </div>
                            <div>
                                <span>Região</span>
                                <Input name="regiao" placeholder="Região"
                                    icon={RiRoadMapLine}
                                />
                            </div>
                            <div>
                                <span>Cidade de Atuação</span>
                                <Input name="cidades_atuacao" placeholder="Cidade de Atuação"
                                    icon={RiHotelLine}
                                />
                            </div>
                        </Column>
                    </Content>
                </Forms>
                <a href="#" onClick={() => setShowModalProvider(false)}>
                    Cancelar
                </a>
            </Body>
        </Container>
    );
}

export default ModalProvider;