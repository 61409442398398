import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { toast } from "react-toastify";
import api from "../../../../../services/api";

import { Content } from "./styles";

function OrcamentosInsumos({ setInsumos, insumoSelecionado, setCategoriaInsumo, categoriaInsumoSelecionada, setCidadeInsumo, cidadeInsumoSelecionada, setRegiaoInsumo, regiaoInsumoSelecionado }) {

  const [insumo, setInsumo] = useState([]);

  const loadingInsumos = async () => {
    try {
      const { data } = await api.get(`/orcamentos_graficoProduto?insumo=${insumoSelecionado}&categoriaInsumo=${categoriaInsumoSelecionada}&cidadeInsumo=${cidadeInsumoSelecionada}&regiaoInsumo=${regiaoInsumoSelecionado}`);
      let insumos = data?.insumos.map((item) => item);
      if (insumos.length == 0) {
        toast.error(`Insumo não encontrado!`);
        return false;
      };
      setInsumo(data.insumos);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingInsumosFiltro = async () => {
    try {
      const { data } = await api.get(`/orcamentos_grafico_filtroInsumo`);

      const categoriaInsumos = [];
      data?.map((item) => {
        if (!categoriaInsumos.includes(item.nome)) {
          categoriaInsumos.push(item.nome)
        }
      });
      setInsumos(categoriaInsumos?.map(item => ({
        value: item, label: item
      })));

      const categorias = []
      data.map((item) => {
        item.categorias?.map((data) => {
          if (!categorias.includes(data.nome)) {
            categorias.push(data.nome)
          }
        })
      });
      setCategoriaInsumo(categorias.map(item => ({
        value: item, label: item
      })));

    } catch (err) {
      console.log(err);
    }
  };

  const loadingCidade = async () => {
    try {
      const { data } = await api.get(`/cidade_all`);
      let cidades = [];
      data.map((item) => {
        if (!cidades.includes(item.nome)) {
          cidades.push(item.nome)
        }
      });
      setCidadeInsumo(cidades?.map(item => ({
        value: item, label: item
      })));
    } catch (err) {
      console.log(err);
    }
  };

  const loadingRegiao = async () => {
    try {
        const { data } = await api.get(`/regiao_all`);
        let regiao = [];
        data.map((item) => {
          if(!regiao.includes(item.nome)){
            regiao.push(item.nome);
          };
        });
        setRegiaoInsumo(regiao?.map(item => ({
          value: item, label: item
        })));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadingInsumos();
    loadingCidade();
    loadingRegiao();
  }, [insumoSelecionado, categoriaInsumoSelecionada, cidadeInsumoSelecionada, regiaoInsumoSelecionado]);

  useEffect(() => {
    loadingInsumosFiltro();
  }, [insumo]);

  return (
    <Content>
      <BarChart
        width={insumo.length * 130}
        height={300}
        data={insumo}
        margin={{
          top: 20,
          // right: 30,
          // left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="nome_comercial" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='produtos' fill="#F8AA27" />
      </BarChart>
    </Content>
  );
}

export default OrcamentosInsumos;
