import React, { useContext, useEffect, useRef, useState } from "react";
import { RiBuilding4Line, RiCalendarTodoFill, RiCommunityLine, RiCustomerService2Line, RiHandCoinLine, RiHotelLine, RiLock2Line, RiMailLine, RiMapPinUserLine, RiPhoneLine, RiRoadMapLine, RiWhatsappLine } from "react-icons/ri";
import * as Yup from "yup";

import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { AuthContextFornecedor } from "../../../../contexts/FornecedorContext";
import getValidationErrors from "../../../../utils/getValidationsErrors";
import Cidade from "./Cidade";
import ModalCidade from "./ModalCidade";
import ModalRegiao from "./ModalRegiao";
import { Body, Column, Container, Content, Forms, Image } from "./styles";

function ModalEdit({ setShowModalEdit, user, loading }) {
    const formRef = useRef(null);

    const { updateFornecedor } = useContext(AuthContextFornecedor);

    const [showModalCidade, setShowModalCidade] = useState(false);
    const [cidadesSelecionadas, setCidadesSelecionadas] = useState([]);
    const [cidadesAtuacao, setCidadesAtuacao] = useState([]);

    const [showModalRegiao, setShowModalRegiao] = useState(false);
    const [regiaoUser, setRegiaoUser] = useState([]);
    const [regiaoSelected, setRegiaoSelected] = useState([]);
    // const [regiaoSelected, setRegiaoSelected] = useState(user?.regiao);
    // console.log('regiaoUser', regiaoUser);

    const [showCidade, setShowCidade] = useState(false);
    const [cidadeSelecionada, setCidadeSelecionada] = useState();

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                nome_estabelecimento: Yup.string()
                    .required("Nome obrigatório!")
                    .min(3, "O nome precisa ter no mínimo 3 caracteres")
                    .max(30, "O nome precisa ter no máximo 30 caracteres"),
                email: Yup.string()
                    .required("Email obrigatório!"),
                // .email("O email precisa ser válido!"),
                cidade: Yup.string()
                    .required("Cidade obrigatório!")
                    .min(3, "O nome da cidade precisa ter no mínimo 3 caracteres")
                    .max(30, "O nome da cidade precisa ter no máximo 30 caracteres"),
                endereco: Yup.string()
                    .required("Endereço obrigatório!")
                    .min(3, "O nome do endereço precisa ter no mínimo 3 caracteres")
                // senha: Yup.string().nullable()
                //   .min(6, "A senha precisa ter no mínimo 6 caracteres")
                //   .max(20, "A senha precisa ter no máximo 20 caracteres"),
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});

            if (data.senha == "") {
                // delete data.senha_confirmation;
                delete data.senha;
            }
            await updateFornecedor(data, user?.id);
            loading();
            setShowModalEdit(false);
        } catch (err) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    useEffect(() => {
        setCidadesAtuacao(user.cidades_atuacao.split(", "));
        if (user.regiao) {
            setRegiaoUser(user?.regiao.split(", "));
            setRegiaoSelected(user?.regiao.split(", "));
        }
    }, []);

    const cidades = cidadesSelecionadas?.map((data) => {
        return data
    });
    // console.log('cidades', cidades);

    const regiaoSelecionada = regiaoSelected?.map((data) => {
        return data
    });
    // console.log('regiaoSelected:', regiaoSelecionada);

    return (
        <>
            {showModalRegiao && (
                <ModalRegiao
                    setShowModalRegiao={setShowModalRegiao}
                    regiaoSelected={regiaoSelected}
                    setRegiaoSelected={setRegiaoSelected}
                    regiaoUser={regiaoUser}
                />
            )}
            {showModalCidade && (
                <ModalCidade
                    showModalCidade={showModalCidade}
                    setShowModalCidade={setShowModalCidade}
                    regiao={regiaoSelecionada}
                    cidadesSelecionadas={cidadesSelecionadas}
                    setCidadesSelecionadas={setCidadesSelecionadas}
                    cidadesAtuacao={cidadesAtuacao}
                    setCidadesAtuacao={setCidadesAtuacao}
                />
            )}
            {showCidade && (
                <Cidade
                    setShowCidade={setShowCidade}
                    setCidadeSelecionada={setCidadeSelecionada}
                />
            )}
            <Container>
                <Body>
                    <div>
                        <Image style={{ background: 'transparent' }} />
                        <h1>Editar Fornecedor</h1>
                        <Image />
                    </div>
                    <p>Confira os dados abaixo</p>
                    <Forms ref={formRef} onSubmit={handleSubmit} initialData={{ ...user, senha: "" }}>
                        <Content>
                            <Column>
                                <div>
                                    <span>Nome do Estabelecimento</span>
                                    <Input name="nome_estabelecimento" placeholder="Nome do estabelecimento"
                                        icon={RiBuilding4Line}
                                    />
                                </div>
                                <div>
                                    <span>Email</span>
                                    <Input name="email" placeholder="Seu email" color="#3F4254"
                                        icon={RiMailLine}
                                    />
                                </div>
                                <div>
                                    <span>Telefone para Contato</span>
                                    <Input name="telefone" placeholder="Seu telefone"
                                        icon={RiPhoneLine} formatar="(99)9999-9999"
                                    />
                                </div>
                                <div onClick={() => setShowCidade(true)} style={{ cursor: 'pointer' }}>
                                    <span>Cidade</span>
                                    {cidadeSelecionada ?
                                        <Input
                                            name="cidade"
                                            placeholder="Cidade"
                                            icon={RiCommunityLine}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>{
                                                e.preventDefault();
                                                setShowCidade(true)
                                              }}
                                            value={cidadeSelecionada}
                                        /> :
                                        <Input
                                            name="cidade"
                                            placeholder="Cidade"
                                            icon={RiCommunityLine}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>{
                                                e.preventDefault();
                                                setShowCidade(true)
                                              }}
                                        />}
                                </div>
                            </Column>
                            <Column>
                                <div>
                                    <span>Endereço Completo</span>
                                    <Input name="endereco" placeholder="Seu Endereço"
                                        icon={RiMapPinUserLine}
                                    />
                                </div>
                                <div>
                                    <span>Whatsapp</span>
                                    <Input name="whatsapp" placeholder="Seu whatsapp"
                                        icon={RiWhatsappLine} formatar="(99)99999-9999"
                                    />
                                </div>
                                <div>
                                    <span>Senha</span>
                                    <Input name="senha" placeholder="****" type="password"
                                        icon={RiLock2Line}
                                    />
                                </div>
                                <div onClick={() => setShowModalRegiao(true)} style={{ cursor: 'pointer' }}>
                                    <span>Região de Atuação</span>
                                    <Input
                                        name="regiao"
                                        placeholder="Noroeste de Minas."
                                        icon={RiRoadMapLine}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) =>{
                                            e.preventDefault();
                                            setShowModalRegiao(true)
                                          }}
                                        value={regiaoSelecionada.length > 0 ? regiaoSelecionada.join(', ') : user?.regiao}
                                    />
                                </div>
                            </Column>
                            <Column>
                                <div onClick={() => setShowModalCidade(true)} style={{ cursor: 'pointer' }}>
                                    <span>Cidades de Atuação</span>
                                    <Input
                                        name="cidades_atuacao"
                                        placeholder="Joao Pinheiro, Lagamar..."
                                        icon={RiHotelLine}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) =>{
                                            e.preventDefault();
                                            setShowModalCidade(true)
                                          }}
                                        value={cidades.length > 0 ? cidades.join(", ") : user?.cidades_atuacao}
                                    />
                                </div>
                            </Column>
                        </Content>
                        <div id='button' style={{ backgroundColor: "#FFF" }}>
                            <Button style={{ fontSize: '18px', width: "30%" }}>
                                Salvar
                            </Button>
                        </div>
                    </Forms>
                    <a href="#" onClick={() => setShowModalEdit(false)}>
                        Voltar
                    </a>
                </Body>
            </Container>
        </>
    );
}

export default ModalEdit;