import React, { useState, useEffect } from 'react';
import { RiArrowRightSLine } from "react-icons/ri";

import { Container, Buttons } from './styles';

const InsumosCategoriaRow = ({ insumos, setSelecionado, selecionado }) => {
    const [checked, setChecked] = useState(false);

    function handleClick() {
        const index = selecionado.findIndex(item => item == insumos.nome)
        if (index != -1) {
            selecionado.splice(index, 1)
            setSelecionado(selecionado)
        } else {
            const auxiliar = [...selecionado]
            auxiliar.push(insumos.nome)
            setSelecionado(auxiliar)
        }
    };

    useEffect(() => {
        if (selecionado?.includes(insumos.nome)) {
            setChecked(true)
        }
    }, []);

    return (
        <>
            <Container
                style={{ background: checked && 'rgb(248, 170, 39, 0.2)' }}
            >
                <header>
                    <p id='p'>{insumos.nome}</p>
                </header>
                <Buttons >
                    <button style={{ color: "#20655F" }}
                        onClick={() => { setChecked(!checked); handleClick() }}
                    >
                        <RiArrowRightSLine size={30} />
                    </button>
                </Buttons>
            </Container>
        </>
    )
}

export default InsumosCategoriaRow;
