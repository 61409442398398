import React, { useContext, useEffect, useRef, useState } from "react";
import { RiBuilding4Line, RiCommunityLine, RiHandHeartLine, RiLock2Line, RiMailLine, RiPhoneLine, RiRoadMapLine } from "react-icons/ri";
import * as Yup from "yup";

import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { AuthContext } from "../../../../contexts/UserContex";
import getValidationErrors from "../../../../utils/getValidationsErrors";
import Cidade from "./Cidade";
import Cultura from "./Cultura";
import Regiao from "./Regiao";
import { Body, Column, Container, Content, Forms, Image } from "./styles";

function ModalCreate({ setShowModalCreate, user, loading }) {
    const formRef = useRef(null);

    const { createUser } = useContext(AuthContext);

    const [showCidade, setShowCidade] = useState(false);
    const [cidadeSelecionada, setCidadeSelecionada] = useState();

    const [showCultura, setShowCultura] = useState(false);
    const [culturaSelecionada, setCulturaSelecionada] = useState([]);

    const [showModalRegiao, setShowModalRegiao] = useState(false);
    const [regiaoSelected, setRegiaoSelected] = useState();


    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                nome: Yup.string()
                    .required("Nome obrigatório!")
                    .min(3, "O nome precisa ter no mínimo 3 caracteres")
                    .max(50, "O nome precisa ter no máximo 30 caracteres"),
                email: Yup.string()
                    .required("Email obrigatório!"),
                // .email("O email precisa ser válido!"),
                cidade: Yup.string()
                    .required("Cidade obrigatório!")
                    .min(3, "O nome da cidade precisa ter no mínimo 3 caracteres")
                    .max(60, "O nome da cidade precisa ter no máximo 30 caracteres"),
                regiao: Yup.string()
                    .required("Endereço obrigatório!")
                    .min(3, "O nome do endereço precisa ter no mínimo 3 caracteres")
                    .max(30, "O nome do endereço precisa ter no máximo 30 caracteres"),
                senha: Yup.string()
                    .required("Senha obrigatória!")
                    .min(6, "A senha precisa ter no mínimo 6 caracteres")
                    .max(6, "A senha precisa ter no máximo 20 caracteres"),
                documento: Yup.string()
                    .required("CPF/CNPJ obrigatório!")
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});

            await createUser(data);
            loading();
            setShowModalCreate(false);
        } catch (err) {
            console.log(err);
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    return (
        <>
            {showCidade && (
                <Cidade
                    setShowCidade={setShowCidade}
                    setCidadeSelecionada={setCidadeSelecionada}
                />
            )}
            {showModalRegiao && (
                <Regiao
                    setShowModalRegiao={setShowModalRegiao}
                    setRegiaoSelected={setRegiaoSelected}
                />
            )}
            {showCultura && (
                <Cultura
                    setShowCultura={setShowCultura}
                    setCulturaSelecionada={setCulturaSelecionada}
                    culturaSelecionada={culturaSelecionada}
                />
            )}
            <Container>
                <Body>
                    <div>
                        <Image style={{ background: 'transparent' }} />
                        <h1>Cadastrar Usuário</h1>
                        <Image />
                    </div>
                    <p>Confira os dados abaixo</p>
                    <Forms ref={formRef} onSubmit={handleSubmit}>
                        <Content>
                            <Column>
                                <div>
                                    <span>Nome</span>
                                    <Input name="nome" placeholder="Seu Nome"
                                        icon={RiBuilding4Line}
                                    />
                                </div>
                                <div>
                                    <span>E-mail</span>
                                    <Input name="email" placeholder="Seu email"
                                        icon={RiMailLine}
                                    />
                                </div>
                                <div>
                                    <span>Telefone</span>
                                    <Input name="telefone" placeholder="Seu telefone"
                                        icon={RiPhoneLine} formatar="(99)99999-9999"
                                    />
                                </div>
                            </Column>
                            <Column>
                                <div onClick={() => setShowCidade(true)} style={{ cursor: 'pointer' }}>
                                    <span>Cidade</span>
                                    {cidadeSelecionada ?
                                        <Input
                                            name="cidade"
                                            placeholder="Cidade"
                                            icon={RiCommunityLine}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>{
                                                e.preventDefault();
                                                setShowCidade(true)
                                              }}
                                            value={cidadeSelecionada}
                                        /> :
                                        <Input
                                            name="cidade"
                                            placeholder="Cidade"
                                            icon={RiCommunityLine}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>{
                                                e.preventDefault();
                                                setShowCidade(true)
                                              }}
                                        />}
                                </div>
                                <div onClick={() => setShowModalRegiao(true)} style={{ cursor: 'pointer' }}>
                                    <span >Região de Atuação</span>
                                    <Input
                                        name="regiao"
                                        placeholder="Região"
                                        icon={RiRoadMapLine}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) =>{
                                            e.preventDefault();
                                            setShowModalRegiao(true)
                                          }}
                                        value={regiaoSelected}
                                    />
                                </div>
                                <div>
                                    <span>Senha</span>
                                    <Input name="senha" placeholder="****" type="password"
                                        icon={RiLock2Line}
                                    />
                                </div>
                            </Column>
                            <Column>
                                <div onClick={() => setShowCultura(true)} style={{ cursor: 'pointer' }}>
                                    <span>Cultura Praticada</span>
                                    {culturaSelecionada ?
                                        <Input
                                            name="cultura"
                                            placeholder="Sua Cultura"
                                            icon={RiHandHeartLine}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>{
                                                e.preventDefault();
                                                setShowCultura(true)
                                              }}
                                            value={culturaSelecionada.join(', ')}
                                        /> :
                                        <Input
                                            name="cultura"
                                            placeholder="Sua Cultura"
                                            icon={RiHandHeartLine}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>{
                                                e.preventDefault();
                                                setShowCultura(true)
                                              }}
                                        />}
                                </div>
                                <div>
                                    <span>CPF</span>
                                    <Input name="documento" placeholder="999.999.999-99"
                                        icon={RiPhoneLine} formatar="999.999.999-99"
                                    />
                                </div>
                            </Column>
                        </Content>
                        <div id='button' style={{ backgroundColor: "#FFF" }}>
                            <Button style={{ fontSize: '18px', width: "30%" }}>Salvar</Button>
                        </div>
                    </Forms>
                    <a href="#" onClick={() => setShowModalCreate(false)}>
                        Voltar
                    </a>
                </Body>
            </Container>
        </>
    );
}

export default ModalCreate;