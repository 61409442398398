import React, { useState, useEffect } from "react";
import Button from "../../../../../../components/Button";
import { Container, Body, Content, Image } from "./styles";
import ProdutosCategoriaRow from "../../../../../../components/ProdutosCategoriaRow";
import api from "../../../../../../services/api";

function ModalProduto({ setShowModalProduto, user, setProdutosSelecionados }) {

    const [produtos, setProdutos] = useState([]);
    const [selecionado, setSelecionado] = useState();
    const produtosCategoria = user.fk_produtos;

    const loadingProdutos = async () => {
        try {
            const { data } = await api.get(`/produto_nome`);
            setProdutos(data);
        } catch (err) {
            console.log(err);
        }
    };

    async function salvarProdutos() {
        setProdutosSelecionados(selecionado);
    };

    useEffect(() => {
        loadingProdutos();
    }, []);

    useEffect(() => {
        setSelecionado(produtosCategoria?.map(item => item.nome))
    },[]);

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Produtos</h1>
                    <Image />
                </div>
                <p>Selecione os Produtos</p>
                <Content>
                    <div>
                        {produtos?.map((data) => (
                            <ProdutosCategoriaRow
                                key={data.id}
                                produtos={data}
                                setShowModalProduto={setShowModalProduto}
                                setSelecionado={setSelecionado}
                                selecionado={selecionado}
                            />
                        ))}
                    </div>
                </Content>
                <div id="button" style={{ backgroundColor: "#FFF" }}>
                    <Button style={{ fontSize: '18px', width: "30%" }}
                        onClick={() => { salvarProdutos(); setShowModalProduto(false) }}
                    >
                        Salvar
                    </Button>
                </div>
                <a href="#" onClick={() => setShowModalProduto(false)}>
                    Voltar
                </a>
            </Body>
        </Container>
    );
}

export default ModalProduto;