import React, { useState, useEffect } from "react";

import { Container, Body, Content, Image } from "./styles";
import CulturaRow from "../../../../../components/CulturaRow";

function Cultura({ setShowCultura, setCulturaSelecionada, culturaSelecionada, culturas }) {

    const cultura = [
        {nome: 'Café'},
        {nome: 'Soja'},
        {nome: 'Milho'},
        {nome: 'Sorgo'},
        {nome: 'Trigo'},
        {nome: 'Algodão'},
        {nome: 'Arroz'},
        {nome: 'Feijão'}
    ]

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Cultura Praticada</h1>
                    <Image />
                </div>
                <p>Selecione sua cultura</p>
                <Content>
                    <div>
                        {cultura?.map((data, index) => (
                            <CulturaRow
                                key={index}
                                cultura={data}
                                setShowCultura={setShowCultura}
                                setCulturaSelecionada={setCulturaSelecionada}
                                culturaSelecionada={culturaSelecionada}
                                culturas={culturas}
                            />
                        ))}
                    </div>
                </Content>
                <a href="#" onClick={() => setShowCultura(false)}>
                    Voltar
                </a>
            </Body>
        </Container>
    );
}

export default Cultura;