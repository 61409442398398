import styled from "styled-components";
import Background from "../../../assets/background.jpg";
import colors from "../../../styles/colors";
import fonts from "../../../styles/fonts";
import { Form } from "@unform/web";

export const Container = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 450px;
    width: 480px;
    background: #fff;
    border-radius: 30px;
    h1 {
        color: ${colors.dark};
        ${fonts[700]};
        font-size: 40px;
        margin: 0px 0px;
        margin-top: 47px;
    }
    > span {
        color: ${colors.extraLightGray};
        ${fonts[400]};
        font-size: 20px;
        margin: 0px 55px;
    }
    > div {
        display: flex;
        ${fonts[400]};
        margin: 0px 65px;
        margin-top: 25px;
        font-weight: 400;
        > a {
            margin-left: 10px;
            font-size: 18px;
            color: #14A54D;
        }
    }
`;

export const Forms = styled(Form)`
    display: flex;
    width: 70%;
    flex-direction: column;
    margin-top: 50px;
    align-self: center;
    > div {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 36px;  
    border-radius: 8px;
    /* background-color: yellow;  */
    box-shadow: 0px 5px 6px rgba(0,0,0,0.2);
    > span {
    display: flex;
    margin-bottom: 12px;
    color: ${colors.darKGray};
    ${fonts[400]};
    /* background-color: blue; */
    }
    svg {
      color: ${colors.darkGrayishBlue};
    }
  }
`;