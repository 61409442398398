import React, { useState, useEffect } from "react";
import Pagination from '@mui/material/Pagination';
import { format } from 'date-fns';

import { Container, Content, Body, Image, SubHeader, ContainerCard } from "./styles";
import colors from "../../../../styles/colors";
import api from "../../../../services/api";

const respostas = [
    { id: 1, nome: 'Fornecedor1', valor: 25.30 },
    { id: 2, nome: 'Fornecedor2', valor: 0 },
    { id: 3, nome: 'Fornecedor3', valor: 2.00 },
    { id: 4, nome: 'Fornecedor4', valor: 25.00 },
    { id: 5, nome: 'Fornecedor5', valor: null },
    { id: 6, nome: 'Fornecedor6', valor: 15.00 },
    { id: 7, nome: 'Fornecedor7', valor: null },
    { id: 8, nome: 'Fornecedor8', valor: 14.00 },
]

function ModalDetalhesSolicitacao({ setShowModalDetalhes, orcamento }) {
    const [page, setPage] = useState(0);
    const [orcamentos, setOrcamentos] = useState();
    const [date, setDate] = useState();

    async function loadingFornecedor() {
        try {
            const orcamentoId = orcamento?.id
            const { data } = await api.get(`/orcamento/${orcamentoId}/fornecedor/${orcamento.regiao}`);
            setOrcamentos(data);
        } catch (err) {
            console.log(err);
        }
    };
    const dataAtualizada = orcamentos?.map(item => item?.updatedAt);
    const fornecedores = []
    const produtos = []

    orcamentos?.forEach(element => {
        if (element.fk_produto == null && element.fk_insumo == null) {
            return;
        } else {
            element.fk_produto ? produtos.push(element.fk_produto.nome) : produtos.push(element.fk_insumo.nome)
            element.fk_produto &&
                element.fk_produto.fk_fornecedor?.map((item) => {
                    const valor = item.fk_fornecedorOrcamentos?.map((data) => data.valor_orcamento)
                    return fornecedores.push({
                        id: item.id,
                        nome: item.nome_estabelecimento,
                        valor: valor,
                    })
                })

            element.fk_insumo &&
                element.fk_insumo.fk_fornecedor?.map((item) => {
                    const valor = item.fk_fornecedorOrcamentos?.map((data) => data.valor_orcamento)
                    return fornecedores.push({
                        id: item.id,
                        nome: item.nome_estabelecimento,
                        valor: valor,
                    })
                })
        }
    });

    const respondidos = []
    const aguardando = []
    fornecedores.forEach((item) => {
        if (item.valor.length > 0) {
            return respondidos.push({ ...item })
        }

        return aguardando.push({ ...item })
    });
    fornecedores.sort((a, b) => a.valor - b.valor);
    respondidos.sort((a, b) => a.valor - b.valor);
    const arr = respondidos.filter(item => {
        return item.valor == 0
    })
    const resp = respondidos.filter(item => {
        return item.valor > 0
    })

    const list = [...resp, ...arr, ...aguardando];

    const enviadosPaginado = list?.slice(page * 6, page * 6 + 6);
    const totalPages = Math.ceil(list.length / 6);

    useEffect(() => {
        loadingFornecedor();
    }, []);

    useEffect(() => {
        if (dataAtualizada && dataAtualizada.length > 0) {
            setDate(format(new Date(dataAtualizada), 'dd/MM/yyyy'))
        }
    }, [dataAtualizada]);

    return (
        <Container>
            <Content>
                <div id="header">
                    <Image style={{ background: 'transparent' }} />
                    <h1>Detalhes da solicitação</h1>
                    <Image />
                </div>
                <SubHeader>
                    <div id="cards">
                        <p>Data do pedido</p>
                        <p>{date}</p>
                    </div>
                    <div id="cards">
                        <p>Nº de respostas</p>
                        <p>{list.length} respostas</p>
                    </div>
                </SubHeader>
                <Body>
                    <div id="contentBody">
                        {enviadosPaginado?.map((item) => (
                            <Cards
                                nome={item.nome}
                                valor={item.valor}
                                key={item.id}
                            />
                        ))}
                    </div>
                </Body>
                <Pagination
                    count={totalPages}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)}
                    shape="rounded"
                    style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                />
                <a href="#" onClick={() => setShowModalDetalhes(false)}>
                    Cancelar
                </a>
            </Content>
        </Container>
    );
}

export default ModalDetalhesSolicitacao;

export function Cards({ nome, valor }) {
    return (
        <ContainerCard>
            <div id="nome">
                <p>{nome}</p>
            </div>
            {valor ?
                <div id="valor">
                    <p>Total</p>
                    <p style={{ color: colors.primary }}>R$ {valor}</p>
                </div> :
                <div id="analise">
                    <p style={{ color: colors.secondary }}>Em análise</p>
                </div>
            }
        </ContainerCard>
    );
}