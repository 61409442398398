import axios from "axios";

import { getToken } from "./auth";

const api = axios.create({
  baseURL: "https://api.campoinvest.com.br/",
  // baseURL: "http://localhost:3333/",
  // baseURL: "http://192.168.0.105:3333/"
  // baseURL: "http://192.168.0.49:3333/",
  // baseURL: "http://192.168.1.102:3333/",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
