import React, { createContext } from "react";
import { toast } from "react-toastify";
import api from "../services/api";
import { getId } from "../services/auth";

const AuthContextProduto = createContext({});

const AuthProviderProduto = ({ children }) => {

  /* Função para carregar usuário */
  const loadingProduto = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/produto/${id}`);
      return data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* Função para delatar usuário */
  const deleteProduto = async (id) => {
    try {
      const { data } = await api.delete(`/produto/${id}`);
      toast.success(data.message);
      return data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

      return false;
    }
  };

  /* Função para atualiza usuário */
  const updateProduto = async (data, id) => {
    try {
      const resp = await api.put(`/produto/${id}`, data);
      toast.success(resp?.data?.message);
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

      return false;
    }
  };

  /* Função para criar um novo usuário */
  const createProduto = async (data) => {
    try {
      const resp = await api.post(`/produto`, data);
      !resp.data?.id ?
        toast.error(resp?.data?.message) :
        toast.success("Cadastrado com sucesso!");
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
      return false;
    }
  };

  return (
    <AuthContextProduto.Provider
      value={{
        deleteProduto,
        loadingProduto,
        updateProduto,
        createProduto,
      }}
    >
      {children}
    </AuthContextProduto.Provider>
  );
};

export { AuthContextProduto, AuthProviderProduto };