import React, { useRef, useContext, useState, useEffect } from "react";
import * as Yup from "yup";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import { AuthContextCidade } from "../../../../../contexts/CidadeContext";
import getValidationErrors from "../../../../../utils/getValidationsErrors";
import api from "../../../../../services/api";
import Select from "react-select";

import { Container, Forms, Body, Content, Column, Image } from "./styles";

function ModalEditCidade({ setShowModalEdit, user, loading }) {
    const formRef = useRef(null);
    const { updateCidade } = useContext(AuthContextCidade);

    const [regiao, setRegiao] = useState();
    const [selectedRegiao, setSelectedRegiao] = useState();

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                nome: Yup.string()
                    .required("Nome Obrigatório")
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});
            // data.categorias = data.categorias.split(/(,[ ]?)/g);
            // data.categorias = selectedCategorias.map(item => item.label);

            data.regiao = selectedRegiao.map(item => item.label);
            await updateCidade(data, user?.id);
            loading();
            setShowModalEdit(false);
            window.location.reload();

        } catch (err) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    const loadingRegiao = async () => {
        try {
            const { data } = await api.get('/regiao_all');
            const regiao = user.fk_regiao?.map(item => item);

            setSelectedRegiao(regiao.map(item => ({
                value: item.id, label: item.nome
            })));

            setRegiao(data?.map(item => ({
                value: item.id, label: item.nome
            })));

        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadingRegiao();
    }, []);

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Editar Cidade</h1>
                    <Image />
                </div>
                <p>Insira os dados referente a cidade</p>
                <Forms ref={formRef} onSubmit={handleSubmit} initialData={{ ...user }}>
                    <Content>
                        <Column>
                            <div div style={{ paddingRight: '10px' }}>
                                <span>Nome</span>
                                <Input name="nome" placeholder="Nome do Insumo"
                                // icon={RiBuilding4Line}
                                />
                            </div>
                            <div div style={{ paddingRight: '10px' }}>
                                <span>Região Vinculada</span>
                                <Select
                                    isMulti={true}
                                    onChange={value => setSelectedRegiao(value)}
                                    value={selectedRegiao}
                                    options={regiao}
                                    placeholder='Selecione'
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </div>
                        </Column>
                    </Content>
                    <div style={{ backgroundColor: "#FFF", }}>
                        <Button style={{ fontSize: '18px', width: "30%" }}>Salvar</Button>
                    </div>
                </Forms>
                <a href="#" onClick={() => setShowModalEdit(false)}>
                    Voltar
                </a>
            </Body>
        </Container>
    );
}

export default ModalEditCidade;