/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-dupe-keys */
export default {
    400: {
      fontFamily: "Kanit",
      fontStyle: "normal",
      fontWeight: 400,
      fontDisplay: "swap",
    },
    700: {
      fontFamily: "Kanit",
      fontStyle: "normal",
      fontWeight: 700,
      fontDisplay: "swap",
    },
  };