import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { getId } from "../../../../services/auth";
import api from "../../../../services/api";

function CotacoesRecebidasRespondidas({ setMes, filtroMesSelecionado, setAno, filtroAnoSelecionado}) {

  const [cotacoes, setCotacoes] = useState([]);

  const loadingCotacoes = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/grafico_showRecebidasRespondidas/${id}?mes=${filtroMesSelecionado}&ano=${filtroAnoSelecionado}`);
      setCotacoes(data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingFiltroMes = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/grafico_recebidosRespondidos_filtroMes/${id}`);

      setMes(data?.map(item => ({
        value: item.nome, label: item.nome
      })));
    } catch (err) {
      console.log(err);
    }
  };

  const loadingFiltroAno = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/grafico_recebidosRespondidos_filtroAno/${id}`);
      const anos = []
      data?.map((item) => {
        if (!anos.includes(item.ano)) {
          anos.push(item.ano)
        }
      });
      setAno(anos?.map(item => ({
        value: item, label: item
      })));

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadingCotacoes();
  }, [filtroMesSelecionado, filtroAnoSelecionado]);

  useEffect(() => {
    loadingFiltroMes();
    loadingFiltroAno();
  }, [cotacoes]);

  return (
    <BarChart
      width={1000}
      height={300}
      data={cotacoes}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="nome" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="recebidos"  fill="#20655F" />
      <Bar dataKey="respondidos"  fill="#F8AA27" />
    </BarChart>
  );
}

export default CotacoesRecebidasRespondidas;
