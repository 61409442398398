import React from 'react';
import { RiDeleteBin5Line, RiEditBoxFill } from "react-icons/ri";

import { Container, Body, Buttons } from './styles';

const RegiaoRow = ({ regiao, setShowModalDelete, setShowModalEdit, setUserSelected, setBotao }) => {

    const cidades = regiao.fk_cidades
    const cidade = cidades?.map((data) => {
        return data.nome
    });

    return (
        <>
            <Container>
                {/* <Body>
                    <img src='https://picsum.photos/200' />
                </Body> */}
                <header>
                    <p id='p'>{regiao.nome}</p>
                    <p>Cidades Vinculadas:</p>
                </header>
                <Body style={{ textAlign: 'start' }}>
                    <span>{cidade.join(', ')}</span>
                </Body>
                <Buttons>
                    <button style={{ color: "#F89747" }}
                        onClick={() => { setShowModalEdit(true); setUserSelected(regiao); setBotao(1) }}>
                        <RiEditBoxFill size={30} />
                    </button>
                    <button style={{ color: "#F26689" }}
                        onClick={() => { setShowModalDelete(true); setUserSelected(regiao); setBotao(1) }}
                    >
                        <RiDeleteBin5Line size={30} />
                    </button>
                </Buttons>
            </Container>
        </>
    )
}

export default RegiaoRow;
