import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { toast } from "react-toastify";
import api from "../../../../services/api";

function SomaValores({ setMes, filtroMesSelecionado, setAno, filtroAnoSelecionado, setSomaValoresCidade, somaValoresCidadeSelecionado, setSomaValoresRegiao, somaValoresRegiaoSelecionado }) {

  const [valores, setValores] = useState([]);

  const loadingValues = async () => {
    try {
      const { data } = await api.get(`/grafico_soma_melhores_piores?mes=${filtroMesSelecionado}&ano=${filtroAnoSelecionado}&cidade=${somaValoresCidadeSelecionado}&regiao=${somaValoresRegiaoSelecionado}`);
      let valores = data?.map((item) => item);
      if (valores.length == 0) {
        toast.error(`Valores não encontrado!`);
        return false;
      };
      setValores(data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingValuesFiltroMes = async () => {
    try {
      const { data } = await api.get(`/grafico_soma_valores_filtroMes`);
      setMes(data?.map(item => ({
        value: item.nome, label: item.nome
      })));
    } catch (err) {
      console.log(err);
    }
  };

  const loadingValuesFiltroAno = async () => {
    try {
      const { data } = await api.get(`/grafico_soma_valores_filtroAno`);
      const anos = []
      data?.map((item) => {
        if (!anos.includes(item.ano)) {
          anos.push(item.ano)
        }
      });

      setAno(anos?.map(item => ({
        value: item, label: item
      })));
    } catch (err) {
      console.log(err);
    }
  };

  const loadingCidade = async () => {
    try {
      const { data } = await api.get(`/cidade_all`);
      let cidades = [];
      data.map((item) => {
        if (!cidades.includes(item.nome)) {
          cidades.push(item.nome)
        }
      });
      setSomaValoresCidade(cidades?.map(item => ({
        value: item, label: item
      })));
    } catch (err) {
      console.log(err);
    }
  };

  const loadingRegiao = async () => {
    try {
        const { data } = await api.get(`/regiao_all`);
        let regiao = [];
        data.map((item) => {
          if(!regiao.includes(item.nome)){
            regiao.push(item.nome);
          };
        });
        setSomaValoresRegiao(regiao?.map(item => ({
          value: item, label: item
        })));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadingValues();
    loadingCidade();
    loadingRegiao();
  }, [filtroMesSelecionado, filtroAnoSelecionado, somaValoresCidadeSelecionado, somaValoresRegiaoSelecionado]);

  useEffect(() => {
    loadingValuesFiltroMes();
    loadingValuesFiltroAno();
  }, [valores]);

  return (
    <BarChart
      width={1000}
      height={300}
      data={valores}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="nome" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="melhores" fill="#20655F" />
      <Bar dataKey="piores" fill="#F8AA27" />
    </BarChart>
  );
}

export default SomaValores;
