import React, { useState, useEffect } from 'react';
import { RiArrowRightSLine } from "react-icons/ri";

import { Container, Buttons } from './styles';

const RegiaoRow = ({ cidade, cidadesSelecionadas, setCidadesSelecionadas, cidadesAtuacao }) => {

    const [checked, setChecked] = useState();

    // function handleClick() {
    //     const index = selecionado.findIndex(item => item == cidades)
    //     if (index != -1) {
    //         selecionado.splice(index, 0)
    //         setSelecionado(selecionado)
    //     } else {
    //         const auxiliar = [...selecionado]
    //         auxiliar.push(cidades)
    //         setSelecionado(auxiliar)
    //     }
    // };

    useEffect(() => {
        if (cidadesAtuacao != '') {
            if (cidadesAtuacao.includes(cidade)) {
                setChecked(true)
            }
        };
    }, [cidade]);

    useEffect(() => {
        if (cidadesSelecionadas.includes(cidade)) {
            setChecked(true)
        };
    }, [cidadesSelecionadas]);

    function handleClick() {
        if (checked === true) {
            let arr = cidadesSelecionadas.filter(item => item !== cidade)
            setCidadesSelecionadas([...arr]);
        } else {
            cidadesSelecionadas.push(cidade);
            setCidadesSelecionadas([...cidadesSelecionadas]);
        }
        setChecked(!checked);
    };

    return (
        <>
            <Container
                style={{ background: checked && 'rgb(248, 170, 39, 0.2)' }}
            >
                <header>
                    <p id='p'>{cidade}</p>
                </header>
                <Buttons>
                    <button style={{ color: "#20655F" }}
                        onClick={() => { handleClick() }}>
                        <RiArrowRightSLine size={30} />
                    </button>
                </Buttons>
            </Container>
        </>
    )
}

export default RegiaoRow;
