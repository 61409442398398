import React, { useState, useEffect} from 'react';
import { RiArrowRightSLine } from "react-icons/ri";

import { Container, Body, Buttons } from './styles';

const RegiaoRow = ({ regiao, setRegiaoSelected, regiaoSelected, regiaoUser }) => {

    const [checked, setChecked] = useState();

    useEffect(() => {
        if (regiaoUser) {
            if (regiaoUser.includes(regiao.nome)) {
                setChecked(true);
            }
        };
    }, [regiao]);

    // useEffect(() => {
    //     if (regiaoSelected.includes(regiao)) {
    //         setChecked(true)
    //     };
    // }, [regiaoSelected]);

    function handleClick() {
        if (checked === true) {
            let arr = regiaoSelected.filter(item => item !== regiao.nome)
            setRegiaoSelected([...arr]);
        } else {
            regiaoSelected.push(regiao.nome)
            setRegiaoSelected([...regiaoSelected]);
        }
        setChecked(!checked);
    };

    return (
        <>
            <Container
                style={{ background: checked && 'rgb(248, 170, 39, 0.2)' }}
            >
                <header>
                    <p id='p'>{regiao.nome}</p>
                </header>
                <Buttons>
                    <button style={{ color: "#20655F" }}
                        onClick={() => { handleClick() }}>
                        <RiArrowRightSLine size={30} />
                    </button>
                </Buttons>
            </Container>
        </>
    )
}

export default RegiaoRow;
