import React, { useState, useEffect } from "react";
import Button from "../../../../../components/Button";

import { RiSearchLine } from "react-icons/ri";
import { Container, Body, Content, Image, Buscar } from "./styles";
import CidadePerfilRow from "../../../../../components/CidadePerfilRow";
import api from "../../../../../services/api";

function ModalCidade({ showModalCidade, setShowModalCidade, regiao, cidadesSelecionadas, setCidadesSelecionadas, cidadesAtuacao }) {
    const [cidades, setCidades] = useState([]);
    const [buscar, setBuscar] = useState('');

    const loadingRegiao = async () => {
        try {
                const nome = regiao
                const { data } = await api.post(`/regiao_nome_cidade`, { nome: nome });
                const cd = [];

                data.forEach((item) => {
                    item.fk_cidades.forEach((data) => {
                        return cd.push(data.nome)
                    })
                });
                setCidades(cd);
        } catch (err) {
            console.log(err);
        }
    };

    const loadingCidadeNome = async () => {
        try {
            if (buscar) {
                const busca = cidades?.filter(item => item.toLowerCase().includes(buscar.toLowerCase()));
                setCidades(busca);
            }
        } catch (err) {
            console.log(err);
        }
    };

    // async function salvarCidades() {
    //     setCidadesSelecionadas(selecionado);
    // };

    useEffect(() => {
        loadingRegiao();
    }, []);

    useEffect(() => {
        if (showModalCidade === true) {
            if (cidadesAtuacao != '') {
                setCidadesSelecionadas(cidadesAtuacao)
            } else {
                setCidadesSelecionadas(cidades)
            }
        }
    }, [cidades]);

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Cidades</h1>
                    <Image />
                </div>
                <p>Selecione as Cidades de Atuação</p>
                <Buscar>
                    <input style={{ marginLeft: '10px' }} name="buscar" placeholder="buscar" onChange={(e) => setBuscar(e.target.value)} />
                    <div id='botao' type="button" style={{ height: '35px' }} onClick={() => loadingCidadeNome()}>
                        <RiSearchLine size={20} />
                    </div>
                </Buscar>
                <Content>
                    <div>
                        {cidades?.map((data, index) => (
                            <CidadePerfilRow
                                key={index}
                                cidade={data}
                                setShowModalCidade={setShowModalCidade}
                                setCidadesSelecionadas={setCidadesSelecionadas}
                                cidadesSelecionadas={cidadesSelecionadas}
                                cidadesAtuacao={cidadesAtuacao}
                            />
                        ))}
                    </div>
                </Content>
                <div id="button" style={{ backgroundColor: "#FFF" }}>
                    <Button style={{ fontSize: '18px', width: "30%" }}
                        onClick={() => { setShowModalCidade(false) }}
                    >
                        Salvar
                    </Button>
                </div>
                <a href="#" onClick={() => setShowModalCidade(false)}>
                    Voltar
                </a>
            </Body>
        </Container>
    );
}

export default ModalCidade;