import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./contexts/UserContex";
import { AuthProviderFornecedor } from "./contexts/FornecedorContext";
import { AuthProviderCategoria } from "./contexts/CategoriaContext";
import { AuthProviderProduto } from "./contexts/ProdutoContext";
import { AuthProviderInsumo } from "./contexts/InsumoContext";
import { AuthProviderFornecedorProduto } from "./contexts/FornecedorProdutosContext";
import { AuthProviderFornecedorInsumo } from './contexts/FornecedorInsumosContext';
import { AuthProviderSolicitacoes } from "./contexts/SolicitacoesContext";
import { AuthProviderRegiao } from "./contexts/RegiaoContext";
import { AuthProviderCidade } from "./contexts/CidadeContext";
// import App from './views/pages/ListFornecedores/Perfil';

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer />
    <AuthProvider>
      <AuthProviderFornecedor>
        <AuthProviderCategoria>
          <AuthProviderProduto>
            <AuthProviderInsumo>
              <AuthProviderFornecedorProduto>
                <AuthProviderFornecedorInsumo>
                  <AuthProviderSolicitacoes>
                    <AuthProviderRegiao>
                      <AuthProviderCidade>
                        <App />
                      </AuthProviderCidade>
                    </AuthProviderRegiao>
                  </AuthProviderSolicitacoes>
                </AuthProviderFornecedorInsumo>
              </AuthProviderFornecedorProduto>
            </AuthProviderInsumo>
          </AuthProviderProduto>
        </AuthProviderCategoria>
      </AuthProviderFornecedor>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
