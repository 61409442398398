import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { toast } from "react-toastify";
import * as Yup from "yup";

import { AuthContextBuyPlan } from '../../../../../contexts/BuyPlanContext';
import getValidationErrors from '../../../../../utils/getValidationsErrors';
import Input from "../../components/Input"
import { estados as  All_states } from '../../util';
import { Forms } from "./styles";

export default function AddressForm() {
  const { requestBody, checkForm, handleNextForm, invalidForm, handleFillRequestBody, activeStep  } = useContext(AuthContextBuyPlan)
  const [hasZipCode, setHasZipCode] = useState(false);
  const formRef = useRef(null);

  const handleCheckForm = async (data) => {
    try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
            zipCode: Yup.string()
              .required("CEP é obrigatório!"),
						line1: Yup.string()
              .required("Endereço é obrigatório!"),
            city: Yup.string()
              .required("Cidade é obrigatório!"),
            state: Yup.string()
              .required("Estado é obrigatório!"),
            number: Yup.string()
              .required("Número é obrigatório!"),
        });

        await schema.validate(data, {
            abortEarly: false,
        });
        formRef.current?.setErrors({});

        const allData = formRef.current.getData();
        const send = {
          line1: allData.line1,
          line2: allData.line2,
          zipCode: allData.zipCode.replace(/\D/g, ''),
          city: allData.city,
          state: allData.state,
					number: allData.number,
        }
        handleFillRequestBody(send)
        handleNextForm()

    } catch (err) {
        console.log(err);
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        invalidForm()
    }
	};

	const searchZipCode = (data) => {
    if(data.replace(/\D/g, '').length === 8){
      setHasZipCode(false)
      fetch(`https://viacep.com.br/ws/${data}/json/`)
      .then(response=>{
        return response.json();
      }).then(result=>{
        if(result?.erro === true) {
          // toast.error("CEP inválido!");
          formRef?.current?.setFieldError('zipCode', 'CEP inválido!')
          setHasZipCode(false)
          return
        }
        formRef?.current?.setFieldError('zipCode', '')
        formRef.current.setData({
          zipCode: data.replace(/\D/g, ''),
          line1: result.logradouro,
          line2: result.complemento,
          city: result.localidade,
          state: result.uf,
          number: ''
        });
        setHasZipCode(true)
      }).catch(error=>{
        setHasZipCode(true)
        console.log(error)
      })
    }else{
      setHasZipCode(false)
    }
	};


  useEffect(()=>{
    if(requestBody?.billingAddress?.zipCode?.length > 0){
			formRef.current.setData({
				zipCode: requestBody.billingAddress.zipCode,
				line1: requestBody.billingAddress.line1,
				line2: requestBody.billingAddress.line2,
				city: requestBody.billingAddress.city,
				state: requestBody.billingAddress.state,
				number: requestBody.billingAddress.number
			});
			setHasZipCode(true)
    }
  },[])

	useEffect(()=>{
    if(checkForm === true && activeStep === 2){
      formRef.current.submitForm();
    }
  },[checkForm])



  return (
    <Fragment>
      <Forms ref={formRef} onSubmit={handleCheckForm}>
        <Typography variant="h6" gutterBottom>
          Endereço de cobrança
        </Typography>
        <Grid container spacing={0}>
          <Grid item sm={6}>
            <Input 
              required
              name="zipCode" 
              label="CEP*"
              key="cep"
              placeholder="CEP" 
              variant="standard"
              onChange={(e)=>{
								searchZipCode(e.target.value)
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} >
            <Input
              required
              id="line1"
              name="line1"
              key="line1"
              label="Endereço*"
              fullWidth
              variant="standard"
              disabled={!hasZipCode}
            />
          </Grid>
          <Grid item xs={12} >
            <Input
              required
              id="line2"
              name="line2"
              key="line2"
              label="Complemento"
              fullWidth
              variant="standard"
              disabled={!hasZipCode}
            />
          </Grid>
          <Grid item xs={12} >
            <Input
              required
              id="number"
              name="number"
              key="number"
              label="Número*"
              fullWidth
              variant="standard"
              disabled={!hasZipCode}
            />
          </Grid> 
          <Grid item xs={12}>
            <Input
              required
              id="city"
              name="city"
              key="city"
              label="Cidade*"
              fullWidth
              variant="standard"
              disabled={!hasZipCode}
            />
          </Grid>
          <Grid item xs={12} >
            <Input
              id="state"
              name="state"
              key="state"
              label="Estado*"
              fullWidth
              variant="standard"
              disabled={!hasZipCode}
							onChange={(e)=>{
								formRef?.current?.setFieldValue('state', e.target.value.substring(0,2).toUpperCase())
								if(e.target.value.length >= 1){
									const result = All_states.filter(fitem=> fitem === e.target.value.substring(0,2).toUpperCase())
									if(result.length === 0) formRef?.current?.setFieldError('state', 'Estado inválido!')
									else formRef?.current?.setFieldError('state', '')
								}
              }}
            />
          </Grid>
        </Grid>
      </Forms>
    </Fragment>
  );
}