import React, { useContext, useRef, useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import * as Yup from "yup";
import Checkbox from '@mui/material/Checkbox';
import { toast } from "react-toastify";
import api from "../../../services/api";

import { Container, Forms, Body } from "./styles";
import getValidationErrors from "../../../utils/getValidationsErrors";
import { AuthContextFornecedor } from "../../../contexts/FornecedorContext";
import { useHistory } from "react-router";

import { RiMailLine, RiLock2Line, RiBuilding4Line } from "react-icons/ri";
import { yellow } from "@mui/material/colors";


const schema = Yup.object().shape({
    nome_estabelecimento: Yup.string()
        .required("Nome do Estabelecimento obrigatório"),
    email: Yup.string()
        .required("e-mail obrigatório")
        .email("O email precisa ser válido"),
    senha: Yup.string()
        .required("Senha obrigatória")
        .min(6, "A senha precisa ter no mínimo 6 caracteres")
        .max(30, "A senha precisa ter no máximo 30 caracteres"),
});

function SignUp() {
    const formRef = useRef(null);
    const { createFornecedor } = useContext(AuthContextFornecedor);
    // const { createUser } = useContext(AuthContext);
    const History = useHistory();
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});


            await schema.validate(data, {
                abortEarly: false,
            });

            formRef.current?.setErrors({});

            data.telefone = "";
            data.cidade = "";
            data.endereco = "";
            data.regiao = "";
            data.cidades_atuacao = "";

            if (checked) {
                if (await createFornecedor(data))
                    History.push('/dashboard')
            }
            toast.error("Aceitar, Termo de uso e Politica de privacidade!");

        } catch (err) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    return (
        <Container>
            <Body>
                <h1>Criar uma conta</h1>
                <span>Faça o cadastro para continuar</span>
                <Forms ref={formRef} onSubmit={handleSubmit}>
                    <div>
                        <span>Nome do Estabelecimento</span>
                        <Input name="nome_estabelecimento" placeholder="Nome do estabelecimento"
                            icon={RiBuilding4Line}
                        />
                    </div>
                    <div>
                        <span>Email</span>
                        <Input name="email" placeholder="usuário@teste.com"
                            icon={RiMailLine}
                        />
                    </div>
                    <div>
                        <span>Senha</span>
                        <Input name="senha" placeholder="Digite sua senha" type="password"
                            icon={RiLock2Line}
                        />
                    </div>
                    <Button style={{ fontSize: '20px' }}>Cadastrar</Button>
                </Forms>
                <div>
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                            color: yellow[800],
                            '&.Mui-checked': {
                              color: yellow[700],
                            },
                          }}
                    />
                    <a href={`${api.defaults.baseURL}/termo_de_uso_campo_invest_web.pdf`}>Termo de uso e Politica de privacidade</a>
                </div>
                <div>
                    Já possui uma conta? <a href="/">Entrar</a>
                </div>
                <div>
                    <a href="/">Cancelar</a>
                </div>
            </Body>
        </Container>
    )
}

export default SignUp;