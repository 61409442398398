import styled from "styled-components";
import colors from "../../../../../styles/colors";
import fonts from "../../../../../styles/fonts";

export const ContainerCircularProgress  = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  svg {
    color: ${colors.green}
  }
`;
