import React, { useEffect, useState } from "react";

import Button from "../../../../../../components/Button";
import InsumosCategoriaRow from "../../../../../../components/InsumosCategoriaRow";
import api from "../../../../../../services/api";
import { Body, Container, Content, Image } from "./styles";

function ModalInsumo({ setShowModalInsumo, user, setInsumosSelecionados }) {

    const [insumos, setInsumos] = useState([]);
    const [selecionado, setSelecionado] = useState();
    const insumosCategoria = user.fk_insumos;

    const loadingInsumos = async () => {
        try {
            const { data } = await api.get(`/insumo_nome`);
            setInsumos(data);
        } catch (err) {
            console.log(err);
        }
    };

    async function salvarInsumos() {
        setInsumosSelecionados(selecionado);
    };

    useEffect(() => {
        loadingInsumos();
    }, []);

    useEffect(() => {
        setSelecionado(insumosCategoria?.map(item => item.nome))
    },[]);

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Insumosa</h1>
                    <Image />
                </div>
                <p>Selecione os Insumos</p>
                <Content>
                    <div>
                        {insumos?.map((data) => (
                            <InsumosCategoriaRow
                                key={data.id}
                                insumos={data}
                                setShowModalInsumo={setShowModalInsumo}
                                setSelecionado={setSelecionado}
                                selecionado={selecionado}
                            />
                        ))}
                    </div>
                </Content>
                <div id="button" style={{ backgroundColor: "#FFF" }}>
                    <Button style={{ fontSize: '18px', width: "30%" }}
                        onClick={() => { salvarInsumos(); setShowModalInsumo(false) }}
                    >
                        Salvar
                    </Button>
                </div>
                <a href="#" onClick={() => setShowModalInsumo(false)}>
                    Voltar
                </a>
            </Body>
        </Container>
    );
}

export default ModalInsumo;