import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { BiFontSize } from "react-icons/bi";
import { RiFilterLine, RiSearchLine } from "react-icons/ri";
import Select from "react-select";

import EnviadosAdminRow from "../../../components/EnviadosAdminRow";
import api from "../../../services/api";
import ModalEnvioOrcamentoAdmin from "../SolicitacoesAdmin/ModalEnvioOrcamentoAdmin";
import ModalProvider from "./ModalProvider";
import { Body, Buscar, Container, Content, Filtros } from "./styles";

function Enviados() {
  const [cor, setCor] = useState(0);
  const [enviados, setEnviados] = useState([]);
  const [showModalEnvioOrcamento, setShowModalEnvioOrcamento] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [showModalProvider, setShowModalProvider] = useState(false);
  const [nomeUser, setNomeUser] = useState(null);
  const [cidade, setCidade] = useState([]);
  const [selectedCidade, setSelectedCidade] = useState("");
  const [selectedRegiao, setSelectedRegiao] = useState("");
  const [regiao, setRegiao] = useState([]);
  const [buscar, setBuscar] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [registros, setRegistros] = useState();

  const loadingEnviados = async () => {
    try {
      const { data } = await api.get(
        `/orcamentos_enviados?page=${page}&search=${buscar}&cidade=${selectedCidade}&regiao=${selectedRegiao}`
      );
      const nomes = data.content.map((item) => ({
        label: item.fk_user.nome,
        value: item.fk_user.nome,
      }));
      setEnviados(data.content);
      setTotalPages(data.totalPages)
      setNomeUser(nomes);
      setRegistros(data.registros);
    } catch (err) {
      console.log(err);
    }
  };

  const getDataFilters = async () => {
    try {
      const { data } = await api.get(`fornecedor_filtro`);
      const cidade = [];
      data?.map((item) => {
        if (!cidade.includes(item.cidade)) {
          cidade.push(item.cidade);
        }
      });
      setCidade(
        cidade?.map((item) => ({
          value: item,
          label: item,
        }))
      );
    } catch (err) {
      console.log(err);
    }

    try {
      const { data } = await api.get(`/regiao_all`);
      let regiao = [];
      data?.map((item) => {
        if (!regiao.includes(item.nome)) {
          regiao.push(item);
        }
        setRegiao(
          regiao?.map((item) => ({
            value: item.nome,
            label: item.nome,
          }))
        );
      });
    } catch (err) {
      console.log(err);
    }
  };

  const resetFilter = () => {
    setPage(0)
    setSelectedRegiao("");
    setSelectedCidade("");
    loadingEnviados();
  };

  useEffect(() => {
    getDataFilters();
  }, []);


  useEffect(() => {
    loadingEnviados();
  }, [selectedRegiao, page, buscar]);

  const handleOrdenar = () => {
    if (cor === 1) {
      const nome = enviados?.sort((a, b) =>
        a.nome_comercial > b.nome_comercial ? 1 : -1
      );
      setEnviados([...nome]);
    }
    if (cor === 2) {
      const nome = enviados?.sort((a, b) =>
        a.fk_fornecedorOrcamentos[0].fk_fornecedor.nome_estabelecimento >
        b.fk_fornecedorOrcamentos[0].fk_fornecedor.nome_estabelecimento
          ? 1
          : -1
      );
      setEnviados([...nome]);
    }
    if (cor === 3) {
      const nome = enviados?.sort((a, b) =>
        a.fk_fornecedorOrcamentos[0].fk_fornecedor.endereco >
        b.fk_fornecedorOrcamentos[0].fk_fornecedor.endereco
          ? 1
          : -1
      );
      setEnviados([...nome]);
    }
    if (cor === 4) {
      const nome = enviados?.sort((a, b) =>
        a.fk_fornecedorOrcamentos[0].valor_orcamento >
        b.fk_fornecedorOrcamentos[0].valor_orcamento
          ? 1
          : -1
      );
      setEnviados([...nome]);
    }
    if (cor === 5) {
      const nome = enviados?.sort((a, b) =>
        a.fk_fornecedorOrcamentos[0].updatedAt >
        b.fk_fornecedorOrcamentos[0].updatedAt
          ? 1
          : -1
      );
      setEnviados([...nome]);
    }
  };

  useEffect(() => {
    handleOrdenar();
  }, [cor]);

  const customStyles = {
    control: () => ({
      width: 150,
      borderRadius: "8px",
      height: "35px",
      // backgroundColor: 'yellow'
    }),
    menuList: (provided) => ({
      ...provided,
      width: 150,
      display: "flex",
      flexDirection: "column",
      fontSize: 15,
    }),
    valueContainer: (provider) => ({
      ...provider,
      width: "100%",
      height: "100%",
    }),
  };

  return (
    <>
      {showModalEnvioOrcamento && (
        <ModalEnvioOrcamentoAdmin
          setShowModalEnvioOrcamento={setShowModalEnvioOrcamento}
          user={userSelected}
          loading={loadingEnviados}
        />
      )}
      {showModalProvider && (
        <ModalProvider
          setShowModalProvider={setShowModalProvider}
          fornecedor={userSelected}
        />
      )}
      <Container>
        <Content>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1>Enviados</h1>
            <Buscar>
              <input
                name="buscar"
                placeholder="buscar"
                onChange={(e) =>{ 
                  setPage(0)
                  setBuscar(e.target.value)
                }}
              />
              <div
                id="button"
                type="button"
                // onClick={() => loadingEnviadosNome()}
              >
                <RiSearchLine size={20} />
              </div>
            </Buscar>
          </div>
          <div>
            <p>Mais de {registros} orçamentos enviados</p>
          </div>
        </Content>
        <Filtros>
          {/* <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', marginRight: '25px' }}>
            <Select
              isMulti={true}
              // onChange={value => setSelectedCultura(value.label)}
              options={nomeUser}
              placeholder={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <RiFilterLine style={{ display: 'flex' }} size={25} />
                  <p style={{ fontSize: '12px' }}>Filtrar por Nome</p>
                </div>
              }
              maxMenuHeight={160}
              menuPlacement='auto'
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'rgb(12, 157, 164, 0.4)',
                  primary: '#20655F',
                },
              })}
            />
          </section> */}
          <section
            id="select"
            style={{
              boxShadow: "0px 5px 6px rgba(0,0,0,0.6)",
              marginRight: "25px",
            }}
          >
            <Select
              value={
                selectedRegiao != ""
                  ? { label: selectedRegiao, value: "" }
                  : null
              }
              isMulti={false}
              onChange={(value) =>{ 
                setPage(0)
                setSelectedRegiao(value.label)}}
              options={regiao}
              placeholder={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RiFilterLine style={{ display: "flex" }} size={25} />
                  <p style={{ fontSize: "12px" }}>Filtrar por Região</p>
                </div>
              }
              maxMenuHeight={160}
              menuPlacement="auto"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(12, 157, 164, 0.4)",
                  primary: "#20655F",
                },
              })}
            />
          </section>

          <button
            style={{
              cursor: "pointer",
              color: "#20655F",
              fontWeight: "bold",
              padding: "8px 8px",
              borderRadius: "5px",
              marginLeft: "50px",
              fontSize: "12px",
              boxShadow: "0px 5px 6px rgba(0,0,0,0.6)",
            }}
            onClick={resetFilter}
          >
            Apagar Filtros
          </button>
        </Filtros>
        <Filtros>
          {/* <Body>
            <p>Nome do cliente</p>
          </Body> */}
          <header>
            <p
              type="button"
              onClick={() => {}}
              style={{ color: cor === 1 && "#F8AA27" }}
            >
              <BiFontSize size={25} />
              Nome do cliente
            </p>
          </header>
          <Body>
            <p
              type="button"
              onClick={() => setCor(1)}
              style={{ color: cor === 1 && "#F8AA27" }}
            >
              <BiFontSize size={25} />
              Produto
            </p>
          </Body>
          {/* <Body>
            <p type="button" onClick={() => setCor(4)} style={{ color: cor === 4 && "#F8AA27" }}>
              <BiFontSize size={25} />Preço</p>
          </Body> */}
          <Body>
            <p
              type="button"
              onClick={() => setCor(5)}
              style={{ color: cor === 5 && "#F8AA27" }}
            >
              <BiFontSize size={25} />
              Data
            </p>
          </Body>

          <Body></Body>
        </Filtros>
        {enviados?.map((data, index) => (
          <EnviadosAdminRow
            key={index}
            enviados={data}
            setShowModalEnvioOrcamento={setShowModalEnvioOrcamento}
            setUserSelected={setUserSelected}
            setShowModalProvider={setShowModalProvider}
            respostas
          />
        ))}
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={(event, value) => setPage(value - 1)}
          shape="rounded"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        />
      </Container>
    </>
  );
}

export default Enviados;
