
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/UserContex";
import { AuthProviderFornecedor } from "./contexts/FornecedorContext";
import { AuthProviderCategoria } from "./contexts/CategoriaContext";
import { AuthProviderProduto } from "./contexts/ProdutoContext";
import { AuthProviderInsumo } from "./contexts/InsumoContext";
import { AuthProviderFornecedorProduto } from "./contexts/FornecedorProdutosContext";
import { AuthProviderFornecedorInsumo } from "./contexts/FornecedorInsumosContext";
import { AuthProviderSolicitacoes } from "./contexts/SolicitacoesContext";
import { AuthProviderRegiao } from "./contexts/RegiaoContext";
import { AuthProviderCidade } from "./contexts/CidadeContext";
import {AuthProviderBuyPlan} from './contexts/BuyPlanContext';
import { routes, PrivateRoute } from "./routes/private.routes";
import Dashboard from "./views/layouts/Dashboard";

function App() {

  return (
    <>
      <AuthProviderCidade>
        <AuthProviderRegiao>
          <AuthProviderSolicitacoes>
            <AuthProviderFornecedorProduto>
              <AuthProviderFornecedorInsumo>
                <AuthProviderInsumo>
                  <AuthProviderProduto>
                    <AuthProviderCategoria>
                      <AuthProviderFornecedor>
                        <AuthProviderBuyPlan>
                        <AuthProvider>
                          <Router>
                            <Switch>
                              {routes?.public?.map((route, index) => (
                                <Route
                                  key={index}
                                  path={route.path}
                                  exact
                                  component={route.component}
                                />
                              ))}
                                <PrivateRoute path="/dashboard" component={Dashboard} />
                            </Switch>
                          </Router>
                        </AuthProvider>
                        </AuthProviderBuyPlan>
                      </AuthProviderFornecedor>
                    </AuthProviderCategoria>
                  </AuthProviderProduto>
                </AuthProviderInsumo>
              </AuthProviderFornecedorInsumo>
            </AuthProviderFornecedorProduto>
          </AuthProviderSolicitacoes>
        </AuthProviderRegiao>
      </AuthProviderCidade>
    </>
  );
}

export default App;
