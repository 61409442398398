import React, { createContext } from "react";
import { toast } from "react-toastify";
import api from "../services/api";
import { getId } from "../services/auth";

const AuthContextFornecedorInsumo = createContext({});

const AuthProviderFornecedorInsumo = ({ children }) => {

    /* Função para carregar Insumos */
    const loadingFornecedorInsumos = async (setFornecedorInsumos, produtoFornecedor, categoriaFornecedor) => {
        try {
            const id = getId();
            const { data } = await api.get(`/fornecedor/${id}/insumo?insumo=${produtoFornecedor}&categoriaInsumo=${categoriaFornecedor}`);
            setFornecedorInsumos(data);
        } catch (err) {
            console.log(err);
        }
    };

    const loadingFornecedorInsumosFiltro = async (setFornecedorInsumosFiltro) => {
        try {
            const id = getId();
            const { data } = await api.get(`/fornecedor_filtro/${id}/insumo`);
            setFornecedorInsumosFiltro(data)
        } catch (err) {
            console.log(err);
        }
    };

    /* Função para delatar insumo */
    const deleteFornecedorInsumo = async (id, userId) => {
        try {
            const data = await api.delete(`/fornecedor/${userId}/insumo/${id}`, { data: { insumo_id: id } });
            toast.success(data.data.message);
            return data;
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
            return false;
        }
    };

    /* Função para atualiza usuário */
    //   const updateProduto = async (data, id) => {
    //     try {
    //       const resp = await api.put(`/produto/${id}`, data);
    //       toast.success(resp?.data?.message);
    //       return resp.data;
    //     } catch (err) {
    //       console.log(err);
    //       toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

    //       return false;
    //     }
    //   };

    /* Função para registrar insumos para um fornecedor */
    const createFornecedorInsumo = async (selected, id) => {
        try {
            const resp = await api.post(`/fornecedor/${id}/insumo`, { insumo_id: selected });
            !resp?.data?.id ?
                toast.error(resp?.data?.message) :
                toast.success("Cadastrado com sucesso!");
            return resp.data;
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
            return false;
        }
    };

    /* Função para registrar insumos para um fornecedor */
    /* Função para todos */
    const createFornecedorInsumos = async (notSelectedInsumos, id, categoria) => {
        try {
            const resp = await api.post(`/fornecedor/${id}/insumos?categoriaInsumo=${categoria}`, { insumo_id: notSelectedInsumos });
            !resp?.data?.id ?
                toast.error(resp?.data?.message) :
                toast.success("Cadastrado com sucesso!");
            return resp.data;
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
            return false;
        }
    };

    return (
        <AuthContextFornecedorInsumo.Provider
            value={{
                deleteFornecedorInsumo,
                loadingFornecedorInsumos,
                // updateProduto,
                createFornecedorInsumo,
                createFornecedorInsumos,
                loadingFornecedorInsumosFiltro
            }}
        >
            {children}
        </AuthContextFornecedorInsumo.Provider>
    );
};

export { AuthContextFornecedorInsumo, AuthProviderFornecedorInsumo };