import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { BiFontSize } from "react-icons/bi";
import { FaSortAlphaDown, FaSortNumericDown } from "react-icons/fa";
import { RiOrderPlayLine, RiSearchLine } from "react-icons/ri";

import EnviadosRow from "../../../components/EnviadosRow";
import api from "../../../services/api";
import { getId } from "../../../services/auth";
import { Body, Buscar, Container, Content, Filtros } from "./styles";

function Enviados() {
  const [cor, setCor] = useState(0);
  const [enviados, setEnviados] = useState([]);
  const [enviadosSearch, setEnviadosSearch] = useState([]);
  const [paginas, setPaginas] = useState(0);
  // const [totalPages, setTotalPages] = useState();
  // const [registros, setRegistros] = useState();
  const [buscar, setBuscar] = useState("");


  const loadingEnviados = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/orcamentos_enviados/${id}`);
      setEnviados(data.content);
      // setTotalPages(data.totalPages);
      // setRegistros(data.registros);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadingEnviados();
  }, []);

  const handleBuscar = (buscar) => {
    if (buscar) {
      const busca = enviados?.filter((item) =>
        item.nome_comercial.toLowerCase().includes(buscar.toLowerCase())
      );
      setEnviadosSearch([...busca]);
    }
  };

  // const handleChange = (event, value) => {
  //     let valorPage = value - 1;
  //     setPage(valorPage);
  // };

  const handleOrdenar = () => {
    if (cor === 1) {
      if (buscar === "") {
        const nome = enviados?.sort((a, b) =>
          a.nome_comercial > b.nome_comercial ? 1 : -1
        );
        setEnviados([...nome]);
      } else {
        const nome = enviadosSearch?.sort((a, b) =>
          a.nome_comercial > b.nome_comercial ? 1 : -1
        );
        setEnviadosSearch([...nome]);
      }
    }

    if (cor === 2) {
      if (buscar === "") {
        const nome = enviados?.sort((a, b) =>
          a.fk_fornecedorOrcamentos[0].valor_orcamento >
          b.fk_fornecedorOrcamentos[0].valor_orcamento
            ? 1
            : -1
        );
        setEnviados([...nome]);
      } else {
        const nome = enviadosSearch?.sort((a, b) =>
          a.fk_fornecedorOrcamentos[0].valor_orcamento >
          b.fk_fornecedorOrcamentos[0].valor_orcamento
            ? 1
            : -1
        );
        setEnviadosSearch([...nome]);
      }
    }

    if (cor === 3) {
      if (buscar === "") {
        const nome = enviados?.sort((a, b) =>
          a.fk_fornecedorOrcamentos[0].updatedAt >
          b.fk_fornecedorOrcamentos[0].updatedAt
            ? 1
            : -1
        );
        setEnviados([...nome]);
      } else {
        const nome = enviadosSearch?.sort((a, b) =>
          a.fk_fornecedorOrcamentos[0].updatedAt >
          b.fk_fornecedorOrcamentos[0].updatedAt
            ? 1
            : -1
        );
        setEnviadosSearch([...nome]);
      }
    }
  };

  useEffect(() => {
    handleOrdenar();
  }, [cor]);

  const enviadosPaginado =
    buscar !== ""
      ? enviadosSearch?.slice(paginas * 10, paginas * 10 + 10)
      : enviados?.slice(paginas * 10, paginas * 10 + 10);
  const totalPages =
    buscar !== ""
      ? Math.ceil(enviadosSearch?.length / 10)
      : Math.ceil(enviados?.length / 10);

  return (
    <>
      <Container>
        <Content>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <div>
                            <p> <RiOrderPlayLine size={25} />Lista</p>
                        </div> */}
            <Buscar style={{ width: "30%" }}>
              <input
                name="buscar"
                placeholder="buscar"
                onChange={(e) => {
                  handleBuscar(e.target.value);
                  setBuscar(e.target.value);
                }}
              />
              <div id="button" type="button" onClick={() => handleBuscar()}>
                <RiSearchLine size={20} />
              </div>
            </Buscar>
            {/* <Filtro>
                            <p><RiFilterLine size={25} /> Filtrar por data</p>
                        </Filtro> */}
          </div>
        </Content>
        <Filtros>
          {/* <Body>
                    </Body> */}
          <header>
            <p
              type="button"
              onClick={() => {
                setCor(1);
              }}
              style={{ color: cor === 1 && "#F8AA27" }}
            >
              <BiFontSize size={25} />
              Produto
            </p>
          </header>
          {/* <Body>
                        <p type="button" onClick={() => setCor(2)} style={{ color: cor === 2 && "#F8AA27" }} >
                            <BiFontSize size={25} />Endereço</p>
                    </Body> */}
          <Body>
            <p
              type="button"
              onClick={() => setCor(2)}
              style={{ color: cor === 2 && "#F8AA27" }}
            >
              <BiFontSize size={25} />
              Preço
            </p>
          </Body>
          <Body>
            <p
              type="button"
              onClick={() => setCor(3)}
              style={{ color: cor === 3 && "#F8AA27" }}
            >
              <BiFontSize size={25} />
              Data
            </p>
          </Body>
          <Body>
            {/* <p type="button" onClick={() => setCor(5)} style={{ color: cor === 5 && "#F8AA27" }}> */}
            <p type="button">{/* <BiFontSize size={25} /> */}</p>
          </Body>
          <Body>
            {/* <p type="button" onClick={() => setCor(5)} style={{ color: cor === 5 && "#F8AA27" }}> */}
            <p type="button">
              {/* <BiFontSize size={25} /> */}
              Ranking Final
            </p>
          </Body>
        </Filtros>
        {enviadosPaginado?.map((data) => (
          <EnviadosRow key={data.id} enviados={data} />
        ))}
        <Pagination
          count={totalPages}
          page={paginas + 1}
          onChange={(event, value) => setPaginas(value - 1)}
          shape="rounded"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        />
      </Container>
    </>
  );
}

export default Enviados;
