import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import {
  Container,
  Input,
  InputContainer,
  Label,
  LabelLine
} from './style';

const InputComponent = ({ name, label, ...rest }) => {
  const inputRef = useRef();
  const { error, fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <LabelLine>
        {label && (<Label htmlFor={name}>{label}</Label>)}
      </LabelLine>
      <InputContainer>
        <Input
          name={name}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />
      </InputContainer>
      {error && <span className="error">{error}</span>}
    </Container>
  );
}

export default InputComponent;
