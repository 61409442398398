import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { Fragment, useContext } from 'react';
import { IoCheckmarkCircleOutline, IoCheckmarkDoneOutline, IoRemoveCircleOutline } from "react-icons/io5";

import { AuthContextBuyPlan } from '../../../../../contexts/BuyPlanContext';
import { Button, DivIcon, DivRow, DivText, PlanosBody, PlanosContainer, PlanosHeader } from "./styles";

export default function AddressForm() {
	const {
		handleNext,
		handleFillRequestBody,
		plans,
		statistics,
		handleFreePlan,
		handleUpgradeSubscription,
		handleCancelSubscription
	} = useContext(AuthContextBuyPlan)

	const renderRow = (text, index, item) => {
		if (item === 2) {
			return (
				<DivRow>
					<DivIcon>
						{
							(index === 1 || index === 2) ?
								<IoRemoveCircleOutline size={20} style={{ marginLeft: 15 }} />
								:
								<IoCheckmarkCircleOutline size={20} style={{ marginLeft: 15 }} />
						}
					</DivIcon>
					<DivText>
						<span> {text}</span>
					</DivText>
				</DivRow>
			)
		}

		if (item === 3) {
			return (
				<DivRow>
					<DivIcon>
						{
							(index === 3) ?
								<IoRemoveCircleOutline size={20} style={{ marginLeft: 15 }} />
								:
								<IoCheckmarkCircleOutline size={20} style={{ marginLeft: 15 }} />
						}
					</DivIcon>
					<DivText>
						<span> {text}</span>
					</DivText>
				</DivRow>
			)
		}

		return (
			<DivRow>
				<DivIcon>
					<IoCheckmarkCircleOutline size={20} style={{ marginLeft: 15 }} />
				</DivIcon>
				<DivText>
					<span> {text}</span>
				</DivText>
			</DivRow>
		);
	}

	// "plans": [{"id": 1,"name": "Teste Grátis 7 dias",},{"id": 2,"name": "Campo",},{"id": 3,"name": "Campo +",}],
	const renderCancelButton = (props) => {
		const activePaidPlan = statistics.allValidPlanStatus.filter(fitem => fitem.plan > 1).length > 0
		const ids = statistics.allValidPlanStatus.map(object => {
			return object.id;
		});
		const maxIds = Math.max(...ids);

		const result = statistics.allValidPlanStatus.filter(fitem => fitem.plan === props.id)
		const isAtive = result.length > 0 && result[0].status_subscription === 'active'
		const isPaid = result.length > 0 && (result[0].status_invoice === 'paid' || result[0].status_invoice === 'pending')

		if (props.price === 0) return false
		if (isAtive === false) return false


		if (result.length > 0 && isAtive && isPaid) {
			return true
		}
		if (result.length > 0 && isPaid === false) {
			return true
		}

		if (props.price === 0) {
			if (activePaidPlan) {
				return true
			}
			return result.length > 0
		} else {
			if (activePaidPlan) {
				return !(props.id < maxIds)
			}
			return false
		}
	}
	const disableButton = (props) => {
		const activePaidPlan = statistics.allValidPlanStatus.filter(fitem => fitem.plan > 1).length > 0
		const ids = statistics.allValidPlanStatus.map(object => {
			return object.id;
		});
		const maxIds = Math.max(...ids);

		const result = statistics.allValidPlanStatus.filter(fitem => fitem.plan === props.id)
		const isAtive = result.length > 0 && result[0].status_subscription === 'active'
		const isPaid = result.length > 0 && (result[0].status_invoice === 'paid' || result[0].status_invoice === 'pending')


		if (result.length > 0 && isAtive && isPaid) {
			if (props.price === 0) return true
			return true
		}
		if (result.length > 0 && isPaid === false) {
			return true
		}

		if (props.price === 0) {
			if (activePaidPlan) {
				return true
			}
			return result.length > 0
		} else {
			if (activePaidPlan) {
				const text = renderTextButton(props)
				if (text === '') return true
				if (text === 'Upgrade') return false
				if (text === 'Selecionar') return false
				return !(props.id < maxIds)
			}
			return false
		}
	}
	const renderTextButton = (props) => {
		const activePaidPlan = statistics.allValidPlanStatus.filter(fitem => fitem.plan > 1).length > 0
		const ids = statistics.allValidPlanStatus.map(object => {
			return object.plan;
		});
		const maxIds = Math.max(...ids);

		const result = statistics.allValidPlanStatus.filter(fitem => fitem.plan === props.id)
		const isAtive = result.length > 0 && result[0].status_subscription === 'active'
		const isPaid = result.length > 0 && (result[0].status_invoice === 'paid' || result[0].status_invoice === 'pending')

		if (props.id === 3) {
			return 'Direcionar'
		}

		if (props.price !== 0 && result.length > 0 && isAtive === false && isPaid) {
			return 'Ativo e Cancelado'
		}
		if (result.length > 0 && isPaid) {
			return 'Ativo'
		}
		if (result.length > 0 && isPaid === false) {
			return 'Falha'
		}
		if (props.price === 0) {
			return activePaidPlan ? '' : 'Selecionar'
		}
		if (activePaidPlan) {
			if (props.id > maxIds) {
				if (props.id === 3) {
					const id_2_is_canceled = statistics.allValidPlanStatus.filter(fitem => fitem.plan === 2 && fitem.status_subscription === 'canceled').length > 0
					if (id_2_is_canceled) return 'Selecionar'
					else return 'Upgrade'
				}
				return ''
			}
			else return 'Selecionar'
		} else {
			return 'Selecionar'
		}
	}

	console.log(plans)

	return (
		<Fragment>
			<Typography variant="h6" gutterBottom>
				Planos
			</Typography>
			<Grid container spacing={3}>
				{statistics && plans && plans
					.map((item) => {
						const textButton = renderTextButton(item)
						const cancelButton = renderCancelButton(item)
						const disableBTN = disableButton(item)

						console.log(textButton)
						return (
							<Grid item xs={12} sm={6} key={`plan-${item.id}`}>
								<PlanosContainer
									renderTextButton={textButton !== ''}
									renderCancelButton={cancelButton}
									key={`plans-${item.id}`}
								>
									<PlanosHeader backgroundColor={item.color}>
										<span> {item.name} </span>
									</PlanosHeader>
									<PlanosBody>
										{item.list.map((row, index) => {
											return renderRow(row, index, item.id)
										})}
										{textButton !== '' &&
											<Button
												disabled={false}
												onClick={() => {
													if (textButton === 'Upgrade') {
														handleUpgradeSubscription(item.id)
													} else if (textButton === 'Direcionar') {
														window.open('https://wa.me/5534999238977')
													} else if (textButton === 'Selecionar') {
														if (item.id === 1) { //free
															handleFreePlan(item.id)
														} else {
															handleFillRequestBody({ plan_id: item.id, description: item.name, price: item.price })
															handleNext()
														}
													}
												}}
											>
												{textButton}
											</Button>
										}
										{cancelButton &&
											<Button onClick={() => { handleCancelSubscription() }}>
												Cancelar
											</Button>
										}
									</PlanosBody>
								</PlanosContainer>
							</Grid>
						)
					})}
			</Grid>
		</Fragment>
	);
}