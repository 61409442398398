import React, { useRef, useContext, useState, useEffect } from "react";
import * as Yup from "yup";
import { AuthContextCidade } from "../../../../../contexts/CidadeContext";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import getValidationErrors from "../../../../../utils/getValidationsErrors";
import api from "../../../../../services/api";
import Select from "react-select";
import { toast } from 'react-toastify';

import { Container, Forms, Body, Content, Column, Image } from "./styles";

function ModalCreateCidade({ setShowModalCreate, loading }) {
    const formRef = useRef(null);
    const { createCidade } = useContext(AuthContextCidade);

    const [regiao, setRegiao] = useState([]);
    const [selectedCidades, setSelectedCidades] = useState();

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                nome: Yup.string()
                    .required("Nome Obrigatório!")
            });

            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});

            // data.categorias = data.categorias.split(/(,[ ]?)/g);
            // data.categorias = selectedCidades.map(item => item.label);
            data.regiao = selectedCidades?.map(item => item.label);
            
            if (data.regiao) {
                await createCidade(data);
                await loading();
                setShowModalCreate(false);
                window.location.reload();
            } else {
                toast.error("Selecione uma região");
                return false;
            }

        } catch (err) {
            console.log(err);
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    const loadingRegiao = async () => {
        try {
            const { data } = await api.get('/regiao_all');
            setRegiao(data?.map(item => ({
                value: item.id, label: item.nome
            })));

        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadingRegiao();
    }, []);

    return (
        <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Adiciona Cidade</h1>
                    <Image />
                </div>
                <p>Insira os dados referente a cidade</p>
                <Forms ref={formRef} onSubmit={handleSubmit}>
                    <Content>
                        <Column>
                            <div style={{ paddingRight: '10px' }}>
                                <span>Nome</span>
                                <Input name="nome" placeholder="Nome da Cidade"
                                // icon={RiBuilding4Line}
                                />
                            </div>
                            <div style={{ paddingRight: '10px' }}>
                                <span>Região Vinculada</span>
                                <Select
                                    isMulti={true}
                                    onChange={value => setSelectedCidades(value)}
                                    options={regiao}
                                    placeholder='Selecione'
                                    maxMenuHeight={160}
                                    menuPlacement='auto'
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'rgb(12, 157, 164, 0.4)',
                                            primary: '#20655F',
                                        },
                                    })}
                                />
                            </div>
                        </Column>
                    </Content>
                    <div style={{ backgroundColor: "#FFF" }}>
                        <Button style={{ fontSize: '18px', width: "30%" }}>Salvar</Button>
                    </div>
                </Forms>
                <a href="#" onClick={() => setShowModalCreate(false)} style={{ marginBottom: '15px' }}>
                    Voltar
                </a>
            </Body>
        </Container>
    );
}

export default ModalCreateCidade;