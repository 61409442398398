import React, { useRef, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import { AuthContextCategoria } from "../../../../../contexts/CategoriaContext";
import getValidationErrors from "../../../../../utils/getValidationsErrors";
import ModalProduto from "./ModalProduto";
import ModalInsumo from "./ModalInsumo";

import { Container, Forms, Body, Content, Column, Image } from "./styles";

function ModalEdit({ setShowModalEdit, user, loading }) {
    const formRef = useRef(null);

    const { updateCategoria } = useContext(AuthContextCategoria);

    const [showModalProduto, setShowModalProduto] = useState(false);
    const [showModalInsumo, setShowModalInsumo] = useState();
    const [produtosSelecionados, setProdutosSelecionados] = useState();
    const [insumosSelecionados, setInsumosSelecionados] = useState();

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                nome: Yup.string()
                    .required("Nome Obrigatório"),
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});

            data.produtos = data.produtos.split(/(,[ ]?)/g);
            data.insumos = data.insumos.split(/(,[ ]?)/g);

            await updateCategoria(data, user?.id);
            loading();
            setShowModalEdit(false);
            window.location.reload();
        } catch (err) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };
    const produto = user.fk_produtos;
    const insumo = user.fk_insumos;


    const produtos = produto.map((data) => {
        return data.nome
    })
    const insumos = insumo.map((data) => {
        return data.nome
    })

    return (
        <>
            {showModalProduto &&
                (<ModalProduto
                    setShowModalProduto={setShowModalProduto}
                    user={user}
                    setProdutosSelecionados={setProdutosSelecionados}
                />)
            }
            {showModalInsumo &&
                (<ModalInsumo
                    setShowModalInsumo={setShowModalInsumo}
                    user={user}
                    setInsumosSelecionados={setInsumosSelecionados}
                />)
            }
            <Container>
                <Body>
                    <div>
                        <Image style={{ background: 'transparent' }} />
                        <h1>Editar Categoria</h1>
                        <Image />
                    </div>
                    <p>Insira os dados referentes a categoria</p>
                    <Forms ref={formRef} onSubmit={handleSubmit}
                        initialData={
                            {
                                ...user,
                                produtos: produtos.join(', '),
                                insumos: insumos.join(', '),
                            }}>
                        <Content>
                            <Column>
                                <div>
                                    <span>Nome</span>
                                    <Input name="nome" placeholder="Nome da Categoria"
                                    />
                                </div>
                                <div onClick={() => setShowModalProduto(true)} style={{ cursor: 'pointer' }}>
                                    <span>Produtos Vinculados</span>
                                    {produtosSelecionados ?
                                        <Input
                                            name="produtos"
                                            placeholder="Produtos Vinculados"
                                            onClick={() => setShowModalProduto(true)}
                                            style={{ cursor: 'pointer' }}
                                            value={produtosSelecionados}
                                        />
                                        :
                                        <Input
                                            name="produtos"
                                            placeholder="Produtos Vinculados"
                                            onClick={() => setShowModalProduto(true)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    }
                                </div>
                                <div>
                                    <span>Insumos Vinculados</span>
                                    {insumosSelecionados ?
                                        <Input
                                            name="insumos"
                                            placeholder="Insumos Vinculados"
                                            onClick={() => setShowModalInsumo(true)}
                                            style={{ cursor: 'pointer' }}
                                            value={insumosSelecionados}
                                        />
                                        :
                                        <Input
                                            name="insumos"
                                            placeholder="Insumos Vinculados"
                                            onClick={() => setShowModalInsumo(true)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    }
                                </div>
                            </Column>
                        </Content>
                        <div style={{ backgroundColor: "#FFF" }}>
                            <Button style={{ fontSize: '18px', width: "30%" }}>Salvar</Button>
                        </div>
                    </Forms>
                    <a href="#" onClick={() => setShowModalEdit(false)}>
                        Voltar
                    </a>
                </Body>
            </Container>
        </>
    );
}

export default ModalEdit;