import styled, { keyframes } from 'styled-components';
import colors from "../../../../../styles/colors";
import fonts from "../../../../../styles/fonts";

export const Container = styled.div`
  --input-min-height: 38px;
  margin: 1rem 0;

  .error {
    color: ${colors.error};
    font-size: 14px;
  }
`;

export const LabelLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  margin-bottom: 0.5em;
`;


export const Label = styled.label`
  color: ${colors.darKGray};
  display: block;
  font-size: 14px;
  // ${fonts[700]};
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Input = styled.input`
  display: flex;
  padding: 2px 32px 2px 8px;
  border: 1px solid  ${colors.darKGray};
  // min-width: 290px;
  transition: border-color 0.2s ease-out;
  border-radius: 4px;
  min-height: 38px;
  width: 100%;
  

  &:focus {
    border: 2px solid ${colors.darKGray};
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
    outline: none;
  }
  &[type="text"]::placeholder {  
    padding-left: 10px; 
  } 
`;