import React, { createContext } from "react";
import { toast } from "react-toastify";

import api from "../services/api";
import { getId, login } from "../services/auth";

const AuthContextSolicitacoes = createContext({});

const AuthProviderSolicitacoes = ({ children }) => {
  /* Função para carregar orcamento */
  const loadingSolicitacoes = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/orcamento/${id}`);
      return data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  /* Função para delatar orcamento */
  const deleteSolicitacoes = async (id) => {
    try {
      const { data } = await api.delete(`/orcamento/${id}`);
      toast.success(data.message);
      return data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

      return false;
    }
  };

  /* Função para atualiza orcamento */
  const updateSolicitacoes = async (
    data,
    id,
    fornecedorOrcamentos_id,
    checked,
    selectedInsumos,
    valor
  ) => {
    try {
      const valor_orcamento = data.valor;
      const observacao = data.observacao;
      const naoPossui = checked;
      const fornecedor_insumo = selectedInsumos.label;
      const fornecedor_id = getId();

      if (data.valor === "" && data.valor <= 0) {
        toast.error(`Digite um valor! ou "0" caso não possua o produto`);
        throw new Error('Digite um valor! ou "0" caso não possua o produto');
      }

      !valor && valor !== 0
        ? await api.post(`/fornecedor/${fornecedor_id}/orcamento/${id}`, {
            valor_orcamento: data.valor,
            observacao: data.observacao,
            naoPossui: checked,
            fornecedor_insumo: selectedInsumos.label,
          })
        : await api.put(
            `/fornecedorOrcamentos/${fornecedorOrcamentos_id}/orcamento/${id}`,
            {
              valor_orcamento: valor_orcamento,
              observacao: observacao,
              naoPossui: naoPossui,
              fornecedor_insumo: fornecedor_insumo,
            }
          );

      const resp = await api.put(`/orcamento/${id}`, data);
      toast.success(resp?.data?.message);
      // window.location.reload();

      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

      return false;
    }
  };

  /* Função para criar um novo orcamento */
  const createSolicitacoes = async (data) => {
    try {
      const resp = await api.post(`/orcamento`, data);
      toast.success("Criado com sucesso");
      return resp.data;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
      return false;
    }
  };

  return (
    <AuthContextSolicitacoes.Provider
      value={{
        deleteSolicitacoes,
        loadingSolicitacoes,
        updateSolicitacoes,
        createSolicitacoes,
      }}
    >
      {children}
    </AuthContextSolicitacoes.Provider>
  );
};

export { AuthContextSolicitacoes, AuthProviderSolicitacoes };
