import React, { useState, useEffect } from 'react';
import { RiArrowRightSLine } from "react-icons/ri";

import { Container, Buttons } from './styles';

const CulturaRow = ({ cultura, setCulturaSelecionada, culturaSelecionada, culturas }) => {

    const [checked, setChecked] = useState(false);

    // useEffect(() => {
    //     if(culturas.includes(cultura.nome)){
    //         setChecked(true)
    //     };
    // },[]);

    return (
        <>
            <Container style={{ background: checked && 'rgb(248, 170, 39, 0.2)' }}>
                <header>
                    <p id='p'>{cultura.nome}</p>
                </header>
                <Buttons>
                    <button style={{ color: "#20655F" }}
                        onClick={() => { setChecked(!checked); culturaSelecionada.push(cultura.nome); setCulturaSelecionada([...culturaSelecionada]) }}>
                        <RiArrowRightSLine size={30} />
                    </button>
                </Buttons>
            </Container>
        </>
    )
}

export default CulturaRow;
