import React, { useRef, useContext, useEffect, useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Container, Forms, Body } from './styles';
import getValidationErrors from "../../../utils/getValidationsErrors";
import { AuthContext } from "../../../contexts/UserContex";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useParams, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import Key from "../../../services/secret_key.json";
import api from "../../../services/api";
import { login } from "../../../services/auth"

import { RiMailLine, RiLock2Line } from "react-icons/ri";
import { toast } from "react-toastify";

function SignInUser() {
    const formRef = useRef(null);
    const { signIn } = useContext(AuthContext);
    const History = useHistory();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const token = params.get('token')
    const [user, setUser] = useState();

    const loadUser = async () => {
        if (token != undefined) {
            try {
                let bytes = CryptoJS.AES.decrypt(token.replaceAll(' ', '+'), Key["key_cryptoJS"]);
                let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                const id_user = decryptedData?.id_user;
                const tokenUser = decryptedData?.token;
                // console.log('id_user', id_user);
                // console.log('tokenUser', tokenUser);

                if (id_user) {
                    const { data } = await api.get(`/users/${id_user}`, {
                        headers: {
                            Authorization: `Bearer ${tokenUser}`
                        }
                    });
                    // setLog(JSON.stringify(data))
                    setUser(data);
                    login(tokenUser, data?.acesso, data?.id);
                }
            } catch (err) {
                toast.error("Por favor, confirmar login!");
                console.log('error', err);
                // setLog(JSON.stringify(err))
            }
        }
    };

    const buyPlan = () => {
        History.push('/dashboard/BuyPlan')
    }

    useEffect(() => {
        loadUser();
    }, [token]);

    useEffect(() => {
        if (user) {
            buyPlan()
        }
    }, [user]);

    // useEffect(() => {
    //     let bytes = CryptoJS.AES.decrypt(token, Key["key_cryptoJS"]);
    //     let originalText = bytes.toString(CryptoJS.enc.Utf8);
    //     setUserId(originalText)
    // }, []);


    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                login: Yup.string()
                    .required("e-mail obrigatório!"),
                // .email("O email precisa ser válido"),
                senha: Yup.string()
                    .required("Senha obrigatória!")
                    .min(6, "A senha precisa ter no mínimo 6 caracteres")
                    .max(30, "A senha precisa ter no máximo 30 caracteres"),
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});
            if (await signIn(data))
                History.push('/dashboard/BuyPlan')

        } catch (err) {
            console.log('erro:', err);
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    return (
        <Container>
            <Body>
                <h1>Bem vindo!</h1>
                <span> Faça o login para acessar seus dados </span>
                <Forms ref={formRef} onSubmit={handleSubmit} >
                    <div>
                        <span>E-mail</span>
                        <Input name="login" placeholder="usuário@teste.com" defaultValue="teste@email.com"
                            icon={RiMailLine}
                        />
                    </div>
                    <div>
                        <span>Senha</span>
                        <Input
                            name="senha" placeholder="Digite sua senha" type="password" defaultValue="123456"
                            icon={RiLock2Line}
                        />
                    </div>
                    <Button type='submit' style={{ fontSize: '20px' }}>Login</Button>
                </Forms>
            </Body>
        </Container >
    );
}

export default SignInUser;