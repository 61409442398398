import { addHours, format, formatDistance, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { RiCheckboxCircleLine, RiDeleteBin5Line, RiEditBoxFill } from "react-icons/ri";
import { toast } from "react-toastify";

import api from '../../services/api';
import { getId } from '../../services/auth';
import { getNivel } from '../../services/auth';
import { Body, Buttons, Container } from './styles';

const SolicitacoesRow = ({ solicitacoes, setShowModalDelete, setUserSelected, setShowModalEnvioOrcamento, paginas }) => {
    const [date, setDate] = useState();
    const [valor, setValor] = useState('');
    const [dataCreate, setDataCreate] = useState();
    const nivel = getNivel();

    const usuario = solicitacoes.fk_user;


    const CategoriaProduto = solicitacoes.fk_produto?.fk_categoria
    const CategoriaProdutos = CategoriaProduto?.map((data) => {
        return data.nome
    });

    const CategoriaInsumo = solicitacoes.fk_insumo?.fk_categorias
    const CategoriaInsumos = CategoriaInsumo?.map((data) => {
        return data.nome
    });

    const valorRespondido = solicitacoes?.fk_fornecedorOrcamentos;
    const valorRespondidos = valorRespondido?.map((data) => {
        return data.valor_orcamento
    });

    const fornecedor = valorRespondido?.map((item) => {
        return item.fk_fornecedor?.nome_estabelecimento
    });
    const fornecedorEndereco = valorRespondido?.map((item) => {
        return item.fk_fornecedor?.endereco
    });
    const fornecedorCidadesAtuacao = valorRespondido?.map((item) => {
        return item.fk_fornecedor?.cidades_atuacao
    });

    async function handleSubmit() {
        try {
            const id = getId();
            const orcamento_id = solicitacoes.id;

            if (valor >= 0 && valor != '') {
                await api.post(`/fornecedor/${id}/orcamento/${orcamento_id}`, { valor_orcamento: valor });
                window.location.reload();
            } else {
                toast.error(`Digite um valor! ou "0" caso não possua o produto`);
            }
        } catch (err) {
            console.log(err);
        }
    };

    function controlarHora() {
        if (solicitacoes.fk_fornecedorOrcamentos.length === 0) {
            setDataCreate(0)
        } else {
            solicitacoes.fk_fornecedorOrcamentos?.map((item) => {
                const dateNow = new Date();
                const firstDate = new Date(item.createdAt);

                const hora = (dateNow.getTime() - firstDate.getTime()) / 3600000
                if (hora <= 2) {
                    setDataCreate(0)
                } else {
                    setDataCreate(1)
                }
            })
        }
    };

    useEffect(() => {
        if (solicitacoes.updatedAt) {
            setDate(format(new Date(solicitacoes.updatedAt), 'dd/MM/yyyy'))
        }
    }, [solicitacoes]);

    useEffect(() => {
        controlarHora();
    }, [paginas]);


    return (
        <>
            <Container style={{ backgroundColor: dataCreate !== 0 && 'rgb(154, 4, 4, 0.1)' }}>
                {nivel === '999' && <header>
                    <p>Nome Cliente</p>
                    <span>{usuario.nome}</span>
                </header>}
                {/* <Body>
                    <p>id</p>
                    <span>{solicitacoes.id}</span>
                </Body> */}
                <Body>
                    <p>{solicitacoes.nome_comercial}</p>
                    <span>{solicitacoes.marca}</span>
                </Body>
                <Body>
                    <p>Pagamento</p>
                    <span>{solicitacoes.forma_pagamento}</span>
                </Body>
                <Body>
                    <p>Quantidade</p>
                    <span>{solicitacoes.quantidade}</span>
                </Body>
                <Body>
                    <p>Data</p>
                    <span>{date}</span>
                </Body>
                <Body>
                    <p>Valor</p>
                    <div>
                        <p>R$</p>
                        <input
                            value={valorRespondidos == '' ? valor : valorRespondidos[0]}
                            type={'number'}
                            onChange={(e) => setValor(e.target.value)}
                            style={{ paddingLeft: '5px', outline: 0 }}
                            disabled={valorRespondidos != '' ? true : false}
                        />
                    </div>
                </Body>
                <Body>
                    {valorRespondidos == ''
                        ?
                        <button onClick={handleSubmit}>
                            Enviar
                        </button>
                        :
                        <RiCheckboxCircleLine size={30} style={{ color: "#559B2D" }} />
                    }
                </Body>
                <Buttons>
                    <button
                        style={{ color: "#F89747" }}
                        // disabled={dataCreate !== 0 ? true : false}
                        onClick={() => {
                            setShowModalEnvioOrcamento(true); setUserSelected(solicitacoes)
                        }}>
                        <RiEditBoxFill size={30} />
                    </button>
                    {nivel === "999" && (
                        <button
                        style={{ color: "#F26689" }}
                        disabled={dataCreate !== 0 ? true : false}
                        onClick={() => { setShowModalDelete(true); setUserSelected(solicitacoes) }}
                    >
                        <RiDeleteBin5Line size={30} />
                    </button>
                    )}
                </Buttons>
            </Container>
        </>
    )
}

export default SolicitacoesRow;
