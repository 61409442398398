import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { RiFilterLine, RiSearchLine } from "react-icons/ri";
import Select from "react-select";
import { toast } from "react-toastify";

import FornecedoresRow from "../../../components/fornecedoresRow";
import api from "../../../services/api";
import ModalCreate from "./ModalCreate";
import ModalDelete from "./ModalDelete";
import ModalEdit from "./ModalEdit";
import { Buscar, Container, Content, Filtros } from "./styles";

function ListagemFornecedor() {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [fornecedores, setFornecedores] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [cidade, setCidade] = useState([]);
  const [selectedCidade, setSelectedCidade] = useState("");
  const [buscar, setBuscar] = useState("");

  const [totalPages, setTotalPages] = useState();
  const [registros, setRegistros] = useState();
  const [page, setPage] = useState(0);

  const loadingUsers = async () => {
    try {
      const { data } = await api.get(
        `/fornecedor?cidade=${selectedCidade}&page=${page}&search=${buscar}`
      );
      setFornecedores(data.content);
      setTotalPages(data.totalPages);
      setRegistros(data.registros);
    } catch (err) {
      console.log(err);
    }
  };

  const getDataFilters = async () => {
    try {
      const { data } = await api.get(`fornecedor_filtro`);
      const cidade = [];
      data?.map((item) => {
        if (!cidade.includes(item.cidade)) {
          cidade.push(item.cidade);
        }
      });
      setCidade(
        cidade?.map((item) => ({
          value: item,
          label: item,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };


  // const loadingFornecedorNome = async () => {
  //   try {
  //     const { data } = await api.get(
  //       `/fornecedor_nome?nome=${buscar}&cidade=${selectedCidade}`
  //     );
  //     // console.log(data.message);
  //     if (data.length === 0) {
  //       toast.error("Fornecedor não encontrado");
  //       return false;
  //     }
  //     setFornecedoresBusca(data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const resetFilter = () => {
    setSelectedCidade("");
    setPage(0)
    loadingUsers();
  };

  useEffect(() => {
    getDataFilters()
  }, []);

  useEffect(() => {
      loadingUsers();
  }, [selectedCidade, page, buscar]);


  const customStyles = {
    control: () => ({
      width: 150,
      borderRadius: "8px",
      height: "35px",
      // backgroundColor: 'yellow'
    }),
    menuList: (provided) => ({
      ...provided,
      width: 150,
      display: "flex",
      flexDirection: "column",
      fontSize: 15,
    }),
    valueContainer: (provider) => ({
      ...provider,
      width: "100%",
      height: "100%",
    }),
  };



  return (
    <>
      {showModalEdit && (
        <ModalEdit
          setShowModalEdit={setShowModalEdit}
          user={userSelected}
          loading={loadingUsers}
        />
      )}
      {showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          user={userSelected}
          loading={loadingUsers}
        />
      )}
      {showModalCreate && (
        <ModalCreate
          setShowModalCreate={setShowModalCreate}
          loading={loadingUsers}
        />
      )}
      <Container>
        <Content>
          <div>
            <h1>Fornecedores</h1>
            <Buscar>
              <input
                style={{ marginLeft: "10px" }}
                name="buscar"
                placeholder="buscar"
                onChange={(e) => {setPage(0);setBuscar(e.target.value)}}
              />
              <div
                id="button"
                type="button"
                // onClick={() => loadingFornecedorNome()}
              >
                <RiSearchLine size={20} />
              </div>
            </Buscar>
            <button onClick={() => setShowModalCreate(!showModalCreate)}>
              +
            </button>
          </div>
          <div>
            <h2>Mais de {registros} novos fornecedores</h2>
          </div>
        </Content>
        <Filtros>
          <section
            id="select"
            style={{
              boxShadow: "0px 5px 6px rgba(0,0,0,0.6)",
              marginRight: "25px",
            }}
          >
            <Select
              value={
                selectedCidade != ""
                  ? { label: selectedCidade, value: "" }
                  : null
              }
              isMulti={false}
              onChange={(value) =>{ setPage(0);setSelectedCidade(value.label)}}
              options={cidade}
              placeholder={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RiFilterLine style={{ display: "flex" }} size={25} />
                  <p style={{ fontSize: "12px" }}>Filtrar por Cidade</p>
                </div>
              }
              maxMenuHeight={160}
              menuPlacement="auto"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(12, 157, 164, 0.4)",
                  primary: "#20655F",
                },
              })}
            />
          </section>
          {/* <section id="select" style={{ boxShadow: '0px 5px 6px rgba(0,0,0,0.6)', }}>
            <Select
              isMulti={false}
              onChange={value => setSelectedCidade(value.label)}
              options={cidade}
              placeholder={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <RiFilterLine style={{ display: 'flex' }} size={25} />
                  <p style={{ fontSize: '12px' }}>Filtrar por Status</p>
                </div>
              }
              maxMenuHeight={160}
              menuPlacement='auto'
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'rgb(12, 157, 164, 0.4)',
                  primary: '#20655F',
                },
              })}
            />
          </section> */}

          <button
            style={{
              cursor: "pointer",
              color: "#20655F",
              fontWeight: "bold",
              padding: "8px 8px",
              borderRadius: "5px",
              marginLeft: "50px",
              fontSize: "12px",
              boxShadow: "0px 5px 6px rgba(0,0,0,0.6)",
            }}
            onClick={resetFilter}
          >
            Apagar Filtros
          </button>
        </Filtros>
        {fornecedores?.map((data, index) => (
          <FornecedoresRow
            key={index}
            setUserSelected={setUserSelected}
            fornecedor={data}
            setShowModalEdit={setShowModalEdit}
            setShowModalDelete={setShowModalDelete}
          />
        ))}
        <Pagination
          count={totalPages}
          page={ page+ 1}
          onChange={(event, value) => setPage(value - 1)}
          shape="rounded"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </Container>
    </>
  );
}

export default ListagemFornecedor;
