import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { RiBuilding4Line, RiCalendarTodoFill, RiCommunityLine, RiHandHeartLine, RiLock2Line, RiMailLine, RiPhoneLine, RiRoadMapLine } from "react-icons/ri";
import Select from "react-select";
import * as Yup from "yup";

import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { AuthContextBuyPlan } from '../../../../contexts/BuyPlanContext';
import { AuthContext } from "../../../../contexts/UserContex";
import api from '../../../../services/api'
import getValidationErrors from "../../../../utils/getValidationsErrors";
import Cidade from "./Cidade";
import Cultura from "./Cultura";
import Regiao from "./Regiao";
import { Body, Column, Container, Content, Forms, Image } from "./styles";

function ModalEdit({ setShowModalEdit, user, loading }) {
  const formRef = useRef(null);
  const [allPlans, setAllPlans] = useState(null);
  const [plano, setPlano] = useState(null);
  const { updateUser } = useContext(AuthContext);
  const {handleAdminUpgradeSubscription} = useContext(AuthContextBuyPlan)
  const [planUser, setPlanUser] = useState(null)
 
console.log(user)

  const [showCidade, setShowCidade] = useState(false);
  const [cidadeSelecionada, setCidadeSelecionada] = useState();



  const [showCultura, setShowCultura] = useState(false);
  const [culturaSelecionada, setCulturaSelecionada] = useState([]);
  const [culturas, setCulturas] = useState([]);

  const [showModalRegiao, setShowModalRegiao] = useState(false);
  const [regiaoSelected, setRegiaoSelected] = useState();

  const [data, setData] = useState();
  
  const dataAtualizadas = user.data_assinatura

  useEffect(() => {
    if (dataAtualizadas) {
        setData(format(new Date(dataAtualizadas), 'dd/MM/yyyy'))
    }
}, [dataAtualizadas]);


  // const getAllPlans = async () => {
  //   const response = await api.get('/planos')
  //   const responseArray = [response.data]
  //   let arrayLimpo = []
    
  //   for (arrayLimpo in responseArray) {
  //     console.log(arrayLimpo)
  //   }
  //   const plans = response.data.map(item => ({label: item.name, value: item.id}))
  //   setAllPlans(plans)
  // }

  // useEffect(() => {
  //   getAllPlans()
  // }, [])

  const plans = [{label: 'Insumos', value: 5},{label: 'Peças', value: 6},{label:` Campo +`, value: 7}]

  // const planoAtual = [{label: 'Peças', value: 6}]

  const planoAtual = () => {
    if(user.plan =='Insumos-free') {
      return [{label: 'Insumos', value: 5}]
    } else if(user.plan == 'Peças-free') {
      return [{label: 'Peças', value:6}]
    } else if(user.plan == 'Campo + free') {
      return [{label: ' Campo +', value: 7}]
    } else return;
  }

  planoAtual()

  const handleSubmit = async (data) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        nome: Yup.string()
          .required("Nome obrigatório!")
          .min(3, "O nome precisa ter no mínimo 3 caracteres")
          .max(30, "O nome precisa ter no máximo 30 caracteres"),
        email: Yup.string()
          .required("Email obrigatório!"),
        // .email("O email precisa ser válido!"),
        cidade: Yup.string()
          .required("Cidade obrigatório!")
          .min(3, "O nome da cidade precisa ter no mínimo 3 caracteres")
          .max(30, "O nome da cidade precisa ter no máximo 30 caracteres"),
        regiao: Yup.string()
          .required("Região obrigatório!")
          .min(3, "O nome da região precisa ter no mínimo 3 caracteres")
          .max(30, "O nome da região precisa ter no máximo 30 caracteres"),
        documento: Yup.string()
          .required("CPF/CNPJ obrigatório!"),
        plano: Yup.string().optional()
        // senha: Yup.string()
        //   .required("Senha obrigatória!")
        //   .min(6, "A senha precisa ter no mínimo 6 caracteres")
        //   .max(6, "A senha precisa ter no máximo 20 caracteres"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      formRef.current?.setErrors({});

      if (data.senha == "") {
        // delete data.senha_confirmation;
        delete data.senha;
      }
      data.plan = plano.value
      if(data.plan !== '') {
        try {
          await handleAdminUpgradeSubscription(data.plan, user?.id)
        } catch (error) {
          console.log(error.message)
        }
        
      }
      await updateUser(data, user?.id);
      loading();
      setShowModalEdit(false);
    } catch (err) {
      console.log(err)
      const errors = getValidationErrors(err);
      formRef.current?.setErrors(errors);
    }
  };

  useEffect(() => {
    setCulturas(user.cultura.split(", "));
  }, []);

  
  const customStyles = {
    control: () => ({
      width: '100%',
      borderRadius: '8px',
      height: '35px',
      display: 'flex'
      // backgroundColor: 'yellow'
    }),
    menuList: (provided) => ({
      ...provided,
      width: 300,
      display: 'flex',
      flexDirection: 'column',
      fontSize: 15,
    }),
    valueContainer: (provider) => ({
      ...provider,
      width: '100%',
      height: '100%'
    })
  };




  return (
    <>
      {showCidade && (
        <Cidade
          setShowCidade={setShowCidade}
          setCidadeSelecionada={setCidadeSelecionada}
        />
      )}
      {showModalRegiao && (
        <Regiao
          setShowModalRegiao={setShowModalRegiao}
          setRegiaoSelected={setRegiaoSelected}
        />
      )}
      {showCultura && (
        <Cultura
          setShowCultura={setShowCultura}
          setCulturaSelecionada={setCulturaSelecionada}
          culturaSelecionada={culturaSelecionada}
          culturas={culturas}
        />
      )}
      <Container>
        <Body>
          <div>
            <Image style={{ background: 'transparent' }} />
            <h1>Editar Usuário</h1>
            <Image />
          </div>
          <p>Confira os dados abaixo</p>
          <Forms ref={formRef} onSubmit={handleSubmit} initialData={{ ...user, senha: "", documento: user.cpf_cnpj, data_assinatura: data }}>
            <Content>
              <Column>
                <div>
                  <span>Nome</span>
                  <Input name="nome" placeholder="Seu Nome"
                    icon={RiBuilding4Line}
                  />
                </div>
                <div>
                  <span>E-mail</span>
                  <Input name="email" placeholder="Seu email"
                    icon={RiMailLine}
                  />
                </div>
                <div>
                  <span>Telefone</span>
                  <Input name="telefone" placeholder="Seu telefone"
                    icon={RiPhoneLine} formatar="(99)99999-9999"
                  />
                </div>
              </Column>
              <Column>
                <div onClick={() => setShowCidade(true)} style={{ cursor: 'pointer' }}>
                  <span>Cidade</span>
                  {cidadeSelecionada ?
                    <Input
                      name="cidade"
                      placeholder="Cidade"
                      icon={RiCommunityLine}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) =>{
                        e.preventDefault();
                        setShowCidade(true)
                      }}
                      value={cidadeSelecionada}
                    /> :
                    <Input
                      name="cidade"
                      placeholder="Cidade"
                      icon={RiCommunityLine}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) =>{
                        e.preventDefault();
                        setShowCidade(true)
                      }}
                    />}
                </div>
                <div onClick={() => setShowModalRegiao(true)} style={{ cursor: 'pointer' }}>
                  <span >Região de Atuação</span>
                  {regiaoSelected ?
                    <Input
                      name="regiao"
                      placeholder="Região"
                      icon={RiRoadMapLine}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) =>{
                        e.preventDefault();
                        setShowModalRegiao(true)
                      }}
                      value={regiaoSelected}
                    /> :
                    <Input
                      name="regiao"
                      placeholder="Região"
                      icon={RiRoadMapLine}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) =>{
                        e.preventDefault();
                        setShowModalRegiao(true)
                      }}
                    />}
                </div>
                <div>
                  <span>Senha</span>
                  <Input
                    name="senha"
                    placeholder="****"
                    type="password"
                    maxlength="6"
                    icon={RiLock2Line}
                  />
                </div>
              </Column>
              <Column>
                <div onClick={() => setShowCultura(true)} style={{ cursor: 'pointer' }}>
                  <span>Cultura Praticada</span>
                  {culturaSelecionada.length != 0 ?
                    <Input
                      name="cultura"
                      placeholder="Sua Cultura"
                      icon={RiHandHeartLine}
                      onClick={(e) =>{
                        e.preventDefault();
                        setShowCultura(true)
                      }}
                      style={{ cursor: 'pointer' }}
                      value={culturaSelecionada.join(', ')}
                    /> :
                    <Input
                      name="cultura"
                      placeholder="Sua Cultura"
                      icon={RiHandHeartLine}
                      onClick={(e) =>{
                        e.preventDefault();
                        setShowCultura(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    />}
                </div>
                <div>
                  <span>Data da Assinatura</span>
                  <Input name="data_assinatura" placeholder="Data da sua assinatura"
                    icon={RiCalendarTodoFill} disabled={true}
                  />
                </div>
                <div>
                  <span>CPF</span>
                  <Input name="documento" placeholder="999.999.999-99"
                    icon={RiPhoneLine} formatar="999.999.999-99"
                  />
                </div>

                <div>
                  <span>Selecione um plano</span>
                  <Select 
                  name="plano"
                  defaultValue={planoAtual}
                  options={plans}
                  onChange={value => setPlano(value) }
                  styles={customStyles}
                  placeholder=""
                  />

                 
                </div>
              </Column>
            </Content>
            <div id='button' style={{ backgroundColor: "#FFF" }}>
              <Button style={{ fontSize: '18px', width: "30%" }}>Salvar</Button>
            </div>
          </Forms>
          <a href="#" onClick={() => setShowModalEdit(false)}>
            Voltar
          </a>
        </Body>
      </Container>
    </>
  );
}

export default ModalEdit;