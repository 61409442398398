import React, { useState, useEffect } from "react";

import { RiSearchLine } from "react-icons/ri";
import { Container, Body, Content, Image, Buscar} from "./styles";
import RegiaoPerfilRow from "../../../../../components/RegiaoPerfilRow";
import api from "../../../../../services/api";

function ModalRegiao({ setShowModalRegiao, loading, setRegiaoSelected, regiaoSelected, regiaoUser }) {

    const [regiao, setRegiao] = useState([]);
    const [buscar, setBuscar] = useState('');

    const loadingRegiao = async () => {
        try {
            const { data } = await api.get(`/regiao_all`);
            setRegiao(data);
        } catch (err) {
            console.log(err);
        }
    };

    const loadingRegiaoNome = async () => {
        try {
            if (buscar) {
                const { data } = await api.post(`/regiao_nome`, { nome: buscar });
                setRegiao(data);
            }

        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadingRegiao();
    }, []);

    // useEffect(() => {
    //     if (setShowModalRegiao === true) {
    //         if (regiaoUser != '') {
    //             setRegiaoSelected(regiaoUser)
    //         } else {
    //             setRegiaoSelected(regiaoSelected)
    //         }
    //     }
    // }, [regiaoSelected]);

    return (
      <Container>
            <Body>
                <div>
                    <Image style={{ background: 'transparent' }} />
                    <h1>Regiões</h1>
                    <Image />
                </div>
                <p>Selecione uma região</p>
                <Buscar>
                    <input style={{ marginLeft: '10px' }} name="buscar" placeholder="buscar" onChange={(e) => setBuscar(e.target.value)} />
                    <div id='botao' type="button" style={{ height: '35px'}} onClick={() => loadingRegiaoNome()}>
                        <RiSearchLine size={20} />
                    </div>
                </Buscar>
                <Content>
                    <div>
                        {regiao?.map((data) => (
                            <RegiaoPerfilRow
                                key={data.id}
                                regiao={data}
                                setShowModalRegiao={setShowModalRegiao}
                                setRegiaoSelected={setRegiaoSelected}
                                regiaoSelected={regiaoSelected}
                                regiaoUser={regiaoUser}
                            />
                        ))}
                    </div>
                </Content>
                <a href="#" onClick={() => setShowModalRegiao(false)}>
                    Voltar
                </a>
            </Body>
        </Container>
    );
}

export default ModalRegiao;