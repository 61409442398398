import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { toast } from "react-toastify";

import SolicitacoesRow from "../../../components/SolicitacoesRow";
import api from "../../../services/api";
import ModalDelete from "./ModalDelete";
import ModalEnvioOrcamentoAdmin from "./ModalEnvioOrcamentoAdmin";
import { Buscar, Container, Content, Filtro } from "./styles";

function Solicitacoes() {
  const [botao, setBotao] = useState();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEnvioOrcamento, setShowModalEnvioOrcamento] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [buscar, setBuscar] = useState('');
  const registros = solicitacoes?.length;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const loadingSolicitacoes = async () => {
    try {
      const { data } = await api.get(
        `/orcamento?page=${page}&search=${buscar}`
      );
      setSolicitacoes(data.content);
      setTotalPages(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  // const loadingSolicitacoesNome = async () => {
  //     try {
  //         const { data } = await api.post(`/orcamento_nome`, { nome: buscar });
  //         if (data.length === 0) {
  //             toast.error("Orçamento não encontrado");
  //             return false;
  //         }
  //         setSolicitacoes(data);
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };

  useEffect(() => { 
    loadingSolicitacoes();
  }, []);

  useEffect(() => { 
    loadingSolicitacoes();
  }, [page, buscar]);

  // const solicitacoesPaginado = solicitacoes.slice(paginas * 10, paginas * 10 + 10);
  // const totalPages = Math.ceil(solicitacoes.length / 10);

  return (
    <>
      {showModalDelete && (
        <ModalDelete
          setShowModalDelete={setShowModalDelete}
          user={userSelected}
          loading={loadingSolicitacoes}
        />
      )}
      {showModalEnvioOrcamento && (
        <ModalEnvioOrcamentoAdmin
          setShowModalEnvioOrcamento={setShowModalEnvioOrcamento}
          user={userSelected}
          loading={loadingSolicitacoes}
        />
      )}
      <Container>
        <Content>
          <div>
            <h1>Solicitações</h1>
            <Buscar>
              <input
                style={{ marginLeft: "10px" }}
                name="buscar"
                placeholder="buscar"
                onChange={(e) => {
                  setPage(0);
                  setBuscar(e.target.value);
                }}
              />
              <div id="button" type="button">
                <RiSearchLine size={20} />
              </div>
            </Buscar>
          </div>

          <div>
            <div>
              <p>Mais de {registros} novos orçamentos</p>
            </div>
            {/* <Filtro>
                            <div id="p" type="button" onClick={() => setBotao(1)}
                                style={{
                                    backgroundColor: botao === 1 ? "#20655F" : "#EFF1F9",
                                    color: botao === 1 ? "#FFF" : "#626679"
                                }}>
                                Mês
                            </div>
                            {"|"}
                            <div id="p" type="button" onClick={() => setBotao(2)}
                                style={{
                                    backgroundColor: botao === 2 ? "#20655F" : "#EFF1F9",
                                    color: botao === 2 ? "#FFF" : "#626679"
                                }}>
                                Semana
                            </div>
                            {"|"}
                            <div id="p" type="button" onClick={() => setBotao(3)}
                                style={{
                                    backgroundColor: botao === 3 ? "#20655F" : "#EFF1F9",
                                    color: botao === 3 ? "#FFF" : "#626679"
                                }}>
                                Dia
                            </div>
                        </Filtro> */}
          </div>
        </Content>
        {solicitacoes?.map((data, index) => (
          <SolicitacoesRow
            key={index}
            solicitacoes={data}
            setShowModalDelete={setShowModalDelete}
            setShowModalEnvioOrcamento={setShowModalEnvioOrcamento}
            setUserSelected={setUserSelected}
            paginas={totalPages}
          />
        ))}
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={(event, value) => setPage(value - 1)}
          shape="rounded"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        />
      </Container>
    </>
  );
}

export default Solicitacoes;
