import React, { createContext } from "react";
import { toast } from "react-toastify";
import api from "../services/api";
import { getId } from "../services/auth";

const AuthContextFornecedorProduto = createContext({});

const AuthProviderFornecedorProduto = ({ children }) => {

    /* Função para carregar produtos */
    const loadingFornecedorProdutos = async (setFornecedorProdutos, produtoFornecedor, categoriaFornecedor) => {
        try {
            const id = getId();
            const { data } = await api.get(`/fornecedor/${id}/produto?produto=${produtoFornecedor}&categoriaProduto=${categoriaFornecedor}`);
            setFornecedorProdutos(data);
        } catch (err) {
            console.log(err);
        }
    };

    const loadingFornecedorProdutosFiltro = async (setFornecedorProdutosFiltro) => {
        try {
            const id = getId();
            const { data } = await api.get(`/fornecedor_filtro/${id}/produto`);
            setFornecedorProdutosFiltro(data)
        } catch (err) {
            console.log(err);
        }
    };

    /* Função para delatar produtos */
    const deleteFornecedorProduto = async (id, userId) => {
        try {
            const data = await api.delete(`/fornecedor/${userId}/produto/${id}`, { data: { produto_id: id } });
            toast.success(data.data.message);
            return data;
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
            return false;
        }
    };

    /* Função para atualiza usuário */
    //   const updateProduto = async (data, id) => {
    //     try {
    //       const resp = await api.put(`/produto/${id}`, data);
    //       toast.success(resp?.data?.message);
    //       return resp.data;
    //     } catch (err) {
    //       console.log(err);
    //       toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");

    //       return false;
    //     }
    //   };

    /* Função para registrar produtos para um fornecedor */
    const createFornecedorProduto = async (selected, id) => {
        try {
            const resp = await api.post(`/fornecedor/${id}/produto`, { produto_id: selected });
            !resp?.data?.id ?
                toast.error(resp?.data?.message) :
                toast.success("Cadastrado com sucesso!");
            return resp.data;
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
            return false;
        }
    };

    /* Função para registrar produtos para um fornecedor */
    /* Função para todos */
    const createFornecedorProdutos = async (notSelectedProdutos, id, categoria) => {
        try {
            const resp = await api.post(`/fornecedor/${id}/produtos?categoriaProduto=${categoria}`, { produto_id: notSelectedProdutos });
            !resp?.data?.id ?
                toast.error(resp?.data?.message) :
                toast.success("Cadastrado com sucesso!");
            return resp.data;
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.error || "Ops... Ocorreu um erro");
            return false;
        }
    };

    return (
        <AuthContextFornecedorProduto.Provider
            value={{
                deleteFornecedorProduto,
                loadingFornecedorProdutos,
                // updateProduto,
                createFornecedorProduto,
                createFornecedorProdutos,
                loadingFornecedorProdutosFiltro,
            }}
        >
            {children}
        </AuthContextFornecedorProduto.Provider>
    );
};

export { AuthContextFornecedorProduto, AuthProviderFornecedorProduto };