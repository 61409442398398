import React, { useRef, useContext, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { AuthContextFornecedor } from "../../../../contexts/FornecedorContext";
import getValidationErrors from "../../../../utils/getValidationsErrors";
import {
    RiMailLine,
    RiLock2Line,
    RiPhoneLine,
    RiRoadMapLine,
    RiBuilding4Line,
    RiCommunityLine,
} from "react-icons/ri";
import Cidade from "./Cidade";

import { Container, Forms, Body, Content, Column, Image } from "./styles";

function ModalCreate({ setShowModalCreate, user, loading }) {
    const formRef = useRef(null);

    const { createFornecedor } = useContext(AuthContextFornecedor);

    const [showCidade, setShowCidade] = useState(false);
    const [cidadeSelecionada, setCidadeSelecionada] = useState();

    const handleSubmit = async (data) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                nome_estabelecimento: Yup.string()
                    .required("Nome obrigatório!")
                    .min(3, "O nome precisa ter no mínimo 3 caracteres")
                    .max(30, "O nome precisa ter no máximo 30 caracteres"),
                email: Yup.string()
                    .required("Email obrigatório!"),
                // .email("O email precisa ser válido!"),
                cidade: Yup.string()
                    .required("Cidade obrigatório!")
                    .min(3, "O nome da cidade precisa ter no mínimo 3 caracteres")
                    .max(30, "O nome da cidade precisa ter no máximo 30 caracteres"),
                endereco: Yup.string()
                    .required("Endereço obrigatório!")
                    .min(3, "O nome do endereço precisa ter no mínimo 3 caracteres")
                    .max(30, "O nome do endereço precisa ter no máximo 30 caracteres"),
                senha: Yup.string()
                    .required("Senha obrigatória!")
                    .min(6, "A senha precisa ter no mínimo 6 caracteres")
                    .max(20, "A senha precisa ter no máximo 20 caracteres"),
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            formRef.current?.setErrors({});

            await createFornecedor(data);

            loading();
            setShowModalCreate(false);
        } catch (err) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
        }
    };

    return (
        <>
            {showCidade && (
                <Cidade
                    setShowCidade={setShowCidade}
                    setCidadeSelecionada={setCidadeSelecionada}
                />
            )}
            <Container>
                <Body>
                    <div>
                        <Image style={{ background: 'transparent' }} />
                        <h1>Cadastrar Fornecedor</h1>
                        <Image />
                    </div>
                    <p>Confira os dados abaixo</p>
                    <Forms ref={formRef} onSubmit={handleSubmit}>
                        <Content>
                            <Column>
                                <div>
                                    <span>Nome do Estabelecimento</span>
                                    <Input name="nome_estabelecimento" placeholder="Nome do estabelecimento"
                                        icon={RiBuilding4Line}
                                    />
                                </div>
                                <div>
                                    <span>E-mail</span>
                                    <Input name="email" placeholder="Seu email"
                                        icon={RiMailLine}
                                    />
                                </div>
                                <div>
                                    <span>Telefone</span>
                                    <Input name="telefone" placeholder="Seu telefone"
                                        icon={RiPhoneLine} formatar="(99)99999-9999"
                                    />
                                </div>
                            </Column>
                            <Column>
                            <div onClick={() => setShowCidade(true)} style={{ cursor: 'pointer' }}>
                                    <span>Cidade</span>
                                    {cidadeSelecionada ?
                                        <Input
                                            name="cidade"
                                            placeholder="Cidade"
                                            icon={RiCommunityLine}
                                            style={{ cursor: 'pointer' }}
                                            disabled={true}
                                            value={cidadeSelecionada}
                                        /> :
                                        <Input
                                            name="cidade"
                                            placeholder="Cidade"
                                            icon={RiCommunityLine}
                                            style={{ cursor: 'pointer' }}
                                            disabled={true}
                                        />}
                                </div>
                                <div>
                                    <span>Endereço Completo</span>
                                    <Input name="endereco" placeholder="Seu Endereço"
                                        icon={RiRoadMapLine}
                                    />
                                </div>
                                <div>
                                    <span>Senha</span>
                                    <Input name="senha" placeholder="****" type="password"
                                        icon={RiLock2Line}
                                    />
                                </div>
                            </Column>
                        </Content>
                        <div id='button' style={{ backgroundColor: "#FFF" }}>
                            <Button style={{ fontSize: '18px', width: "30%" }}>Salvar</Button>
                        </div>
                    </Forms>
                    <a href="#" onClick={() => setShowModalCreate(false)}>
                        Voltar
                    </a>
                </Body>
            </Container>
        </>
    );
}

export default ModalCreate;