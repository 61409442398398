import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { Fragment, useContext, useEffect, useRef } from 'react';
import * as Yup from "yup";

import { AuthContextBuyPlan } from '../../../../../contexts/BuyPlanContext';
import getValidationErrors from '../../../../../utils/getValidationsErrors';
import Input from "../../components/Input"
import { formatCredicardNumber, formatExpDate, isValidCredicardExpDate, isValidCredicardNumber } from '../../util';
import { Forms } from "./styles";

export default function PaymentForm() {
  const { requestBody, checkForm, handleNextForm, invalidForm, handleFillRequestBody, activeStep } = useContext(AuthContextBuyPlan)
  const formRef = useRef(null);
  const [metodoPagamento, setMetodoPagamento] = React.useState("");
  const [selecionarMetodo, setSelecionarMetodo] = React.useState(true)



  Yup.addMethod(Yup.mixed, "isValidCredicardNumber", isValidCredicardNumber);
  Yup.addMethod(Yup.mixed, "isValidCredicardExpDate", isValidCredicardExpDate);

  const handleCheckForm = async (data) => {
    try {
      if(data.paymentMethod != "boleto") {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          holderName: Yup.string()
              .required("Nome no cartão é obrigatório!")
              .min(3, "O nome precisa ter no mínimo 3 caracteres")
              .max(30, "O nome precisa ter no máximo 30 caracteres"),
            number: Yup.mixed()
              .isValidCredicardNumber(),
            cvv: Yup.string()
              .required("CVV é obrigatório!")
              .min(3, "CVV precisa ter no mínimo 3 números")
              .max(4, "CVV precisa ter no máximo 4 números"),
            expDate: Yup.mixed()
              .isValidCredicardExpDate()
        });

        await schema.validate(data, {
            abortEarly: false,
        });
        formRef.current?.setErrors({});

        const allData = formRef.current.getData();
        const { formatted, isValid, brand} = formatCredicardNumber(allData.number);
        const send = {
          holderName: allData.holderName,
          number: allData.number,
          cvv: allData.cvv,
          expDate: allData.expDate,
          brand: brand
        }
        handleFillRequestBody(send)
        handleNextForm()

      } else {
        
        const send = {
          paymentMethod: 'boleto'
        }
        handleFillRequestBody(send)
        handleNextForm()
      }
      
    } catch (err) {
        console.log(err);
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        invalidForm()
    }
	};

  useEffect(()=>{
    if(requestBody?.card?.holderName?.length > 0){
			formRef.current.setData({
				holderName: requestBody.card.holderName,
				number: requestBody.card.number,
				cvv: requestBody.card.cvv,
				expDate: requestBody.card.expDate,
			});
    }
  },[])

  useEffect(()=>{
    if(checkForm === true && activeStep === 3){
      formRef.current.submitForm();
    }
  },[checkForm])



  
      
    

  
  return (
    <Fragment>
      {metodoPagamento == 'cartao' && selecionarMetodo == false &&  
      <Forms ref={formRef} onSubmit={handleCheckForm}>
        <Typography variant="h6" gutterBottom>
          Método de pagamento
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Input
              required
              id="holderName"
              name="holderName"
              label="Nome no cartão"
              fullWidth
              autoComplete="cc-name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              id="number"
              name="number"
              label="Número do cartão"
              fullWidth
              autoComplete="cc-number"
              variant="standard"
              onChange={(e)=>{
                const { formatted, isValid, brand} = formatCredicardNumber(e.target.value);
								formRef?.current?.setFieldValue('number', formatted)
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              id="expDate"
              name="expDate"
              label="Data de validade"
              placeholder="MM/AA"
              fullWidth
              autoComplete="cc-exp"
              variant="standard"
              onChange={(e)=>{
                const { formatted, isValid } = formatExpDate(e.target.value);
								formRef?.current?.setFieldValue('expDate', formatted)
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              id="cvv"
              name="cvv"
              label="CVV"
              fullWidth
              autoComplete="cc-csc"
              variant="standard"
              onChange={(e)=>{
                const value = e.target.value.substring(0,4).replace(/\D/g, '')
                formRef?.current?.setFieldValue('cvv', value)
                if(value.length === 3 || value.length === 4) formRef?.current?.setFieldError('cvv', '')
              }}
            />
          </Grid>
        </Grid>
      </Forms>
 }
 {metodoPagamento == 'boleto' && selecionarMetodo == false && <p>Aqui é boleto</p>}

 {selecionarMetodo && metodoPagamento !== 'boleto' && metodoPagamento !== 'cartao' &&
      <Box sx={{width: "100%", marginTop: 5, marginBottom: 5}}> 
       <Button onClick={() => {
        setMetodoPagamento('cartao')
        setSelecionarMetodo(false)
        }} variant="outlined" sx={{width: '100%', color: '#000', borderColor: 'green', padding: "10px", borderRadius: "8px"}}>Cartão de Crédito</Button>
       <Button onClick={() => {
        setMetodoPagamento('boleto')
        setSelecionarMetodo(false)
        handleCheckForm({ paymentMethod: "boleto"})
        }} variant="outlined" sx={{width: '100%', color: '#000', borderColor: 'green', padding: "10px", borderRadius: "8px", marginTop: 2}}>Boleto</Button>
      </Box>
    }
    </Fragment>
  );
}