import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import api from "../../../../services/api";

function NumeroUsuarios({
  setFiltroMesUsuarios,
  mesSelecionado,
  setFiltroAnoUsuarios,
  anoSelecionado,
  setCidade,
  filtroCidadeSelecionada,
  setRegiao,
  filtroRegiaoSelecionada
}) {

  const [user, setUser] = useState([]);

  const loadingUsers = async () => {
    try {
      const { data } = await api.get(`/grafico_usuarios_ativos?mes=${mesSelecionado}&ano=${anoSelecionado}&cidade=${filtroCidadeSelecionada}&regiao=${filtroRegiaoSelecionada}`);
      setUser(data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingUsersFiltroMes = async () => {
    try {
      const { data } = await api.get(`/grafico_usuarios_ativos_filtroMes`);
      setFiltroMesUsuarios(data?.map(item => ({
        value: item.nome, label: item.nome
      })));
    } catch (err) {
      console.log(err);
    }
  };

  const loadingUsersFiltroAno = async () => {
    try {
      const { data } = await api.get(`/grafico_usuarios_ativos_filtroAno`);
      const anos = []
      data?.map((item) => {
        if (!anos.includes(item.ano)) {
          anos.push(item.ano)
        }
      });
      setFiltroAnoUsuarios(anos?.map(item => ({
        value: item, label: item
      })));

    } catch (err) {
      console.log(err);
    }
  };

  const loadingCidades = async () => {
    try {
      const { data } = await api.get(`/cidade_all`);
      setCidade(data.map((item) => ({
        value: item.nome, label: item.nome
      })));

    } catch (err) {
      console.log(err);
    }
  };

  const loadingRegioes = async () => {
    try {
      const { data } = await api.get(`regiao_all`);
      const regiao = [];
      data?.map(item => {
        if (!regiao.includes(item.nome)) {
          regiao.push(item.nome)
        }
      });
      setRegiao(regiao.map((item) => ({
        value: item, label: item
      })));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadingUsers();
  }, [mesSelecionado, anoSelecionado, filtroCidadeSelecionada, filtroRegiaoSelecionada]);

  useEffect(() => {
    loadingUsersFiltroMes();
    loadingUsersFiltroAno();
    loadingCidades();
    loadingRegioes();
  }, [user]);

  return (
    <BarChart
      width={1000}
      height={300}
      data={user}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="nome" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="ativos" fill="#20655F" />
      <Bar dataKey="usuarios" fill="#F8AA27" />
    </BarChart>
  );
}

export default NumeroUsuarios;
