import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'

import { Body, Container } from './styles';

const EnviadosRow = ({ enviados }) => {
    const [data, setData] = useState();
    const fornecedorOrcamentos = enviados?.fk_fornecedorOrcamentos;
    const ranking = enviados?.ranking;
    // console.log('ranking', ranking);

    const valores = fornecedorOrcamentos?.map((data) => {
        return data.valor_orcamento
    });
    // const ranking = fornecedorOrcamentos?.map((data) => {
    //     return data.ranking
    // });
    const dataAtualizadas = fornecedorOrcamentos?.map((item) => {
        return item.updatedAt
    });
    const fornecedor = fornecedorOrcamentos?.map((item) => {
        return item.fk_fornecedor?.nome_estabelecimento
    });
    const fornecedorEndereco = fornecedorOrcamentos?.map((item) => {
        return item.fk_fornecedor?.endereco
    });

    const valorOrcamento = fornecedorOrcamentos?.map((item) => {
        return item.valor_orcamento
    });

    useEffect(() => {
        if (dataAtualizadas) {
            setData(format(new Date(dataAtualizadas[0]), 'dd/MM/yyyy'))
        }
    }, [dataAtualizadas]);


    return (
        <>
            <Container>
                {/* <Body>
                    <img
                        // style={{cursor: 'pointer'}}
                        src='https://picsum.photos/200'
                        // onClick={() => {setShowModalProvider(true); setUserSelected(enviados)}}
                    />
                </Body> */}
                <header>
                    <p>{enviados.nome_comercial}</p>
                    <span>{enviados.marca}</span>
                </header>
                {/* <Body>
                    <p>Fornecedor</p>
                    <span>{fornecedor}</span>
                </Body> */}
                {/* <Body>
                    <p>Endereço</p>
                    <span>{fornecedorEndereco}</span>
                </Body> */}
                <Body>
                    <p>Valor Orçado</p>
                    <span>R$ {valores[0].toString().replace('.',',')}</span>
                </Body>
                <Body>
                    <p>Data da Resposta</p>
                    <span>{data}</span>
                </Body>
                <Body>
                    <p>Quantidade</p>
                    <span>{enviados.quantidade}</span>
                </Body>
                {ranking != '' ?
                    <Body>
                        <span>{ranking + "º"}</span>
                    </Body> :
                    <Body>
                        <span>Em analise</span>
                    </Body>
                }
            </Container>
        </>
    )
}

export default EnviadosRow;
