import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { getId } from "../../../../services/auth";
import api from "../../../../services/api";

function ContatoVisualizado({ setFiltroMesContato, mesSelecionado, setFiltroAnoContato, anoSelecionado }) {

  const [views, setViews] = useState([]);

  const loadingViews = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/grafico_showContatoVisualizado/${id}?mes=${mesSelecionado}&ano=${anoSelecionado}`);
      setViews(data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadingFiltroMes = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/grafico_showContatoVisualizado_filtroMes/${id}`);
      setFiltroMesContato(data?.map(item => ({
        value: item.nome, label: item.nome
      })));
    } catch (err) {
      console.log(err);
    }
  };

  const loadingFiltroAno = async () => {
    try {
      const id = getId();
      const { data } = await api.get(`/grafico_showContatoVisualizado_filtroAno/${id}`);
      const anos = []
      data?.map((item) => {
        if (!anos.includes(item.ano)) {
          anos.push(item.ano)
        }
      });
      setFiltroAnoContato(anos?.map(item => ({
        value: item, label: item
      })));

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadingViews();
  }, [mesSelecionado, anoSelecionado]);

  useEffect(() => {
    loadingFiltroMes();
    loadingFiltroAno();
  }, [views]);

  return (
    <BarChart
      width={1000}
      height={300}
      data={views}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="nome" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="visualizado" stackId="a" fill="#20655F" />
    </BarChart>
  );
}

export default ContatoVisualizado;
